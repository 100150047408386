import { isEmpty } from "helpers/strhelper";
import ElementsBaseValues from "./Elements/ElementsBaseValues";

const ScoringLevels = [
  {
    level: 1,
    code: "Beginners",
    name: "Beginners",
  },
  {
    level: 2,
    code: "Beginners+",
    name: "Beginners+",
  },
  {
    level: 3,
    code: "Intermediate",
    name: "Intermediate",
    rule: {
      jumps: [
        {
          code: "S",
          number: 1,
        },
        {
          code: "T",
          number: 1,
        },
      ],
      rotations: [{ code: "USP" }, { code: "SSP" }, { code: "CSP" }],
      sequence: [
        { code: "STSQ", level: "B" },
        { code: "CHSQ", level: "B" },
      ],
    },
  },
  {
    level: 4,
    code: "pre-Bronze",
    name: "pre-Bronze / Pre-Chicks",
    rule: {
      jumps: [
        {
          code: "S",
          number: 1,
        },
        {
          code: "T",
          number: 1,
        },
        {
          code: "Lo",
          number: 1,
        },
      ],
      rotations: [{ code: "USP" }, { code: "SSP" }, { code: "CSP" }],
      sequence: [
        { code: "STSQ", level: "1" },
        { code: "CHSQ", level: "1" },
      ],
    },
  },
  {
    level: 5,
    code: "bronze",
    name: "Bronze",
    rule: {
      jumps: [
        {
          code: "S",
          number: 1,
        },
        {
          code: "T",
          number: 1,
        },
        {
          code: "Lo",
          number: 1,
        },
        {
          code: "Lz",
          number: 1,
        },
        {
          code: "F",
          number: 1,
        },
      ],
      rotations: [
        { code: "USP" },
        { code: "SSP" },
        { code: "CSP" },
        { code: "COSP" },
        { code: "CCOSP" },
      ],
      sequence: [
        { code: "STSQ", level: "1" },
        { code: "CHSQ", level: "1" },
      ],
    },
  },
  {
    level: 6,
    code: "silver",
    name: "Silver",
    rule: {
      age: { min: 9 },
      jumps: [
        {
          code: "S",
          number: 1,
        },
        {
          code: "T",
          number: 1,
        },
        {
          code: "Lo",
          number: 1,
        },
        {
          code: "Lz",
          number: 1,
        },
        {
          code: "F",
          number: 1,
        },
        {
          code: "A",
          number: 1,
        },
      ],
      rotations: [
        { code: "USP" },
        { code: "SSP" },
        { code: "CSP" },
        { code: "COSP" },
        { code: "CCOSP" },
        { code: "FCCOSP" },
      ],
      sequence: [
        { code: "STSQ", level: "2" },
        { code: "CHSQ", level: "2" },
      ],
    },
  },
  {
    level: 7,
    code: "B1",
    name: "Категорія B1",
    rule: {
      age: { min: 6, max: 9 },
      jumps: [
        {
          code: "S",
          number: 1,
        },
        {
          code: "T",
          number: 2,
        },
        {
          code: "Lo",
          number: 1,
        },
        {
          code: "Lz",
          number: 1,
        },
        {
          code: "F",
          number: 1,
        },
        {
          code: "A",
          number: 1,
        },
      ],
      rotations: [
        { code: "USP" },
        { code: "SSP" },
        { code: "CSP" },
        { code: "COSP" },
        { code: "CCOSP" },
        { code: "FCCOSP" },
      ],
      sequence: [
        { code: "STSQ", level: "2" },
        { code: "CHSQ", level: "2" },
      ],
    },
  },
  {
    level: 7,
    code: "B2",
    name: "Категорія B2",
    rule: {
      age: { min: 6, max: 9 },
      jumps: [
        {
          code: "S",
          number: 2,
        },
        {
          code: "T",
          number: 2,
        },
        {
          code: "Lo",
          number: 1,
        },
        {
          code: "Lz",
          number: 1,
        },
        {
          code: "F",
          number: 1,
        },
        {
          code: "A",
          number: 1,
        },
      ],
      rotations: [
        { code: "USP" },
        { code: "SSP" },
        { code: "CSP" },
        { code: "COSP" },
        { code: "CCOSP" },
        { code: "FCCOSP" },
      ],
      sequence: [
        { code: "STSQ", level: "3" },
        { code: "CHSQ", level: "2" },
      ],
    },
  },
  {
    level: 8,
    code: "Gold",
    name: "Gold",
    rule: {
      age: { min: 9 },
      jumps: [
        {
          code: "S",
          number: 2,
        },
        {
          code: "T",
          number: 2,
        },
        {
          code: "Lo",
          number: 1,
        },
        {
          code: "Lz",
          number: 1,
        },
        {
          code: "F",
          number: 1,
        },
        {
          code: "A",
          number: 1,
        },
      ],
      rotations: [
        { code: "USP" },
        { code: "SSP" },
        { code: "CSP" },
        { code: "COSP" },
        { code: "CCOSP" },
        { code: "FCCOSP" },
      ],
      sequence: [
        { code: "STSQ", level: "3" },
        { code: "CHSQ", level: "2" },
      ],
    },
  },
  {
    level: 8,
    code: "A",
    name: "Категорія A",
    rule: {
      age: { min: 6, max: 9 },
      jumps: [
        {
          code: "S",
          number: 2,
        },
        {
          code: "T",
          number: 2,
        },
        {
          code: "Lo",
          number: 2,
        },
        {
          code: "Lz",
          number: 2,
        },
        {
          code: "F",
          number: 2,
        },
        {
          code: "A",
          number: 2,
        },
      ],
      rotations: [
        { code: "USP" },
        { code: "SSP" },
        { code: "CSP" },
        { code: "COSP" },
        { code: "CCOSP" },
        { code: "FCCOSP" },
      ],
      sequence: [
        { code: "STSQ", level: "4" },
        { code: "CHSQ", level: "4" },
      ],
    },
  },
];

const GetAgeRestrictionLabel = (age) => {
  if (isEmpty(age)) {
    return "";
  }
  let lbl = "";
  if (age.min) {
    lbl += `від ${age.min} `;
  }
  if (age.max) {
    lbl += `до ${age.max} `;
  }

  return `${lbl}років`;
};

const GetElementBaseValue = (code) => {
  const el = ElementsBaseValues.find((e) => e.code === code);
  return (el?.value ?? 0).toFixed(2);
};

const CalculateGOE = (baseValue, j1, j2, j3) => {
  const t1 = (baseValue ?? 0) / 10;
  const t2 = ((j1 ?? 0) + (j2 ?? 0) + (j3 ?? 0)) / 3;
  return (t1 * t2).toFixed(2);
};
export { ScoringLevels, GetAgeRestrictionLabel, GetElementBaseValue, CalculateGOE };
