/* eslint-disable no-undef */
/* eslint-disable react/destructuring-assignment */
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { useLocation } from "react-router-dom";
import { ShowAlert, AlertTypes, ShowConfirmationAlert } from "components/Alerts";
import { useEffect, useState } from "react";
import { isEmpty, getDepartment } from "helpers/strhelper";
import ConfigService from "api/ConfigService";
import CustomCard from "components/CustomCard";
import { Grid, Skeleton } from "@mui/material";
import MDButton from "components/MDButton";
import { AddAPhoto, AddCircleOutline } from "@mui/icons-material";
import MDInput from "components/MDInput";
import resizeFile from "helpers/ImageResizer";
import MediaService from "api/MediaService";
import GeneralSectionItem from "./Forms/SectionItemGeneral";
import EditGeneralSectionItem from "./Forms/EditGeneralSectionItem";

const uuid = require("uuid");

export default function SectionWithCoverImageSettings() {
  const { search } = useLocation();
  const route = useLocation();

  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [, setRequestInProgress] = useState(false);

  const [currentDepartment, setCurrentDepartment] = useState("");
  const [sectionSettings, setSectionSettings] = useState();

  const [sectionImage, setSectionImage] = useState();
  const [imageChanged, setImageChanged] = useState(false);
  const [imagePrevUrl, setImagePrevUrl] = useState("");

  const [showEditForm, setShowEditForm] = useState(false);
  const [editContext, setEditContext] = useState(null);

  const handleChangeCoverImage = (e) => {
    const imgFile = {
      file: e.target.files[0],
      url: URL.createObjectURL(e.target.files[0]),
    };

    setImageChanged(true);
    setSectionImage(imgFile.file);
    setImagePrevUrl(imgFile.url);
  };

  const handleSaveChanges = async () => {
    try {
      setRequestInProgress(true);
      const recordToUpdate = { ...sectionSettings };

      const currentPageName = `${route.pathname.replace("/settings/sections/", "")}`;

      if (imageChanged) {
        const filepath = `sections/${currentDepartment}/${currentPageName}`;
        const filename = `s_cover_img.${sectionImage.name.split(".")[1]}`;
        const imagetoSave = await resizeFile(sectionImage, 720, 720);
        const response = await MediaService.uploadMediaToS3(imagetoSave, filepath, filename);
        // //response.location - file path
        recordToUpdate.img_url = response.Location;
      }

      await ConfigService.updateSectionSettings(recordToUpdate);

      setImageChanged(false);
      setSectionImage(null);
      ShowAlert("Зміни Збережено", AlertTypes.Success, true);
    } catch (error) {
      const msg = "Невдалося отримати данні з сервера для сторінки";
      ShowAlert(msg, AlertTypes.Error);
    } finally {
      setRequestInProgress(false);
    }
  };

  const handleDeleteSectionItem = (itemId) => {
    const newValues = (sectionSettings?.values ?? []).filter((x) => x.id !== itemId);
    const newSettings = { ...sectionSettings, values: newValues };

    setSectionSettings(newSettings);
  };

  const handleSectionItemChange = (newItem) => {
    if (isEmpty(newItem.id)) {
      const newValues = [
        ...(sectionSettings?.values ?? []),
        {
          ...newItem,
          id: uuid.v1(),
        },
      ];
      const newSettings = { ...sectionSettings, values: newValues };
      setSectionSettings(newSettings);
    } else {
      const itemsWithoutOld = (sectionSettings?.values ?? []).filter((x) => x.id !== newItem.id);
      const newSettings = { ...sectionSettings, values: [...itemsWithoutOld, newItem] };
      setSectionSettings(newSettings);
    }
    setShowEditForm(false);
  };

  const fetchData = async () => {
    try {
      const linkdep = getDepartment(search);
      setCurrentDepartment(linkdep);
      const currentName = route.pathname.replace("/settings/sections/", "");

      const response = await ConfigService.getSectionSettings(currentName, linkdep);
      setSectionSettings(response?.id ? response : { id: `${currentName}_${linkdep}` });

      setImagePrevUrl(response.img_url);
      setImageChanged(false);
      setSectionImage(null);
    } catch (error) {
      const msg = "Невдалося отримати данні з сервера для сторінки";
      ShowAlert(msg, AlertTypes.Error);
    } finally {
      setPageIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(search);
  }, [search]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {isEmpty(currentDepartment) && (
          <div>
            <br />
            <h2>Виберіть секцію: ковзани або ролики</h2>
          </div>
        )}
        {!isEmpty(currentDepartment) && pageIsLoading && (
          <div>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
        )}
        {!isEmpty(currentDepartment) && !pageIsLoading && showEditForm && (
          <EditGeneralSectionItem
            context={editContext}
            onSubmit={handleSectionItemChange}
            onCancel={() => {
              setEditContext(null);
              setShowEditForm(false);
            }}
          />
        )}
        {!isEmpty(currentDepartment) && !pageIsLoading && !showEditForm && (
          <MDBox>
            <MDBox style={{ marginBottom: 25 }}>
              <CustomCard
                title="Заголовок Секції"
                content={
                  <div>
                    <Grid container style={{ paddingTop: 15 }}>
                      <Grid item xs={12}>
                        <div
                          style={{
                            backgroundImage: `url(${imagePrevUrl})`,
                            backgroundSize: "cover",
                            height: 375,
                            marginBottom: 20,
                            padding: 10,
                            borderRadius: 5,
                          }}
                        >
                          <input
                            style={{ display: "none" }}
                            accept="image/*"
                            id="bg-img-files"
                            multiple
                            type="file"
                            onChange={(e) => {
                              handleChangeCoverImage(e);
                            }}
                          />
                          <label htmlFor="bg-img-files">
                            <MDButton variant="contained" color="primary" component="span">
                              <AddAPhoto />
                            </MDButton>
                          </label>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                        <MDInput
                          label="Заголовок"
                          id="title"
                          fullWidth
                          multiline
                          rows={2}
                          value={sectionSettings?.title ?? ""}
                          onChange={(event) =>
                            setSectionSettings({ ...sectionSettings, title: event.target.value })
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                        <MDInput
                          label="Заголовок (EN)"
                          id="title_en"
                          fullWidth
                          multiline
                          rows={2}
                          value={sectionSettings?.title_en ?? ""}
                          onChange={(event) =>
                            setSectionSettings({ ...sectionSettings, title_en: event.target.value })
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                        <MDInput
                          label="Короткий Опис"
                          id="description"
                          fullWidth
                          multiline
                          rows={3}
                          value={sectionSettings?.description ?? ""}
                          onChange={(event) =>
                            setSectionSettings({
                              ...sectionSettings,
                              description: event.target.value,
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                        <MDInput
                          label="Короткий Опис (EN)"
                          id="description_en"
                          fullWidth
                          multiline
                          rows={3}
                          value={sectionSettings?.description_en ?? ""}
                          onChange={(event) =>
                            setSectionSettings({
                              ...sectionSettings,
                              description_en: event.target.value,
                            })
                          }
                        />
                      </Grid>
                    </Grid>
                  </div>
                }
              />
            </MDBox>
            <MDBox style={{ marginBottom: 10 }}>
              <CustomCard
                title="Елементи Секції"
                content={
                  <Grid container>
                    {(sectionSettings?.values ?? []).map((prop) => (
                      <Grid item xs={12} key={prop.id}>
                        <GeneralSectionItem
                          context={prop}
                          onEdit={() => {
                            setShowEditForm(true);
                            setEditContext(prop);
                          }}
                          onDelete={() => {
                            ShowConfirmationAlert(
                              "Видалити запис?",
                              "Так, видалити!",
                              handleDeleteSectionItem,
                              prop.id
                            );
                          }}
                        />
                      </Grid>
                    ))}
                    <Grid item xs={12}>
                      <MDButton
                        color="success"
                        onClick={() => {
                          setEditContext(null);
                          setShowEditForm(true);
                        }}
                      >
                        <AddCircleOutline /> Добавити Елемент
                      </MDButton>
                    </Grid>
                  </Grid>
                }
              />
            </MDBox>
            <Grid container alignItems="center">
              <Grid item xs={12} style={{ padding: 5 }}>
                <MDButton
                  style={{ marginRight: 10 }}
                  variant="gradient"
                  color="info"
                  onClick={handleSaveChanges}
                >
                  Зберегти
                </MDButton>
                <MDButton
                  variant="gradient"
                  color="dark"
                  onClick={() => {
                    fetchData();
                    ShowAlert("Зміни Відмінено", AlertTypes.Success);
                  }}
                >
                  Відміна
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
        )}
      </MDBox>
    </DashboardLayout>
  );
}
