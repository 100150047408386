/* eslint-disable react/no-array-index-key */
import MDBox from "components/MDBox";
import PropTypes from "prop-types";
import { isEmpty } from "helpers/strhelper";
import { Divider, Grid } from "@mui/material";
import MDButton from "components/MDButton";
import { Close, Edit } from "@mui/icons-material";
import MDTypography from "components/MDTypography";

function SectionItemGeneral({ context, onEdit, onDelete }) {
  return (
    <MDBox py={3} style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 10, paddingRight: 10 }}>
      <Grid container>
        <Grid item xs={1}>
          <span className="material-icons">{context.icon_name}</span>
        </Grid>
        <Grid item xs={10}>
          <MDTypography variant="h6">{context.title}</MDTypography>
          <MDTypography variant="overline">{context.category}</MDTypography>

          {!isEmpty(context?.description) && (
            <MDTypography variant="subtitle2">
              {context.description.split("\n").map((x, index) => (
                <p key={`h4_${index}`}>{x}</p>
              ))}
            </MDTypography>
          )}
        </Grid>
        <Grid item xs={1}>
          <MDButton
            iconOnly
            circular
            variant="outlined"
            color="dark"
            onClick={onEdit}
            style={{ margin: 5 }}
          >
            <Edit />
          </MDButton>
          <MDButton
            iconOnly
            circular
            variant="outlined"
            color="error"
            onClick={onDelete}
            style={{ margin: 5 }}
          >
            <Close />
          </MDButton>
        </Grid>
      </Grid>
      <Divider style={{ marginTop: 20 }} />
    </MDBox>
  );
}

SectionItemGeneral.defaultProps = {};

SectionItemGeneral.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  context: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default SectionItemGeneral;
