import { API } from "aws-amplify";
import { appConfig } from "config/appConfig";

const AccountService = {
  async addIdentityAccount(prop) {
    const requestBody = {
      email: prop.email,
      name: prop.name,
      family_name: prop.family_name,
      address: prop.address,
      zoneinfo: prop.zoneinfo,
      temp_pass: prop.temp_password,
    };

    const path = "/accounts";
    const params = {
      response: true,
      body: requestBody,
    };
    return API.post(appConfig.SECURE_API_NAME, path, params);
  },

  async deleteIdentityAccount(prop) {
    const path = `/accounts/${prop}`;
    const params = {
      response: false,
    };
    return API.del(appConfig.SECURE_API_NAME, path, params);
  },

  async getIdentityAccounts() {
    const path = "/accounts";
    const params = {
      response: false,
    };
    return API.get(appConfig.SECURE_API_NAME, path, params);
  },

  async updateAttributes(username, attributes) {
    const path = `/accounts/${username}`;
    const params = {
      response: false,
      body: attributes,
    };
    return API.put(appConfig.SECURE_API_NAME, path, params);
  },

  async setPassword(username, pass) {
    const path = `/auth/password`;
    const params = {
      response: true,
      body: {
        user_name: username,
        new_password: pass,
      },
    };
    return API.post(appConfig.SECURE_API_NAME, path, params);
  },

  async listUserGroups(username) {
    const path = `/accounts/${username}/groups`;
    const params = {
      response: false,
    };
    return API.get(appConfig.SECURE_API_NAME, path, params);
  },

  async assignUserToGroup(username, groupname) {
    const path = `/accounts/${username}/groups`;
    const params = {
      response: false,
      body: {
        Groupname: groupname,
      },
    };
    return API.put(appConfig.SECURE_API_NAME, path, params);
  },

  async removeUserFromGroup(username, groupname) {
    const path = `/accounts/${username}/groups`;
    const params = {
      response: false,
      body: {
        Groupname: groupname,
      },
    };

    return API.del(appConfig.SECURE_API_NAME, path, params);
  },
};

export default AccountService;
