/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable prefer-template */
/* eslint-disable object-shorthand */
import { isEmpty } from "helpers/strhelper";

import { API, Auth } from "aws-amplify";
import { appConfig } from "config/appConfig";

const groupMembersCacheKey = "groupMembersCacheKey_";
const UsersService = {
  async loadAll(reloadCache) {
    const cacheKey = `configCacheKey_users`;
    if (!reloadCache) {
      const cacheData = sessionStorage.getItem(cacheKey);
      if (!isEmpty(cacheData) && cacheData !== "undefined") {
        return JSON.parse(cacheData);
      }
    }
    const path = "/users";
    const params = {
      response: false,
    };
    const response = await API.get(appConfig.SECURE_API_NAME, path, params);
    if (response && response.length > 0) {
      sessionStorage.setItem(cacheKey, JSON.stringify(response));
      return response;
    }
    return [];
  },

  loadUnassigned() {
    const path = "/users";
    const params = {
      response: true,
      queryStringParameters: {
        getunassigned: "true",
      },
    };
    return API.get(appConfig.SECURE_API_NAME, path, params);
  },

  loadBirthdays() {
    const path = "/users-birthdays";
    const params = {
      response: true,
    };
    return API.get(appConfig.SECURE_API_NAME, path, params);
  },

  async loadByGroupId(groupid, refreshCache) {
    const cacheKey = groupMembersCacheKey + groupid;
    if (refreshCache) {
      sessionStorage.removeItem(cacheKey);
    }

    const cachedData = sessionStorage.getItem(cacheKey);
    if (!isEmpty(cachedData)) {
      return JSON.parse(cachedData);
    }

    const path = "/users";
    const params = {
      response: true,
      queryStringParameters: {
        groupid: groupid,
      },
    };
    const response = await API.get(appConfig.SECURE_API_NAME, path, params);

    sessionStorage.setItem(cacheKey, JSON.stringify(response.data));

    return response.data;
  },

  async loadProfiles(accountname, refreshCache) {
    try {
      const cacheKey = "accprofiles:" + accountname.toLowerCase();

      if (refreshCache) {
        sessionStorage.removeItem(cacheKey);
      }

      const cachedData = sessionStorage.getItem(cacheKey);
      if (!isEmpty(cachedData)) {
        return JSON.parse(cachedData);
      }

      const path = "/users";
      const params = {
        response: true,
        queryStringParameters: {
          accountid: accountname.toLowerCase(),
        },
      };
      const response = await API.get(appConfig.SECURE_API_NAME, path, params);

      sessionStorage.setItem(cacheKey, JSON.stringify(response.data));
      return response.data;
    } catch (error) {
      return [];
    }
  },

  async getCurrentAccountProfiles() {
    const cachedData = sessionStorage.getItem("accountProfiles");
    if (!isEmpty(cachedData)) {
      return JSON.parse(cachedData);
    }
    const currentuser = await Auth.currentAuthenticatedUser();
    return this.loadProfiles(currentuser.username);
  },

  async getProfileById(profileId, refreshCache) {
    try {
      const cacheKey = "profileid:" + profileId;

      if (refreshCache) {
        sessionStorage.removeItem(cacheKey);
      }

      const cachedData = sessionStorage.getItem(cacheKey);
      if (!isEmpty(cachedData)) {
        return JSON.parse(cachedData)[0];
      }

      const path = "/users";
      const params = {
        response: true,
        queryStringParameters: {
          id: profileId,
        },
      };
      const response = await API.get(appConfig.SECURE_API_NAME, path, params);

      sessionStorage.setItem(cacheKey, JSON.stringify(response.data));
      return response.data[0];
    } catch (error) {
      return {};
    }
  },

  addProfile(accountId, profileRecord) {
    const record = {
      accountid: accountId,
      firstname: profileRecord.firstname,
      familyname: profileRecord.familyname,
      birthdate: profileRecord.birthdate,
      groupid: profileRecord.groupid,
      avatarurl: profileRecord.avatarurl ?? "",
    };
    const path = "/users";
    const params = {
      response: true,
      body: record,
    };
    return API.post(appConfig.SECURE_API_NAME, path, params);
  },

  updateProfile(profileRecord) {
    const path = "/users/" + profileRecord.id;
    const params = {
      response: true,
      body: profileRecord,
    };
    return API.put(appConfig.SECURE_API_NAME, path, params);
  },

  deleteProfile(itemToDelete) {
    const path = "/users/" + itemToDelete;
    const params = {
      response: true,
    };
    return API.del(appConfig.SECURE_API_NAME, path, params);
  },

  updateProfileTestResults(resultsRecord) {
    const path = "/user-results";
    const params = {
      response: false,
      body: resultsRecord,
    };
    return API.put(appConfig.SECURE_API_NAME, path, params);
  },

  getTestResults(accountid, profileid) {
    const path = "/user-results";
    const params = {
      response: false,
      queryStringParameters: {
        accountid: accountid.toLowerCase(),
        profileid: profileid,
      },
    };
    return API.get(appConfig.SECURE_API_NAME, path, params);
  },

  deleteTestResults(recordId) {
    const path = "/user-results/" + recordId;
    const params = {
      response: false,
    };
    return API.del(appConfig.SECURE_API_NAME, path, params);
  },

  getTestResultsById(resultsId, profileId) {
    const path = "/user-results";
    const params = {
      response: false,
      queryStringParameters: {
        id: resultsId,
        profileid: profileId,
      },
    };

    return API.get(appConfig.SECURE_API_NAME, path, params);
  },

  searchTestResultsByProfile(profileIds) {
    const path = "/user-results/search";
    const params = {
      response: false,
      body: profileIds,
    };

    return API.post(appConfig.SECURE_API_NAME, path, params);
  },

  getIdentityAccounts() {
    const path = "/admin/users";
    const params = {
      response: false,
    };
    return API.get(appConfig.SECURE_API_NAME, path, params);
  },

  async listUserGroups(username) {
    const path = `/admin/users/${username}/groups`;
    const params = {
      response: false,
    };
    return API.get(appConfig.SECURE_API_NAME, path, params);
  },

  async assignUserToGroup(username, groupname) {
    const path = `/admin/users/${username}/groups`;
    const params = {
      response: false,
      body: {
        Groupname: groupname,
      },
    };
    return API.put(appConfig.SECURE_API_NAME, path, params);
  },

  async removeUserFromGroup(username, groupname) {
    const path = `/admin/users/${username}/groups`;
    const params = {
      response: false,
      body: {
        Groupname: groupname,
      },
    };
    return API.del(appConfig.SECURE_API_NAME, path, params);
  },

  async assignLinkedProfiles(profiles, accountemail) {
    if (profiles?.length > 0) {
      profiles.map((x) => {
        const updateRecord = {
          id: x.profileid,
          accountid: accountemail,
        };
        this.updateProfile(updateRecord).catch((error) => {
          console.error("Unable update User", error);
        });

        console.debug("delete already used invite code");
        const path = "/codes/" + x.id;
        API.del(appConfig.SECURE_API_NAME, path).catch((error) => {
          console.error("Unable delete invite code from DB", error);
        });
      });
    }
  },

  getISUTestResults(accountid, profileid) {
    const path = "/isuresults";
    const params = {
      response: false,
      queryStringParameters: {},
    };
    if (accountid) {
      params.queryStringParameters.accountid = accountid.toLowerCase();
    }
    if (profileid) {
      params.queryStringParameters.profileid = profileid;
    }
    return API.get(appConfig.SECURE_API_NAME, path, params);
  },

  deleteISUTestResults(recordId) {
    const path = "/isuresults/" + recordId;
    const params = {
      response: false,
    };
    return API.del(appConfig.SECURE_API_NAME, path, params);
  },

  updateISUTestResults(resultsRecord) {
    const path = "/isuresults";
    const params = {
      response: false,
      body: resultsRecord,
    };
    return API.put(appConfig.SECURE_API_NAME, path, params);
  },
};

export default UsersService;
