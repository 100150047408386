/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import { useState, useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 PRO React example components
import Sidenav from "components/Sidenav";
import Configurator from "components/Configurator";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 PRO React routes
import routes from "routes";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav } from "context";

// Images
import brandWhite from "assets/images/logo-white.png";
import brandDark from "assets/images/logo-dark.png";
import SignInBasic from "layouts/authentication/sign-in";
import { Auth } from "aws-amplify";
import MDAvatar from "components/MDAvatar";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import uaLocale from "date-fns/locale/uk";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [actualRoutes, setActualRoutes] = useState([]);

  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  useEffect(() => {
    if (window.location.pathname !== "/auth/sign-in") {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          // setActualRoutes
          const routesToUpdate = [...routes];
          const profileRoute = routesToUpdate.find((x) => x.key === "user_profile");
          profileRoute.name = `${user.attributes.name ?? ""} ${user.attributes.family_name}` ?? "";
          profileRoute.icon = <MDAvatar src={user.attributes.avatarurl ?? ""} size="sm" />;
          setActualRoutes(routesToUpdate);
        })
        .catch((er) => {
          console.log("er", er);
          window.location.href = "/auth/sign-in";
        });
    }
  }, []);

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={uaLocale}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="Bauer Figuer Skating"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          <Route exact path="/auth/sign-in" element={<SignInBasic />} />
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/dashboards/analytics" />} />
        </Routes>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
