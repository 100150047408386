import { useState } from "react";
import PropTypes from "prop-types";

import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDInput";
import MDEditor from "components/MDEditor";

function VideoGeneralStep({ context, onChange }) {
  const [articleTitle, setArticleTitle] = useState(context.title ?? "");
  const [articleDescription, setArticleDescription] = useState(context.description ?? "");
  const [articleCategory, setArticleCategory] = useState(context.record_category ?? "");

  return (
    <MDBox>
      <Grid container alignItems="center">
        <Grid item xs={10} md={8}>
          <Grid container alignItems="center">
            <Grid item xs={12} style={{ padding: 10 }}>
              <MDInput
                fullWidth
                label="Заголовок"
                value={articleTitle}
                onChange={(event) => {
                  setArticleTitle(event.target.value);
                  onChange({ title: event.target.value });
                }}
              />
            </Grid>
            <Grid item xs={12} style={{ padding: 10 }}>
              <MDInput
                fullWidth
                label="Категорія"
                value={articleCategory}
                onChange={(event) => {
                  setArticleCategory(event.target.value);
                  onChange({ record_category: event.target.value });
                }}
              />
            </Grid>
            <Grid item xs={12} style={{ padding: 10 }}>
              <MDEditor
                fullWidth
                label="Опис"
                value={articleDescription}
                onChange={(event) => {
                  setArticleDescription(event);
                  onChange({ description: event });
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MDBox>
  );
}

VideoGeneralStep.defaultProps = {};

VideoGeneralStep.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  context: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default VideoGeneralStep;
