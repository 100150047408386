const appConfig = {
  MEDIA_BASE_URL: "https://media.bauer.school",
  BUCKET_NAME: "media.bauer.school",
  IDENTITY_POOL_ID: "eu-central-1:6c9d302d-865f-4558-abba-011c8522e0f5",
  GAPI_KEY: "AIzaSyBOniLu7btnjcPOvc1D83tgD3E7QKDJmws",
  GANALYTICS_KEY: "G-SHJCDS3ZXW",
  Auth: {
    region: "eu-central-1",
    userPoolId: "eu-central-1_eXKmFDaex",
    userPoolWebClientId: "4fcgjemj1due5tbc37hajtpbgq",
    mandatorySignIn: false,
    authenticationFlowType: "USER_PASSWORD_AUTH",
  },
  SECURE_API_NAME: "BAUER_PRIVATE_API",
  API_GATEWAY: "https://api.bauer.school",
};

module.exports = {
  appConfig,
};
