import MDBox from "components/MDBox";
import PropTypes from "prop-types";
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import MDButton from "components/MDButton";
import { useState } from "react";
import MDInput from "components/MDInput";
import CustomCard from "components/CustomCard";
import { AddAPhoto } from "@mui/icons-material";
import defaultImage from "assets/images/image_placeholder.jpg";
import { isEmpty } from "helpers/strhelper";
import MDEditor from "components/MDEditor";

function EditImgSectionItem({ context, onSubmit, onCancel }) {
  const [title, setTitle] = useState(context?.title ?? "");
  const [titleEn, setTitleEn] = useState(context?.title_en ?? "");

  const [description, setDescription] = useState(context?.description ?? "");
  const [descriptionEn, setDescriptionEn] = useState(context?.description_en ?? "");

  const [category, setCategory] = useState(context?.category ?? "");
  const [categoryEn, setCategoryEn] = useState(context?.category_en ?? "");

  const [displayType, setDisplayType] = useState(context?.displaytype ?? "0");

  const [itemImage, setItemImage] = useState();
  const [imageChanged, setImageChanged] = useState(false);
  const [imagePrevUrl, setImagePrevUrl] = useState(context?.img_url ?? "");

  const handleChangeImage = (e) => {
    const imgFile = {
      file: e.target.files[0],
      url: URL.createObjectURL(e.target.files[0]),
    };

    setImageChanged(true);
    setItemImage(imgFile.file);
    setImagePrevUrl(imgFile.url);
  };

  return (
    <MDBox py={3} style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 10, paddingRight: 10 }}>
      <MDBox style={{ marginBottom: 10, paddingTop: 0 }}>
        <CustomCard
          title="Редагування Запису"
          content={
            <Grid container alignItems="flex-start" justifyContent="center">
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Gender</FormLabel>
                  <RadioGroup
                    row
                    aria-label="orientation"
                    name="controlled-radio-buttons-group"
                    value={displayType}
                    onChange={(e) => {
                      setDisplayType(e.target.value);
                    }}
                  >
                    <FormControlLabel value="0" control={<Radio />} label="Зліва" />
                    <FormControlLabel value="1" control={<Radio />} label="По Центру" />
                    <FormControlLabel value="2" control={<Radio />} label="Зправа" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} display="grid">
                <img
                  src={isEmpty(imagePrevUrl) ? defaultImage : imagePrevUrl}
                  alt="..."
                  style={{ maxWidth: 150, marginTop: 35, marginBottom: 5, borderRadius: 10 }}
                />

                <input
                  style={{ display: "none" }}
                  accept="image/*"
                  id="img-file"
                  multiple
                  type="file"
                  onChange={(e) => {
                    handleChangeImage(e);
                  }}
                />
                <label htmlFor="img-file">
                  <MDButton variant="gradient" color="info" component="span" style={{ margin: 10 }}>
                    <AddAPhoto style={{ marginRight: 5 }} />
                    Змінити
                  </MDButton>
                </label>
              </Grid>
              <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                <MDInput
                  label="Назва"
                  id="title"
                  fullWidth
                  value={title}
                  onChange={(event) => setTitle(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                <MDInput
                  label="Назва (EN)"
                  id="title_en"
                  fullWidth
                  value={titleEn}
                  onChange={(event) => setTitleEn(event.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                <MDInput
                  label="Категорія"
                  id="category"
                  fullWidth
                  multiline
                  rows={2}
                  value={category}
                  onChange={(event) => setCategory(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                <MDInput
                  label="Категорія (EN)"
                  id="category_en"
                  fullWidth
                  multiline
                  rows={2}
                  value={categoryEn}
                  onChange={(event) => setCategoryEn(event.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                <MDEditor
                  fullWidth
                  label="Опис"
                  value={description}
                  onChange={(event) => {
                    setDescription(event);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                <MDEditor
                  fullWidth
                  label="Опис  (EN)"
                  value={descriptionEn}
                  onChange={(event) => {
                    setDescriptionEn(event);
                  }}
                />
              </Grid>
            </Grid>
          }
        />
      </MDBox>

      <Grid container alignItems="center">
        <Grid item xs={12} style={{ padding: 5 }}>
          <MDButton
            style={{ marginRight: 10, width: 125 }}
            variant="gradient"
            color="info"
            onClick={() => {
              onSubmit({
                id: context?.id,
                title,
                title_en: titleEn,
                category,
                category_en: categoryEn,
                description,
                description_en: descriptionEn,
                displaytype: displayType,
                img_url: context?.img_url,
                imageChanged,
                imagePrevUrl,
                itemImage,
              });
            }}
          >
            OK
          </MDButton>
          <MDButton variant="gradient" color="dark" style={{ width: 125 }} onClick={onCancel}>
            Відміна
          </MDButton>
        </Grid>
      </Grid>
    </MDBox>
  );
}

EditImgSectionItem.defaultProps = {
  context: null,
};

EditImgSectionItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  context: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EditImgSectionItem;
