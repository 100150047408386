import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import { ShowAlert, AlertTypes } from "components/Alerts";

import Grid from "@mui/material/Grid";
import { Autocomplete, Skeleton } from "@mui/material";

import CustomCard from "components/CustomCard";

import UsersService from "api/UsersService";
import MDInput from "components/MDInput";
import IsuEditForm from "./Forms/IsuEditForm";
import ISUResultCardFlat from "./Forms/ISUResultCardFlat";

export default function ISUTestingResultsPage() {
  const [pageIsLoading, setPageIsLoading] = useState(true);

  const [usersList, setUsersList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const [resultsList, setResultsList] = useState([]);

  const [showEditForm, setShowEditForm] = useState(false);
  const [editContext, setEditContext] = useState();

  const fetchData = async () => {
    try {
      const response = await UsersService.loadAll();
      setUsersList(
        (response ?? []).sort((a, b) =>
          a.firstname + a.familyname > b.firstname + b.familyname ? 1 : -1
        )
      );
    } catch (error) {
      const msg = "Невдалося отримати данні з сервера для сторінки";
      ShowAlert(msg, AlertTypes.Error);
    } finally {
      setPageIsLoading(false);
    }
  };

  const handleLoadTestingResults = async (user) => {
    try {
      if (user) {
        const response = await UsersService.getISUTestResults(user.accountid, user.id);
        setResultsList(response.sort((a, b) => (a.createDate > b.createDate ? -1 : 1)));
      } else {
        setResultsList([]);
      }
    } catch (error) {
      const msg = "Невдалося отримати данні з сервера для сторінки";
      ShowAlert(msg, AlertTypes.Error);
    } finally {
      setPageIsLoading(false);
    }
  };

  const handleUpdateRecord = async (e) => {
    try {
      await UsersService.updateISUTestResults(e);
      setShowEditForm(false);
      handleLoadTestingResults(selectedUser);
      ShowAlert("Запис оновлено", AlertTypes.Success, true);
      setEditContext(null);
    } catch (error) {
      const msg = "Невдалося зберегти зміни";
      ShowAlert(msg, AlertTypes.Error);
    }
  };

  const handleDeleteRecord = async (e) => {
    try {
      await UsersService.deleteISUTestResults(e);
      handleLoadTestingResults(selectedUser);
      ShowAlert("Запис Видалено", AlertTypes.Success, true);
    } catch (error) {
      const msg = `Невдалося видалити запис. ${error.message}`;
      ShowAlert(msg, AlertTypes.Error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {pageIsLoading && (
          <div>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
        )}
        {!pageIsLoading && showEditForm && (
          <IsuEditForm
            user={selectedUser}
            ts={editContext}
            onSubmit={handleUpdateRecord}
            onCancel={() => setShowEditForm(false)}
          />
        )}
        {!pageIsLoading && !showEditForm && (
          <>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <CustomCard
                  title=""
                  iconName="quiz"
                  content={
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Autocomplete
                          fullWidth
                          disablePortal
                          id="users-select"
                          options={usersList}
                          renderInput={(params) => <MDInput {...params} label="Спортсмен" />}
                          getOptionLabel={(option) => `${option.firstname} ${option.familyname}`}
                          value={selectedUser}
                          onChange={(event, newValue) => {
                            setSelectedUser(newValue);
                            handleLoadTestingResults(newValue);
                          }}
                        />
                      </Grid>
                      {selectedUser && (
                        <Grid item xs={12}>
                          <MDButton
                            style={{ marginRight: 10, width: 250 }}
                            variant="gradient"
                            color="info"
                            onClick={() => {
                              setShowEditForm(true);
                            }}
                          >
                            Добавити Нове Тестування
                          </MDButton>
                        </Grid>
                      )}
                    </Grid>
                  }
                />
              </Grid>

              {resultsList.map((r) => (
                <Grid item key={r.id} xs={12}>
                  <ISUResultCardFlat
                    results={r}
                    onEdit={(record) => {
                      setEditContext(record);
                      setShowEditForm(true);
                    }}
                    onDelete={(id) => {
                      handleDeleteRecord(id);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </MDBox>
    </DashboardLayout>
  );
}
