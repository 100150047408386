/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from "react";
import PropTypes from "prop-types";

import CustomCard from "components/CustomCard";
import { Grid } from "@mui/material";

import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { DataGrid } from "@mui/x-data-grid";
import MDInput from "components/MDInput";

export default function EditResultsForm({ context, onCancel, onSubmit }) {
  const [testResults] = useState(context.elements ?? []);
  const [comments, setComments] = useState(context.comments ?? "");
  const [selectionModel, setSelectionModel] = React.useState([]);

  React.useEffect(() => {
    const selectedElements = context.elements.filter((x) => x.passed === true);
    setSelectionModel((selectedElements ?? []).map((x) => x.id));
  }, []);

  const columns = [
    {
      field: "title",
      headerName: "Елемент",
      flex: 1,
    },
  ];

  return (
    <MDBox>
      <MDBox style={{ paddingBottom: 20 }}>
        <CustomCard
          title="Редагування Запису"
          iconName="person"
          content={
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div
                  style={{
                    height: window.innerHeight < 850 ? 500 : window.innerHeight - 500,
                    width: "100%",
                  }}
                >
                  <DataGrid
                    checkboxSelection
                    onRowSelectionModelChange={(newSelectionModel) => {
                      setSelectionModel(newSelectionModel);
                    }}
                    rowSelectionModel={selectionModel}
                    rows={testResults}
                    columns={columns}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <MDInput
                  fullWidth
                  multiline
                  rows={6}
                  label="Коментар"
                  value={comments}
                  onChange={(event) => {
                    setComments(event.target.value);
                  }}
                />
              </Grid>
            </Grid>
          }
        />
      </MDBox>
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <MDButton
            style={{ marginRight: 10, width: 125 }}
            variant="gradient"
            color="info"
            onClick={() => {
              const updatedResults = { ...context, comments };
              updatedResults.elements = testResults.map((x) => ({
                id: x.id,
                title: x.title,
                passed: selectionModel.includes(x.id),
              }));
              onSubmit(updatedResults);
            }}
          >
            Зберегти
          </MDButton>
          <MDButton
            variant="gradient"
            color="dark"
            style={{ width: 125 }}
            onClick={() => {
              onCancel();
            }}
          >
            Відміна
          </MDButton>
        </Grid>
      </Grid>
    </MDBox>
  );
}

EditResultsForm.propTypes = {
  context: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
