/* eslint-disable no-console */
import React from "react";
import { Auth } from "aws-amplify";
import Swal from "sweetalert2";
// @material-ui/core components

// core components

import { isEmpty } from "helpers/strhelper";
import bgImage from "assets/images/main-bg.jpg";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { ShowAlert, AlertTypes } from "components/Alerts";
import BasicLayout from "../components/BasicLayout";

export default function ChangePasswordPage() {
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");

  const handleChangePassword = async () => {
    try {
      if (isEmpty(oldPassword)) {
        throw new Error("Поле пароля не може бути пустим.");
      }
      if (isEmpty(newPassword)) {
        throw new Error("Новий пароль не може бути пустим.");
      }
      // if (!userNewPassword.match(passwordFormat)) {
      //   throw new Error("Новий пароль не задовільняє вимоги безпеки.");
      // }
      if (oldPassword === newPassword) {
        throw new Error("Новий пароль не повинен співпадати з поточним.");
      }

      const profileRecord = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(profileRecord, oldPassword, newPassword);
      Swal.fire({
        title: "Пароль Змінено",
        confirmButtonText: "OK",
        icon: "success",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          window.location.href = "/";
        }
      });
    } catch (error) {
      console.error(error);
      let msg = "";
      if (error.code === "NotAuthorizedException") {
        msg = `Невірно введений старий пароль.`;
      } else {
        msg = `${error.message}`;
      }

      ShowAlert(msg, AlertTypes.Error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleChangePassword();
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Зміна Пароля
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Старий Пароль"
                fullWidth
                onChange={(event) => setOldPassword(event.target.value)}
                inputProps={{
                  autoComplete: "current-password",
                }}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Новий Пароль"
                fullWidth
                onChange={(event) => setNewPassword(event.target.value)}
                onKeyDown={handleKeyDown}
                inputProps={{
                  autoComplete: "off",
                }}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleChangePassword}>
                Змінити Пароль
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}
