const RotatingElements = [
  {
    type: "BaseSpins",
    code: "USp",
    name: "Стоячи (гвинт)",
  },
  {
    type: "BaseSpins",
    code: "LSp",
    name: "Заклон",
  },
  {
    type: "BaseSpins",
    code: "CSp",
    name: "Лібела",
  },
  {
    type: "BaseSpins",
    code: "SSp",
    name: "Сидячи (дзиґа)",
  },

  // complex spinning codes
  {
    type: "ComplexSpins",
    code: "FUSp",
    name: "Стрибок в гвинт",
  },
  {
    type: "ComplexSpins",
    code: "FLSp",
    name: "Стрибок в заклон",
  },
  {
    type: "ComplexSpins",
    code: "FCSp",
    name: "Cтрибок в лібелу",
  },
  {
    type: "ComplexSpins",
    code: "FSSp",
    name: "Cтрибок в дзиґу (Чинян)",
  },

  {
    type: "ComplexSpins",
    code: "CUSp",
    name: "Гвинт зі зміною ноги",
  },
  {
    type: "ComplexSpins",
    code: "CLSp",
    name: "Заклон зі зміною ноги",
  },
  {
    type: "ComplexSpins",
    code: "CCSp",
    name: "Лібела зі зміною ноги",
  },
  {
    type: "ComplexSpins",
    code: "CSSp",
    name: "Дзига зі зміною ноги",
  },

  {
    type: "ComplexSpins",
    code: "CoSp",
    name: "Комбіноване обертання без зміни ноги",
  },
  {
    type: "ComplexSpins",
    code: "CCoSp",
    name: "Комбіноване обертання зі зміною ноги",
  },

  // program spinning codes
  {
    type: "ProgramSpins",
    code: "FCSSp",
    name: "Дзига зі зміною ноги, захід стрибком",
  },
  {
    type: "ProgramSpins",
    code: "FCCSp",
    name: "Лібела зі зміною ноги, захід стрибком",
  },
  {
    type: "ProgramSpins",
    code: "FCoSp",
    name: "Комбіноване обертання на одній нозі з заходом стрибком",
  },
  {
    type: "ProgramSpins",
    code: "FCCoSp",
    name: "Комбіноване обертання зі зміною ноги, захід стрибком",
  },
];

const GetRotationDescription = (rcode, level) => {
  const el = RotatingElements.find((x) => x.code === rcode);
  let levelLbl = "";
  switch (level) {
    case "B":
      levelLbl = " (Базовий рівень)";
      break;
    case "1":
      levelLbl = " (Перший рівень)";
      break;
    case "2":
      levelLbl = " (Другий рівень)";
      break;
    case "3":
      levelLbl = " (Третій рівень)";
      break;
    case "4":
      levelLbl = " (Четвертий рівень)";
      break;
    default:
      break;
  }
  return el ? el.name + levelLbl : "";
};

export { RotatingElements, GetRotationDescription };
