import { useState } from "react";
import PropTypes from "prop-types";

import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import CustomCard from "components/CustomCard";

function EditSettingForm({ context, onSubmit, onCancel }) {
  const [editId, setEditId] = useState(context?.id ?? "");
  const [editValue, setEditValue] = useState(context?.title ?? "");

  return (
    <MDBox>
      <MDBox style={{ paddingBottom: 20 }}>
        <CustomCard
          title="Редагування Налаштувань"
          iconName="settings"
          content={
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12}>
                <MDInput
                  fullWidth
                  label="ID"
                  value={editId}
                  onChange={(event) => {
                    setEditId(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <MDInput
                  fullWidth
                  label="Значення"
                  value={editValue}
                  onChange={(event) => {
                    setEditValue(event.target.value);
                  }}
                />
              </Grid>
            </Grid>
          }
        />
      </MDBox>
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <MDButton
            style={{ marginRight: 10, width: 125 }}
            variant="gradient"
            color="info"
            onClick={() => {
              onSubmit({
                id: editId,
                title: editValue,
              });
            }}
          >
            Зберегти
          </MDButton>
          <MDButton
            variant="gradient"
            color="dark"
            style={{ width: 125 }}
            onClick={() => {
              onCancel();
            }}
          >
            Відміна
          </MDButton>
        </Grid>
      </Grid>
    </MDBox>
  );
}

EditSettingForm.defaultProps = {};

EditSettingForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  context: PropTypes.object.isRequired,
};

export default EditSettingForm;
