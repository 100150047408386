/* eslint-disable */
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { ShowAlert, AlertTypes, ShowConfirmationAlert } from "components/Alerts";

import Grid from "@mui/material/Grid";
import { Skeleton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { AddCircleOutline, DeleteForever, Edit } from "@mui/icons-material";

import { useEffect, useState } from "react";

import format from "date-fns/format";
import ukLocale from "date-fns/locale/uk";
import UsersService from "api/UsersService";
import ConfigService, { ConfigResources } from "api/ConfigService";

import CustomCard from "components/CustomCard";
import NewUserForm from "./Forms/NewUserForm";
import EditUserForm from "./Forms/EditUserForm";

export default function UsersPage() {
  const [dataset, setDataset] = useState([]);

  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [, setRequestInProgress] = useState(false);

  const [openNewForm, setOpenNewForm] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [editContext, setEditContext] = useState(null);

  const fetchData = async () => {
    try {
      await ConfigService.loadResources();
      const response = await UsersService.loadAll(true);
     
      setDataset(response);
    } catch (error) {
      const msg = "Невдалося отримати данні з сервера для сторінки";
      ShowAlert(msg, AlertTypes.Error);
    } finally {
      setPageIsLoading(false);
    }
  };

  const handleAddNewIdentity = async (e) => {
    try {
      setRequestInProgress(true);

      await UsersService.addProfile(e?.email ?? "", {
        firstname: e.firstname,
        familyname: e.familyname,
        birthdate: e.birthdate,
      });

      setOpenNewForm(false);
      fetchData();
      ShowAlert("Користувача Додано", AlertTypes.Success, true);
    } catch (error) {
      console.error(error)
      const msg = "Виникла помилка при обробці запиту";
      ShowAlert(msg, AlertTypes.Error);
    } finally {
      setRequestInProgress(false);
    }
  };

  const handleDeleteIdentity = async (prop) => {
    try {
      setRequestInProgress(true);
      await UsersService.deleteProfile(prop);
      fetchData();
      ShowAlert("Запис Видалено", AlertTypes.Success, true);
    } catch (error) {
      const msg = "Виникла помилка при обробці запиту";
      ShowAlert(msg, AlertTypes.Error);
    } finally {
      setRequestInProgress(false);
    }
  };

  const handleUpdateIdentity = async (e) => {
    try {
      setRequestInProgress(true);
      const profile = {
        id: e.id,
        accountid: e.accountid,
        avatarurl: e.avatarurl,
        birthdate: e.birthdate,
        firstname: e.firstname,
        familyname: e.familyname,
        groupid: e.groupid,
        categoryid: e.categoryid,
      };

      await UsersService.updateProfile(profile);
      fetchData();
      setOpenEditForm(false);

      ShowAlert("Зміни Збережено", AlertTypes.Success, true);
    } catch (error) {
      const msg = "Виникла помилка при обробці запиту";
      ShowAlert(msg, AlertTypes.Error);
    } finally {
      setRequestInProgress(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      field: "actions",
      headerName: "Дії",
      width: 100,
      renderCell: (params) => (
        <div>
          <MDButton
            circular
            iconOnly
            color="info"
            variant="gradient"
            style={{ margin: 5 }}
            onClick={() => {
              setEditContext({
                ...params.row,
              });
              setOpenEditForm(true);
            }}
          >
            <Edit />
          </MDButton>
          <MDButton
            circular
            iconOnly
            color="dark"
            variant="gradient"
            onClick={() => {
              ShowConfirmationAlert(
                "Видалити запис?",
                "Так, видалити!",
                handleDeleteIdentity,
                params.row.id
              );
            }}
          >
            <DeleteForever />
          </MDButton>
        </div>
      ),
    },
    {
      field: "firstname",
      headerName: "Ім'я",
      width: 120,
    },
    {
      field: "familyname",
      headerName: "Прізвище",
      width: 150,
    },
    {
      field: "birthdate",
      headerName: "День Народження",
      width: 200,
      valueGetter: (record) =>
        format(new Date(record.row.birthdate), "dd MMMM yyyy", { locale: ukLocale }),
    },
    {
      field: "groupid",
      headerName: "Група",
      width: 250,
      valueGetter: (record) =>
        ConfigService.getNamebyId(record.row.groupid ?? "", ConfigResources.Groups),
    },
    {
      field: "categoryid",
      headerName: "Категорія",
      width: 250,
      valueGetter: (record) =>
        ConfigService.getNamebyId(record.row.categoryid ?? "", ConfigResources.InternalEvaluation),
    },
    {
      field: "accountid",
      headerName: "Email",
      width: 300,
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {pageIsLoading ? (
          <div>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
        ) : (
          <div>
            {openNewForm && (
              <NewUserForm onSubmit={handleAddNewIdentity} onCancel={() => setOpenNewForm(false)} />
            )}
            {openEditForm && (
              <EditUserForm
                context={editContext}
                onSubmit={handleUpdateIdentity}
                onCancel={() => setOpenEditForm(false)}
              />
            )}
            {!openNewForm && !openEditForm && (
              <CustomCard
                title=""
                content={
                  <Grid container>
                    <Grid item xs={6}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        onClick={() => {
                          setOpenNewForm(true);
                        }}
                      >
                        <AddCircleOutline /> Добавити Нового Спортсмена
                      </MDButton>
                    </Grid>
                    <Grid item xs={12} style={{ paddingTop: 10 }}>
                      <div
                        style={{
                          height: window.innerHeight < 850 ? 600 : window.innerHeight - 300,
                          width: "100%",
                        }}
                      >
                        <DataGrid rows={dataset} columns={columns} />
                      </div>
                    </Grid>
                  </Grid>
                }
              />
            )}
          </div>
        )}
      </MDBox>
    </DashboardLayout>
  );
}
