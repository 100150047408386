import MDBox from "components/MDBox";
import PropTypes from "prop-types";
import { Divider, Grid } from "@mui/material";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import MDInput from "components/MDInput";
import CustomCard from "components/CustomCard";

function EditGeneralSectionItem({ context, onSubmit, onCancel }) {
  const [title, setTitle] = useState("");
  const [titleEn, setTitleEn] = useState("");

  const [subtitle, setSubtitle] = useState("");
  const [subtitleEn, setSubtitleEn] = useState("");

  const [description, setDescription] = useState("");
  const [descriptionEn, setDescriptionEn] = useState("");

  const [iconName, setIconName] = useState("");

  useEffect(() => {
    setTitle(context?.title ?? "");
    setTitleEn(context?.title_en ?? "");
    setSubtitle(context?.category ?? "");
    setSubtitleEn(context?.category_en ?? "");
    setDescription(context?.description ?? "");
    setDescriptionEn(context?.description_en ?? "");
    setIconName(context?.icon_name ?? "");
  }, []);

  return (
    <MDBox py={3} style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 10, paddingRight: 10 }}>
      <MDBox style={{ marginBottom: 10 }}>
        <CustomCard
          title="Редагування Запису"
          content={
            <Grid container>
              <Grid item xs={1} style={{ padding: 5 }}>
                <span className="material-icons">{iconName}</span>
              </Grid>
              <Grid item xs={10} style={{ padding: 5 }}>
                <MDInput
                  label="Назва іконки"
                  id="iconname"
                  value={iconName}
                  onChange={(event) => setIconName(event.target.value)}
                />
                <MDButton
                  style={{ marginLeft: 10 }}
                  variant="gradient"
                  color="info"
                  href="https://fonts.google.com/icons?selected=Material+Icons"
                  target="_blank"
                >
                  Зберегти
                </MDButton>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                <MDInput
                  label="Назва"
                  id="title"
                  fullWidth
                  value={title}
                  onChange={(event) => setTitle(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                <MDInput
                  label="Назва (EN)"
                  id="title_en"
                  fullWidth
                  value={titleEn}
                  onChange={(event) => setTitleEn(event.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                <MDInput
                  label="Категорія"
                  id="category"
                  fullWidth
                  multiline
                  rows={2}
                  value={subtitle}
                  onChange={(event) => setSubtitle(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                <MDInput
                  label="Категорія (EN)"
                  id="subtitle_en"
                  fullWidth
                  multiline
                  rows={2}
                  value={subtitleEn}
                  onChange={(event) => setSubtitleEn(event.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                <MDInput
                  label="Опис"
                  id="description"
                  fullWidth
                  multiline
                  rows={3}
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                <MDInput
                  label="Опис (EN)"
                  id="description_en"
                  fullWidth
                  multiline
                  rows={3}
                  value={descriptionEn}
                  onChange={(event) => setDescriptionEn(event.target.value)}
                />
              </Grid>
            </Grid>
          }
        />
      </MDBox>

      <Grid container alignItems="center">
        <Grid item xs={12} style={{ padding: 5 }}>
          <MDButton
            style={{ marginRight: 10 }}
            variant="gradient"
            color="info"
            onClick={() => {
              onSubmit({
                id: context?.id,
                title,
                title_en: titleEn,
                category: subtitle,
                category_en: subtitleEn,
                description,
                description_en: descriptionEn,
                icon_name: iconName,
              });
            }}
          >
            Зберегти
          </MDButton>
          <MDButton variant="gradient" color="dark" onClick={onCancel}>
            Відміна
          </MDButton>
        </Grid>
      </Grid>
    </MDBox>
  );
}

EditGeneralSectionItem.defaultProps = {
  context: null,
};

EditGeneralSectionItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  context: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EditGeneralSectionItem;
