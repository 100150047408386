/* eslint-disable react/destructuring-assignment */
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { useLocation } from "react-router-dom";
import { ShowAlert, AlertTypes } from "components/Alerts";
import { useEffect, useState } from "react";
import { isEmpty, getDepartment } from "helpers/strhelper";
import ConfigService from "api/ConfigService";
import CustomCard from "components/CustomCard";
import { Grid, Skeleton } from "@mui/material";
import MDButton from "components/MDButton";
import { AddAPhoto, Close, YouTube } from "@mui/icons-material";
import ReactPlayer from "react-player";
import MDInput from "components/MDInput";
import resizeFile from "helpers/ImageResizer";
import MediaService from "api/MediaService";

export default function SupportUsPageSettings() {
  const { search } = useLocation();
  const route = useLocation();

  const [pageName, setPageName] = useState("");
  const [pageSettings, setPageSettings] = useState();
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [, setRequestInProgress] = useState(false);
  const [currentDepartment, setCurrentDepartment] = useState("");

  const [imagePrevUrl, setImagePrevUrl] = useState("");
  const [pageBgImage, setPageBgImage] = useState();

  const [pageBgVideo, setPageBgVideo] = useState();
  const [imageChanged, setImageChanged] = useState(false);
  const [videoChanged, setVideoChanged] = useState(false);

  const handleChangeCoverImage = (e) => {
    const imgFile = {
      file: e.target.files[0],
      url: URL.createObjectURL(e.target.files[0]),
    };

    setImageChanged(true);
    setPageBgImage(imgFile.file);
    setImagePrevUrl(imgFile.url);
  };

  const handleVideoChanged = (e) => {
    setPageSettings({ ...pageSettings, bg_video_url: URL.createObjectURL(e.target.files[0]) });
    setPageBgVideo(e.target.files[0]);
    setVideoChanged(true);
  };

  const handleSaveChanges = async () => {
    try {
      setRequestInProgress(true);
      const recordToUpdate = { ...pageSettings };
      const filepath = `pages/${pageName}`;

      if (imageChanged) {
        // upload new image to S3
        const imagetoSave = await resizeFile(pageBgImage);
        const filename = `${currentDepartment}_cover_img.${pageBgImage.name.split(".")[1]}`;
        const response = await MediaService.uploadMediaToS3(imagetoSave, filepath, filename);
        // response.location - file path
        recordToUpdate.bg_img_url = response.Location;
      }

      if (videoChanged) {
        const videofilename = `${currentDepartment}_cover_video.${pageBgVideo.name.split(".")[1]}`;
        const videoResponse = await MediaService.uploadMediaToS3(
          pageBgVideo,
          filepath,
          videofilename
        );
        // response.location - file path
        recordToUpdate.bg_video_url = videoResponse.Location;
      }

      await ConfigService.updatePageSettings(recordToUpdate);
      setPageBgVideo(null);
      setVideoChanged(false);
      setImageChanged(false);
      ShowAlert("Зміни Збережено", AlertTypes.Success, true);
    } catch (error) {
      const msg = "Невдалося отримати данні з сервера для сторінки";
      ShowAlert(msg, AlertTypes.Error);
    } finally {
      setRequestInProgress(false);
    }
  };

  const fetchData = async () => {
    try {
      const linkdep = getDepartment(search);
      setCurrentDepartment(linkdep);
      const currentPageName = `${route.pathname.replace("/settings/pages/", "")}page`;
      setPageName(currentPageName);
      if (!isEmpty(linkdep)) {
        const response = await ConfigService.getPageSettings(currentPageName, linkdep);
        setPageSettings(response);
        setImagePrevUrl(response.bg_img_url);
      }

      setImageChanged(false);
      setVideoChanged(false);
      setPageBgImage(null);
      setPageBgVideo(null);
    } catch (error) {
      const msg = "Невдалося отримати данні з сервера для сторінки";
      ShowAlert(msg, AlertTypes.Error);
    } finally {
      setPageIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(search);
  }, [search]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {isEmpty(currentDepartment) && (
          <div>
            <br />
            <h2>Виберіть секцію: ковзани або ролики</h2>
          </div>
        )}
        {!isEmpty(currentDepartment) && pageIsLoading && (
          <div>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
        )}
        {!isEmpty(currentDepartment) && !pageIsLoading && (
          <MDBox>
            <CustomCard
              title=""
              content={
                <div>
                  <div
                    style={{
                      backgroundImage: `url(${imagePrevUrl})`,
                      backgroundSize: "cover",
                    }}
                  >
                    <div
                      style={{
                        color: "#FFF",
                        paddingTop: 100,
                        paddingBottom: 100,
                        paddingLeft: 25,
                        paddingRight: 25,
                        background: "rgba(0, 0, 0, 0.5)",
                      }}
                    >
                      <Grid
                        container
                        justifyContent="flex-end"
                        style={{ top: -80, position: "relative" }}
                      >
                        <Grid item xs={12}>
                          <input
                            style={{ display: "none" }}
                            accept="image/*"
                            id="bg-img-files"
                            multiple
                            type="file"
                            onChange={(e) => {
                              handleChangeCoverImage(e);
                            }}
                          />
                          <label htmlFor="bg-img-files">
                            <MDButton variant="contained" color="primary" component="span">
                              <AddAPhoto />
                            </MDButton>
                          </label>
                          <input
                            accept="video/*"
                            id="video-files"
                            type="file"
                            style={{ display: "none" }}
                            onChange={(e) => handleVideoChanged(e)}
                          />{" "}
                          <label htmlFor="video-files">
                            <MDButton variant="contained" color="warning" component="span">
                              <YouTube fontSize="medium" />
                            </MDButton>
                          </label>
                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: 20 }}>
                          <ReactPlayer
                            url={pageSettings?.bg_video_url}
                            width="300px"
                            height={isEmpty(pageSettings?.bg_video_url) ? "1px" : "169px"}
                            controls
                            config={{
                              file: {
                                attributes: {
                                  controlsList: "nodownload",
                                },
                              },
                            }}
                          />
                          {isEmpty(pageSettings?.bg_video_url) ? null : (
                            <MDButton
                              color="error"
                              iconOnly
                              circular
                              style={{ top: -169, position: "relative" }}
                              onClick={() => {
                                setPageSettings({ ...pageSettings, bg_video_url: "" });
                                setPageBgVideo(null);
                              }}
                            >
                              <Close />
                            </MDButton>
                          )}
                        </Grid>
                      </Grid>
                      {!isEmpty(pageSettings?.title) && (
                        <h2>
                          {pageSettings?.title.split("\n").map((x) => (
                            <p key={x}>{x}</p>
                          ))}
                        </h2>
                      )}
                      {!isEmpty(pageSettings?.description) && (
                        <h4>
                          {pageSettings?.description.split("\n").map((x) => (
                            <p key={x}>{x}</p>
                          ))}
                        </h4>
                      )}
                    </div>
                  </div>
                  <Grid container style={{ paddingTop: 15 }}>
                    <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                      <MDInput
                        label="Заголовок"
                        id="title"
                        fullWidth
                        multiline
                        rows={3}
                        value={pageSettings?.title ?? ""}
                        onChange={(event) =>
                          setPageSettings({ ...pageSettings, title: event.target.value })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                      <MDInput
                        label="Заголовок (EN)"
                        id="title_en"
                        fullWidth
                        multiline
                        rows={3}
                        value={pageSettings?.title_en ?? ""}
                        onChange={(event) =>
                          setPageSettings({ ...pageSettings, title_en: event.target.value })
                        }
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                      <MDInput
                        label="Короткий Опис"
                        id="description"
                        fullWidth
                        multiline
                        rows={5}
                        value={pageSettings?.description ?? ""}
                        onChange={(event) =>
                          setPageSettings({ ...pageSettings, description: event.target.value })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                      <MDInput
                        label="Короткий Опис (EN)"
                        id="description_en"
                        fullWidth
                        multiline
                        rows={5}
                        value={pageSettings?.description_en ?? ""}
                        onChange={(event) =>
                          setPageSettings({ ...pageSettings, description_en: event.target.value })
                        }
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                      <MDInput
                        label="Заголовок Основного Тексту"
                        id="text_title"
                        fullWidth
                        multiline
                        rows={5}
                        value={pageSettings?.texttitle ?? ""}
                        onChange={(event) =>
                          setPageSettings({ ...pageSettings, texttitle: event.target.value })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                      <MDInput
                        label="Заголовок Основного Тексту (EN)"
                        id="text_title_en"
                        fullWidth
                        multiline
                        rows={5}
                        value={pageSettings?.texttitle_en ?? ""}
                        onChange={(event) =>
                          setPageSettings({ ...pageSettings, texttitle_en: event.target.value })
                        }
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                      <MDInput
                        label="Основний Текст"
                        id="text_description"
                        fullWidth
                        multiline
                        rows={10}
                        value={pageSettings?.textdescription ?? ""}
                        onChange={(event) =>
                          setPageSettings({ ...pageSettings, textdescription: event.target.value })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                      <MDInput
                        label="Основний Текст (EN)"
                        id="text_description_en"
                        fullWidth
                        multiline
                        rows={10}
                        value={pageSettings?.textdescription_en ?? ""}
                        onChange={(event) =>
                          setPageSettings({
                            ...pageSettings,
                            textdescription_en: event.target.value,
                          })
                        }
                      />
                    </Grid>

                    <Grid item xs={12} style={{ padding: 5 }}>
                      <MDInput
                        label="Контактний Телефон"
                        id="phone"
                        fullWidth
                        multiline
                        rows={3}
                        value={pageSettings?.phone ?? ""}
                        onChange={(event) =>
                          setPageSettings({ ...pageSettings, phone: event.target.value })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} style={{ padding: 5 }}>
                      <MDInput
                        label="Email"
                        id="email"
                        fullWidth
                        multiline
                        rows={3}
                        value={pageSettings?.email ?? ""}
                        onChange={(event) =>
                          setPageSettings({
                            ...pageSettings,
                            email: event.target.value,
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </div>
              }
            />

            <Grid container alignItems="center" style={{ paddingTop: 15 }}>
              <Grid item xs={12} style={{ padding: 5 }}>
                <MDButton
                  style={{ marginRight: 10 }}
                  variant="gradient"
                  color="info"
                  onClick={handleSaveChanges}
                >
                  Зберегти
                </MDButton>
                <MDButton
                  variant="gradient"
                  color="dark"
                  onClick={() => {
                    fetchData();
                    ShowAlert("Зміни Відмінено", AlertTypes.Success);
                  }}
                >
                  Відміна
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
        )}
      </MDBox>
    </DashboardLayout>
  );
}
