import { useState } from "react";
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

export default function Wizard({ context, stepsLabels, steps, onSubmit, onCancel }) {
  const [activeStep, setActiveStep] = useState(0);

  const isLastStep = activeStep === steps.length;

  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  function getStepContent(stepIndex) {
    if (stepIndex === 0) {
      return <div />;
    }
    if (stepIndex <= steps.length) {
      return steps[stepIndex - 1];
    }
    return null;
  }

  return (
    <MDBox>
      <Card>
        <MDBox mt={-3} mx={2}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {stepsLabels.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </MDBox>
        <MDBox p={2}>
          <MDBox>
            {getStepContent(activeStep)}
            <MDBox mt={3} width="100%" display="flex" justifyContent="space-between">
              {activeStep === 0 ? (
                <MDButton
                  variant="outlined"
                  color="dark"
                  onClick={() => {
                    onCancel();
                  }}
                >
                  Відміна
                </MDButton>
              ) : (
                <MDButton variant="outlined" color="dark" onClick={handleBack}>
                  Назад
                </MDButton>
              )}
              <MDButton
                variant="gradient"
                color="dark"
                onClick={!isLastStep ? handleNext : () => onSubmit(context)}
              >
                {isLastStep ? "Зберегти" : "Далі"}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </MDBox>
  );
}

Wizard.defaultProps = {};

Wizard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  context: PropTypes.object.isRequired,
  stepsLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  steps: PropTypes.arrayOf(PropTypes.node).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
