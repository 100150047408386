/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React base styles

function Footer() {
  return (
    <MDBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
    >
      <MDTypography variant="body2" style={{ paddingTop: 15, paddingLeft: 15 }}>
        Build: 3.1.0
      </MDTypography>
      <MDTypography variant="body2">
        &copy; {1900 + new Date().getYear()}{" "}
        <a href="#blank" target="_blank">
          Serhiy Sadurskyy
        </a>
      </MDTypography>
    </MDBox>
  );
}

export default Footer;
