/* eslint-disable */


import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { ShowAlert, AlertTypes, ShowConfirmationAlert } from "components/Alerts";

import Grid from "@mui/material/Grid";
import { Skeleton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { AddCircleOutline, DeleteForever, Edit } from "@mui/icons-material";

import { useEffect, useState } from "react";
import AccountService from "api/AccountService";
import { isEmpty } from "helpers/strhelper";
import CustomCard from "components/CustomCard";
import NewAccountForm from "./Forms/NewAccountForm";
import EditAccountForm from "./Forms/EditAccountForm";
import UsersService from "api/UsersService";

export default function AccountsPage() {
  const [dataset, setDataset] = useState([]);
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [, setRequestInProgress] = useState(false);

  const [openNewForm, setOpenNewForm] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [editContext, setEditContext] = useState(null);

  const fetchData = async () => {
    try {
      const response = await AccountService.getIdentityAccounts();
      const ds = (response?.Users ?? []).map((prop, key) => ({
        id: key,
        sub: prop.Attributes.find((x) => x.Name === "sub")?.Value ?? "",
        first_name: prop.Attributes.find((x) => x.Name === "name")?.Value ?? "Відсутнє",
        family_name: prop.Attributes.find((x) => x.Name === "family_name")?.Value ?? "Відсутнє",
        email: prop.Attributes.find((x) => x.Name === "email")?.Value ?? "",
        phone: prop.Attributes.find((x) => x.Name === "phone_number")?.Value ?? "",
      }));

      setDataset(ds);
    } catch (error) {
      const msg = "Невдалося отримати данні з сервера для сторінки";
      ShowAlert(msg, AlertTypes.Error);
    } finally {
      setPageIsLoading(false);
    }
  };

  const handleAddNewIdentity = async (e) => {
    try {
      setRequestInProgress(true);
      const requestProps = {
        email: e.email,
        name: e.name,
        family_name: e.familyName,
        address: e.address,
        zoneinfo: e.zoneInfo,
        temp_pass: e.tempPass,
      };
      await AccountService.addIdentityAccount(requestProps);
      await AccountService.setPassword(e.email, e.tempPass);

    
      await UsersService.addProfile(e?.email ?? "", {
        firstname: e.userName,
        familyname: e.familyName,
        groupid: e.userGroup?.id,
        categoryid: e.userCategory,
        birthdate: (e.userBirthdate ?? new Date()).toISOString(),
      });

      setOpenNewForm(false);
      fetchData();
      ShowAlert("Користувача Додано", AlertTypes.Success, true);
    } catch (error) {
      let msg = "Виникла помилка при обробці запиту. ";
      if (error.response?.data === "An account with the given email already exists.") {
        msg = "Такий акаунт вже зареєстрований";
      } else if (
        error.response?.data === "Password does not conform to policy: Password not long enough"
      ) {
        msg = "Пароль не відповідає політиці безпеки: пароль недостатньо довгий";
      }
      ShowAlert(msg, AlertTypes.Error);
      console.log(error);
    } finally {
      setRequestInProgress(false);
    }
  };

  


  const handleDeleteIdentity = async (prop) => {
    try {
      setRequestInProgress(true);
      await AccountService.deleteIdentityAccount(prop);
      fetchData();
      ShowAlert("Користувача Видалено", AlertTypes.Success, true);
    } catch (error) {
      const msg = "Виникла помилка при обробці запиту";
      ShowAlert(msg, AlertTypes.Error);
    } finally {
      setRequestInProgress(false);
    }
  };

  const handleUpdateIdentity = async (e) => {
    try {
      setRequestInProgress(true);
      const attributesToUpdate = [
        { Name: "name", Value: e.name ?? "" },
        { Name: "family_name", Value: e.familyName ?? "" },
        { Name: "address", Value: e.address ?? "" },
        { Name: "zoneinfo", Value: e.zoneInfo ?? "" },
      ];

      if (!isEmpty(e.phone)) {
        if (e.phone[0] !== "+") {
          attributesToUpdate.push({ Name: "phone_number", Value: `+${e.phone}` });
        } else {
          attributesToUpdate.push({ Name: "phone_number", Value: e.phone });
        }
      }

      await AccountService.updateAttributes(e.email, attributesToUpdate);

      if (!isEmpty(e.newPass)) {
        try {
          await AccountService.setPassword(e.email, e.newPass);
        } catch (error) {
          const msg = `Не вдалося змінити пароль: ${error.message}`;
          ShowAlert(msg, AlertTypes.Error);
          return;
        }
      }

      setOpenEditForm(false);
      ShowAlert("Зміни Збережено", AlertTypes.Success, true);
    } catch (error) {
      const msg = "Виникла помилка при обробці запиту: ";
      ShowAlert(msg, AlertTypes.Error);
    } finally {
      setRequestInProgress(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      field: "actions",
      headerName: "Дії",
      width: 100,
      renderCell: (params) => (
        <div>
          <MDButton
            circular
            iconOnly
            color="info"
            variant="gradient"
            style={{ margin: 5 }}
            onClick={() => {
              AccountService.listUserGroups(params.row.email).then((response) => {
                setEditContext({
                  ...params.row,
                  accountGroups: (response.Groups ?? []).map((x) => x.GroupName),
                });
                setOpenEditForm(true);
              });
            }}
          >
            <Edit />
          </MDButton>
          <MDButton
            circular
            iconOnly
            color="dark"
            variant="gradient"
            onClick={() => {
              ShowConfirmationAlert(
                "Видалити запис?",
                "Так, видалити!",
                handleDeleteIdentity,
                params.row.email
              );
            }}
          >
            <DeleteForever />
          </MDButton>
        </div>
      ),
    },
    {
      field: "first_name",
      headerName: "Ім'я",
      width: 150,
    },
    {
      field: "family_name",
      headerName: "Прізвище",
      width: 150,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 300,
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Телефон",
      width: 250,
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {pageIsLoading ? (
          <div>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
        ) : (
          <div>
            {openNewForm && (
              <NewAccountForm
                onSubmit={handleAddNewIdentity}
                onCancel={() => setOpenNewForm(false)}
              />
            )}
            {openEditForm && (
              <EditAccountForm
                context={editContext}
                onSubmit={handleUpdateIdentity}
                onCancel={() => setOpenEditForm(false)}
              />
            )}
            {!openNewForm && !openEditForm && (
              <CustomCard
                title=""
                content={
                  <Grid container>
                    <Grid item xs={6}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        onClick={() => {
                          setOpenNewForm(true);
                        }}
                      >
                        <AddCircleOutline /> Добавити Нового Користувача
                      </MDButton>
                    </Grid>
                    <Grid item xs={12} style={{ paddingTop: 10 }}>
                      <div
                        style={{
                          height: window.innerHeight < 850 ? 600 : window.innerHeight - 300,
                          width: "100%",
                        }}
                      >
                        <DataGrid rows={dataset} columns={columns} />
                      </div>
                    </Grid>
                  </Grid>
                }
              />
            )}
          </div>
        )}
      </MDBox>
    </DashboardLayout>
  );
}
