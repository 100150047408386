import PropTypes from "prop-types";

import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import SimpleVideoCard from "components/Cards/BlogCards/SimpleVideoCard";

function PreviewVideoStep({ context }) {
  return (
    <MDBox>
      <Grid container alignItems="center">
        <Grid item xs={10} md={6} style={{ padding: 25, textAlign: "center" }}>
          <SimpleVideoCard
            url={context.url}
            title={context.title}
            category={context.record_category}
            description={context.description}
          />
        </Grid>
      </Grid>
    </MDBox>
  );
}

PreviewVideoStep.defaultProps = {};

// Typechecking props for the SimpleBlogCard
PreviewVideoStep.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  context: PropTypes.object.isRequired,
};

export default PreviewVideoStep;
