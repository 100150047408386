/* eslint-disable react/jsx-key */
/* eslint-disable react/display-name */
import React, { useState } from "react";

import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { ShowAlert, AlertTypes, ShowConfirmationAlert } from "components/Alerts";
import Grid from "@mui/material/Grid";
import { DataGrid } from "@mui/x-data-grid";

import format from "date-fns/format";
import ukLocale from "date-fns/locale/uk";
import MediaService from "api/MediaService";
import { DeleteForever, AddCircleOutline, Edit } from "@mui/icons-material";
import { isEmpty } from "helpers/strhelper";
import { Skeleton } from "@mui/material";
import CustomCard from "components/CustomCard";
import resizeFile from "helpers/ImageResizer";
import Wizard from "../../components/Wizard";
import VideoStep from "./Forms/WizardSteps/VideoStep";
import PreviewVideoStep from "./Forms/WizardSteps/PreviewVideoStep";
import VideoGeneralStep from "./Forms/WizardSteps/VideoGeneralStep";

export default function VideosPage() {
  const [pageIsLoading, setPageIsLoading] = useState(true);

  const [pageDataset, setPageDataset] = useState([]);
  const [editRow, setEditRow] = useState(null);
  const [openEditForm, setOpenEditForm] = useState(false);

  const [wizardContext, setWizardContext] = React.useState({});

  const updateContext = (e) => {
    setWizardContext({ ...wizardContext, ...e });
  };

  const fetchData = async () => {
    try {
      const response = await MediaService.listMedia();
      const sorted = (response.data ?? [])
        .filter((x) => x.record_type === "video")
        .sort((a, b) => (a.submittedAt > b.submittedAt ? -1 : 1));
      setPageDataset(sorted);
    } catch (error) {
      const msg = "Невдалося отримати данні з сервера для сторінки";
      ShowAlert(msg, AlertTypes.Error);
    } finally {
      setPageIsLoading(false);
    }
  };

  const handleDeleteRecord = async (id) => {
    try {
      await MediaService.deleteMedia(id);
      ShowAlert("Запис Видалено", AlertTypes.Success, true);
      setEditRow("");
      fetchData();
    } catch (error) {
      const msg = "Виникла помилка при обробці запиту";
      ShowAlert(msg, AlertTypes.Error);
    }
  };

  const submitForm = async () => {
    try {
      // setProgressState("Створення запису в базі данних");
      let videoCoverImgResponse = {};

      if (wizardContext.coverImageChanged) {
        //   setProgressState("Завантаження обкладинки");
        const videoPath = `videos/${wizardContext.title.replaceAll(" ", "_")}`;
        const filename = wizardContext.newCoverImage.file.name;
        const imagetoSave = await resizeFile(wizardContext.newCoverImage.file, 1920, 1080);
        videoCoverImgResponse = await MediaService.uploadMediaToS3(
          imagetoSave,
          videoPath,
          filename
        );

        if (!videoCoverImgResponse) {
          // error
          ShowAlert("Не вдалося завантажити обкладинку", AlertTypes.Error);
          return;
        }
      }

      if (!isEmpty(wizardContext.id)) {
        await MediaService.updateMedia({
          id: wizardContext.id,
          record_type: "video",
          title: wizardContext.title,
          description: wizardContext.description,
          record_category: wizardContext.record_category,
          url: wizardContext.url,
          cover_image: wizardContext.coverImageChanged
            ? videoCoverImgResponse.Location
            : wizardContext.cover_image,
        });
      } else {
        await MediaService.addMedia({
          type: "video",
          title: wizardContext.title,
          description: wizardContext.description,
          category: wizardContext.record_category,
          url: wizardContext.url,
          cover_image: videoCoverImgResponse?.Location ?? "",
        });
        // setProgressState("");
      }

      // close form

      fetchData();
      ShowAlert("Запис оновлено", AlertTypes.Success, true);
      setOpenEditForm(false);
    } catch (error) {
      ShowAlert(error.message, AlertTypes.Error);
    }
  };

  const renderEditForm = () => (
    <div>
      <Wizard
        context={editRow}
        stepsLabels={["Нова Стаття", "Загальна ІНформація", "Відео", "Перегляд"]}
        steps={[
          <VideoGeneralStep onChange={updateContext} context={wizardContext} />,
          <VideoStep onChange={updateContext} context={wizardContext} />,
          <PreviewVideoStep context={wizardContext} />,
        ]}
        onSubmit={submitForm}
        onCancel={() => {
          setOpenEditForm(false);
        }}
      />
    </div>
  );

  React.useEffect(() => {
    fetchData();
  }, []);

  const tableColumns = [
    {
      field: "title",
      headerName: "Заголовок",
      flex: 1,
    },
    {
      field: "record_category",
      headerName: "Категорія",
      width: 170,
    },
    {
      field: "submittedAt",
      headerName: "Опубліковано",
      width: 220,
      valueGetter: (params) =>
        format(new Date(params.row.submittedAt), "dd MMMM yyyy HH:mm", { locale: ukLocale }),
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      renderCell: (params) => (
        <div key="actions">
          <MDButton
            circular
            iconOnly
            color="info"
            variant="gradient"
            style={{ margin: 5 }}
            // eslint-disable-next-line react/destructuring-assignment
            key={`edit_${params.row.id}`}
            onClick={() => {
              setEditRow(params.row);
              setWizardContext({ ...params.row });
              setOpenEditForm(true);
            }}
          >
            <Edit />
          </MDButton>
          <MDButton
            circular
            iconOnly
            color="dark"
            variant="gradient"
            onClick={() => {
              ShowConfirmationAlert(
                "Видалити запис?",
                "Так, видалити!",
                handleDeleteRecord,
                params.row.id
              );
            }}
          >
            <DeleteForever />
          </MDButton>
        </div>
      ),
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {pageIsLoading && (
          <div>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
        )}
        {!pageIsLoading && openEditForm && renderEditForm()}
        {!pageIsLoading && !openEditForm && (
          <MDBox py={3}>
            <CustomCard
              title=""
              content={
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <MDButton
                      color="info"
                      variant="gradient"
                      onClick={() => {
                        setWizardContext({});
                        setOpenEditForm(true);
                      }}
                    >
                      <AddCircleOutline /> Добавити Відео
                    </MDButton>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        height: window.innerHeight < 850 ? 600 : window.innerHeight - 300,
                        width: "100%",
                      }}
                    >
                      <DataGrid rows={pageDataset} columns={tableColumns} />
                    </div>
                  </Grid>
                </Grid>
              }
            />
          </MDBox>
        )}
      </MDBox>
    </DashboardLayout>
  );
}
