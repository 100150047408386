/* eslint-disable react/destructuring-assignment */
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { ShowAlert, AlertTypes, ShowConfirmationAlert } from "components/Alerts";

import Grid from "@mui/material/Grid";
import { Skeleton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import exportToCSV from "helpers/Exporter";
import { Check, DeleteForever, GetApp, NewReleases } from "@mui/icons-material";

import format from "date-fns/format";
import ukLocale from "date-fns/locale/uk";
import NotificationService from "api/NotificationService";
import { useEffect, useState } from "react";
import CustomCard from "components/CustomCard";

const exportFileName = "Заявки На Пробне Заняття";

export default function RequestFormsPage() {
  const [dataset, setDataset] = useState([]);
  const [pageIsLoading, setPageIsLoading] = useState(true);

  const formatExportJson = (data) =>
    data.map((record) => ({
      id: record.id,
      "Дата Заявки": format(new Date(record.submitdate ?? ""), "dd MMMM yyyy, HH:mm", {
        locale: ukLocale,
      }),
      Статус: record.record_status,
      "Ім'я та Прізвище": record.name,
      "Контактний Телефон": record.phone,
      "Контактний Email": record.email,
      Напрямок: record.dep,
      "Дата Народження": format(new Date(record.birthdate ?? ""), "dd MMMM yyyy", {
        locale: ukLocale,
      }),
    }));

  const fetchData = async () => {
    try {
      // const linkdep = new URLSearchParams(search).get('dep');
      // setCurrentDepartment(linkdep ?? '');
      // if (!isEmpty(linkdep)) {
      const response = await NotificationService.getRequestForms("iceskating");
      const ds = (response ?? []).sort((a, b) => (a.submitdate > b.submitdate ? -1 : 1));
      setDataset(ds);
      // }
    } catch (error) {
      const msg = "Невдалося отримати данні з сервера для сторінки";
      ShowAlert(msg, AlertTypes.Error);
    } finally {
      setPageIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleMarkAsRead = async (recordId) => {
    try {
      await NotificationService.updateRequestForm(recordId, { record_status: "readed" });
      ShowAlert("Записи Позначено як прочитаний", AlertTypes.Success, true);
      fetchData();
    } catch (error) {
      const msg = "Невдалося оновити запис";
      ShowAlert(msg, AlertTypes.Error);
    }
  };

  const handleDeleteRecord = async (recordId) => {
    try {
      await NotificationService.deleteRequestForm(recordId);
      ShowAlert("Записи Видалено", AlertTypes.Success, true);
      fetchData();
    } catch (error) {
      const msg = "Невдалося видалити запис";
      ShowAlert(msg, AlertTypes.Error);
    }
  };

  const columns = [
    {
      field: "record_status",
      headerName: "Статус",
      width: 85,
      renderCell: (param) => (
        <div style={{ paddingTop: 0 }}>
          {param.row.record_status === "NEW" ? (
            <MDButton
              circular
              iconOnly
              color="info"
              variant="gradient"
              // eslint-disable-next-line react/destructuring-assignment
              key={`edit_${param?.row?.id}`}
              onClick={() => {
                ShowConfirmationAlert(
                  "Позначити як прочитане?",
                  "Так!",
                  handleMarkAsRead,
                  param.row.id
                );
              }}
            >
              <NewReleases />
            </MDButton>
          ) : (
            <Check color="action" fontSize="large" style={{ marginTop: 20 }} />
          )}
        </div>
      ),
    },
    {
      field: "name",
      headerName: "Ім'я та Прізвище",
      width: 320,
    },
    {
      field: "birthdate",
      headerName: "Дата Народження",
      width: 200,
      valueGetter: (record) => {
        try {
          return format(new Date(record.row.birthdate ?? ""), "dd MMMM yyyy", { locale: ukLocale });
        } catch (error) {
          return record.row.birthdate;
        }
      },
    },
    {
      field: "phone",
      headerName: "Контактний Телефон",
      width: 200,
    },
    {
      field: "email",
      headerName: "Контактний Email",
      width: 250,
    },
    {
      field: "dep",
      headerName: "Напрямок",
      width: 150,
    },
    {
      field: "submitdate",
      headerName: "Дата Заявки",
      width: 200,
      valueGetter: (record) =>
        format(new Date(record.row.submitdate), "dd MMMM yyyy, HH:mm", { locale: ukLocale }),
    },
    {
      field: "actions",
      headerName: "Дії",
      width: 100,
      renderCell: (params) => (
        <div>
          <MDButton
            circular
            iconOnly
            variant="gradient"
            color="dark"
            onClick={() => {
              ShowConfirmationAlert(
                "Видалити запис?",
                "Так, видалити!",
                handleDeleteRecord,
                params.row.id
              );
            }}
          >
            <DeleteForever />
          </MDButton>
        </div>
      ),
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {pageIsLoading ? (
          <div>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
        ) : (
          <CustomCard
            title=""
            content={
              <Grid container>
                <Grid item xs={6}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      exportToCSV(formatExportJson(dataset), exportFileName);
                    }}
                  >
                    <GetApp /> Експорт в ексель
                  </MDButton>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: 10 }}>
                  <div
                    style={{
                      height: window.innerHeight < 850 ? 600 : window.innerHeight - 300,
                      width: "100%",
                    }}
                  >
                    <DataGrid rows={dataset} columns={columns} />
                  </div>
                </Grid>
              </Grid>
            }
          />
        )}
      </MDBox>
    </DashboardLayout>
  );
}
