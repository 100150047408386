/* eslint-disable no-console */
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import CustomCard from "components/CustomCard";
import UsersService from "api/UsersService";
import { ShowAlert, AlertTypes } from "components/Alerts";
import React, { useState } from "react";
import format from "date-fns/format";
import ukLocale from "date-fns/locale/uk";
import { Divider, Skeleton } from "@mui/material";
import MDTypography from "components/MDTypography";

function Birthdays() {
  const [birthdays, setBirthdays] = useState([]);
  const [pageIsLoading, setPageIsLoading] = useState(true);

  const fetchData = async () => {
    try {
      const birthdaysResponse = await UsersService.loadBirthdays();

      setBirthdays(
        birthdaysResponse.data.sort((a, b) =>
          a.birthdate.substring(4, 10) > b.birthdate.substring(4, 10) ? 1 : -1
        )
      );
    } catch (error) {
      const msg = "Невдалося отримати данні з сервера для сторінки";
      console.error(msg, error);
      ShowAlert(msg, AlertTypes.Error);
    } finally {
      setPageIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {pageIsLoading ? (
          <div>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
        ) : (
          <Grid container>
            <Grid item xs={12} style={{ padding: 15 }}>
              <CustomCard
                title="Дні Народження цього та наступного місяця"
                content={
                  <div>
                    {birthdays.map((x) => (
                      <div key={x.id}>
                        <Grid container>
                          <Grid item xs={12} sm={6}>
                            <MDTypography variant="subtitle2">{`${x.firstname} ${x.familyname}`}</MDTypography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <MDTypography variant="subtitle2">
                              <b>
                                {format(new Date(x.birthdate), "dd MMMM yyyy", {
                                  locale: ukLocale,
                                })}
                              </b>
                            </MDTypography>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider style={{ marginTop: 3, marginBottom: 5 }} />
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                  </div>
                }
              />
            </Grid>
          </Grid>
        )}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Birthdays;
