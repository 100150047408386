/* eslint-disable react/destructuring-assignment */
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { useLocation } from "react-router-dom";
import { ShowAlert, AlertTypes } from "components/Alerts";
import { useEffect, useState } from "react";
import { isEmpty, getDepartment } from "helpers/strhelper";
import ConfigService from "api/ConfigService";
import CustomCard from "components/CustomCard";
import { Checkbox, FormControlLabel, FormGroup, Grid, Skeleton } from "@mui/material";
import MDButton from "components/MDButton";
import { AddAPhoto, Close, YouTube } from "@mui/icons-material";
import ReactPlayer from "react-player";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDSocialButton from "components/MDSocialButton";

// @mui icons-material components
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YoutubeIcon from "@mui/icons-material/YouTube";
import resizeFile from "helpers/ImageResizer";
import MediaService from "api/MediaService";

const currentPageName = "homepage";

export default function HomePageSettings() {
  const { search } = useLocation();

  const [pageSettings, setPageSettings] = useState();
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [, setRequestInProgress] = useState(false);
  const [currentDepartment, setCurrentDepartment] = useState("");

  const [imagePrevUrl, setImagePrevUrl] = useState("");
  const [pageBgImage, setPageBgImage] = useState();

  const [pageBgVideo, setPageBgVideo] = useState();
  const [imageChanged, setImageChanged] = useState(false);

  const handleChangeCoverImage = (e) => {
    const imgFile = {
      file: e.target.files[0],
      url: URL.createObjectURL(e.target.files[0]),
    };

    setImageChanged(true);
    setPageBgImage(imgFile.file);
    setImagePrevUrl(imgFile.url);
  };

  const handleVideoChanged = (e) => {
    setPageSettings({ ...pageSettings, bg_video_url: URL.createObjectURL(e.target.files[0]) });
    setPageBgVideo(e.target.files[0]);
  };

  const getIcon = (className) => {
    switch (className) {
      case "fab fa-facebook-square":
        return <FacebookIcon />;
      case "fab fa-instagram":
        return <InstagramIcon />;
      case "fab fa-youtube":
        return <YoutubeIcon />;
      case "fab fa-tiktok":
        return <span className="material-icons">tiktok</span>;
      case "fab fa-twitter":
        return <TwitterIcon />;
      default:
        return null;
    }
  };

  const handleChangeSections = (e, section) => {
    if (e) {
      const newSections = [...(pageSettings?.sections ?? []), { id: section }];
      setPageSettings({ ...pageSettings, sections: newSections });
    } else {
      const newSections = (pageSettings?.sections ?? []).filter((x) => x.id !== section);
      setPageSettings({ ...pageSettings, sections: newSections });
    }
  };

  const handleSocialMediaChange = (newValue, socialnnetworkName) => {
    const updatedSettings = { ...pageSettings };
    let editedRecord = updatedSettings.values.find((x) => x.className === socialnnetworkName);
    if (!editedRecord) {
      editedRecord = { className: socialnnetworkName, url: newValue };
      updatedSettings.values.push(editedRecord);
    } else {
      editedRecord.url = newValue;
    }
    setPageSettings(updatedSettings);
  };

  const handleSaveChanges = async () => {
    try {
      setRequestInProgress(true);

      const recordToUpdate = { ...pageSettings };
      const filepath = `pages/${currentPageName}`;

      if (imageChanged) {
        // upload new image to S3
        const imagetoSave = await resizeFile(pageBgImage);
        const filename = `${currentDepartment}_cover_img.${pageBgImage.name.split(".")[1]}`;
        const response = await MediaService.uploadMediaToS3(imagetoSave, filepath, filename);
        recordToUpdate.bg_img_url = response.Location;
      }

      if (pageBgVideo) {
        const videofilename = `${currentDepartment}_cover_video.${pageBgVideo.name.split(".")[1]}`;
        const videoResponse = await MediaService.uploadMediaToS3(
          pageBgVideo,
          filepath,
          videofilename
        );
        recordToUpdate.bg_video_url = videoResponse.Location;
      }

      await ConfigService.updatePageSettings(recordToUpdate);
      setPageBgVideo(null);
      ShowAlert("Зміни Збережено", AlertTypes.Success, true);
    } catch (error) {
      const msg = "Невдалося отримати данні з сервера для сторінки";
      ShowAlert(msg, AlertTypes.Error);
    } finally {
      setRequestInProgress(false);
    }
  };

  const fetchData = async () => {
    try {
      const linkdep = getDepartment(search);
      setCurrentDepartment(linkdep);

      if (!isEmpty(linkdep)) {
        const response = await ConfigService.getPageSettings(currentPageName, linkdep);

        setPageSettings(response);

        // setAvailablePageSections(availableSections);
        setImagePrevUrl(response.bg_img_url);
      }
      setImageChanged(false);
      setPageBgImage(null);
      setPageBgVideo(null);
    } catch (error) {
      const msg = "Невдалося отримати данні з сервера для сторінки";
      ShowAlert(msg, AlertTypes.Error);
    } finally {
      setPageIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(search);
  }, [search]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {isEmpty(currentDepartment) && (
          <div>
            <br />
            <h2>Виберіть секцію: ковзани або ролики</h2>
          </div>
        )}
        {!isEmpty(currentDepartment) && pageIsLoading && (
          <div>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
        )}
        {!isEmpty(currentDepartment) && !pageIsLoading && (
          <MDBox>
            <CustomCard
              title=""
              content={
                <div>
                  <div
                    style={{
                      backgroundImage: `url(${imagePrevUrl})`,
                      backgroundSize: "cover",
                    }}
                  >
                    <div
                      style={{
                        color: "#FFF",
                        paddingTop: 100,
                        paddingBottom: 100,
                        paddingLeft: 25,
                        paddingRight: 25,
                        background: "rgba(255, 255, 255, 0.1)",
                      }}
                    >
                      <Grid
                        container
                        justifyContent="flex-end"
                        style={{ top: -80, position: "relative" }}
                      >
                        <Grid item xs={12}>
                          <input
                            style={{ display: "none" }}
                            accept="image/*"
                            id="bg-img-files"
                            multiple
                            type="file"
                            onChange={(e) => {
                              handleChangeCoverImage(e);
                            }}
                          />
                          <label htmlFor="bg-img-files">
                            <MDButton variant="contained" color="primary" component="span">
                              <AddAPhoto />
                            </MDButton>
                          </label>
                          <input
                            accept="video/*"
                            id="video-files"
                            type="file"
                            style={{ display: "none" }}
                            onChange={(e) => handleVideoChanged(e)}
                          />{" "}
                          <label htmlFor="video-files">
                            <MDButton variant="contained" color="warning" component="span">
                              <YouTube fontSize="medium" />
                            </MDButton>
                          </label>
                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: 20 }}>
                          <ReactPlayer
                            url={pageSettings?.bg_video_url}
                            width="300px"
                            height="169px"
                            controls
                            config={{
                              file: {
                                attributes: {
                                  controlsList: "nodownload",
                                },
                              },
                            }}
                          />
                          {isEmpty(pageSettings?.bg_video_url) ? null : (
                            <MDButton
                              color="error"
                              iconOnly
                              circular
                              style={{ top: -169, position: "relative" }}
                              onClick={() => {
                                setPageSettings({ ...pageSettings, bg_video_url: "" });
                                setPageBgVideo(null);
                              }}
                            >
                              <Close />
                            </MDButton>
                          )}
                        </Grid>
                      </Grid>
                      {!isEmpty(pageSettings?.title) && (
                        <h2>
                          {pageSettings?.title.split("\n").map((x) => (
                            <p key={x}>{x}</p>
                          ))}
                        </h2>
                      )}
                      {!isEmpty(pageSettings?.description) && (
                        <MDTypography variant="h6" style={{ color: "white" }}>
                          {pageSettings?.description.split("\n").map((x) => (
                            <p key={x}>{x}</p>
                          ))}
                        </MDTypography>
                      )}
                      <div style={{ padding: 5 }}>
                        {(pageSettings?.values ?? []).map((s) =>
                          !isEmpty(s.url) ? (
                            <MDSocialButton
                              key={s.id}
                              iconOnly
                              circular
                              variant="gradient"
                              color="facebook"
                              href={s.url}
                              target="_blank"
                              style={{ margin: 5 }}
                            >
                              {getIcon(s.className)}
                            </MDSocialButton>
                          ) : null
                        )}
                        <br />
                        <MDButton color="warning" style={{ marginTop: 5 }}>
                          Записатись на пробне заняття
                        </MDButton>
                      </div>
                    </div>
                  </div>
                  <Grid container>
                    <Grid item xs={12} md={6} style={{ padding: 5, paddingTop: 15 }}>
                      <MDInput
                        label="Заголовок"
                        id="title"
                        fullWidth
                        multiline
                        rows={3}
                        value={pageSettings?.title ?? ""}
                        onChange={(event) =>
                          setPageSettings({ ...pageSettings, title: event.target.value })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: 5, paddingTop: 15 }}>
                      <MDInput
                        label="Заголовок (EN)"
                        id="title_en"
                        fullWidth
                        multiline
                        rows={3}
                        value={pageSettings?.title_en ?? ""}
                        onChange={(event) =>
                          setPageSettings({ ...pageSettings, title_en: event.target.value })
                        }
                      />
                    </Grid>

                    <Grid item xs={12} md={6} style={{ padding: 5, paddingTop: 15 }}>
                      <MDInput
                        label="Короткий Опис"
                        id="description"
                        fullWidth
                        multiline
                        rows={5}
                        value={pageSettings?.description ?? ""}
                        onChange={(event) =>
                          setPageSettings({ ...pageSettings, description: event.target.value })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: 5, paddingTop: 15 }}>
                      <MDInput
                        label="Короткий Опис (EN)"
                        id="description_en"
                        fullWidth
                        multiline
                        rows={5}
                        value={pageSettings?.description_en ?? ""}
                        onChange={(event) =>
                          setPageSettings({ ...pageSettings, description_en: event.target.value })
                        }
                      />
                    </Grid>

                    {/* Social Media */}
                    <Grid item xs={12} style={{ padding: 5, paddingTop: 15 }}>
                      <MDTypography>Соціальні Мережі</MDTypography>
                    </Grid>
                    <Grid item xs={12} style={{ padding: 5 }}>
                      <MDInput
                        label="Twitter"
                        id="twitter_url"
                        fullWidth
                        value={
                          (pageSettings?.values ?? []).find((x) => x.className === "fab fa-twitter")
                            ?.url
                        }
                        onChange={(e) => {
                          handleSocialMediaChange(e.target.value, "fab fa-twitter");
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ padding: 5 }}>
                      <MDInput
                        label="Facebook"
                        id="facebook_url"
                        fullWidth
                        value={
                          (pageSettings?.values ?? []).find(
                            (x) => x.className === "fab fa-facebook-square"
                          )?.url
                        }
                        onChange={(e) => {
                          handleSocialMediaChange(e.target.value, "fab fa-facebook-square");
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ padding: 5 }}>
                      <MDInput
                        label="Instagram"
                        id="instagram_url"
                        fullWidth
                        value={
                          (pageSettings?.values ?? []).find(
                            (x) => x.className === "fab fa-instagram"
                          )?.url
                        }
                        onChange={(e) => {
                          handleSocialMediaChange(e.target.value, "fab fa-instagram");
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ padding: 5 }}>
                      <MDInput
                        label="YouTube"
                        id="youtube_url"
                        fullWidth
                        value={
                          (pageSettings?.values ?? []).find((x) => x.className === "fab fa-youtube")
                            ?.url
                        }
                        onChange={(e) => {
                          handleSocialMediaChange(e.target.value, "fab fa-youtube");
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ padding: 5 }}>
                      <MDInput
                        label="TikTok"
                        id="tiktok_url"
                        fullWidth
                        value={
                          (pageSettings?.values ?? []).find((x) => x.className === "fab fa-tiktok")
                            ?.url
                        }
                        onChange={(e) => {
                          handleSocialMediaChange(e.target.value, "fab fa-tiktok");
                        }}
                      />
                    </Grid>

                    {/* Page Sections */}
                    <Grid item xs={12} style={{ padding: 5, paddingTop: 15 }}>
                      <MDTypography>Сторінки Секції</MDTypography>
                    </Grid>
                    <Grid item xs={12} style={{ padding: 5 }}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                (pageSettings?.sections ?? []).find(
                                  (x) => x.id === "mainvideosection"
                                ) != null
                              }
                              onChange={(e) =>
                                handleChangeSections(e.target.checked, "mainvideosection")
                              }
                            />
                          }
                          label="Головне Відео"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                (pageSettings?.sections ?? []).find(
                                  (x) => x.id === "groupssection"
                                ) != null
                              }
                              onChange={(e) =>
                                handleChangeSections(e.target.checked, "groupssection")
                              }
                            />
                          }
                          label="Наші Спортивні Групи"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                (pageSettings?.sections ?? []).find(
                                  (x) => x.id === "servicessection"
                                ) != null
                              }
                              onChange={(e) =>
                                handleChangeSections(e.target.checked, "servicessection")
                              }
                            />
                          }
                          label="Наші Додаткові Послуги"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                (pageSettings?.sections ?? []).find(
                                  (x) => x.id === "mobileappsection"
                                ) != null
                              }
                              onChange={(e) =>
                                handleChangeSections(e.target.checked, "mobileappsection")
                              }
                            />
                          }
                          label="Наші Мобільні Додатки"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                (pageSettings?.sections ?? []).find(
                                  (x) => x.id === "teamsection"
                                ) != null
                              }
                              onChange={(e) =>
                                handleChangeSections(e.target.checked, "teamsection")
                              }
                            />
                          }
                          label="Наша Команда"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </div>
              }
            />

            <Grid container alignItems="center" style={{ paddingTop: 15 }}>
              <Grid item xs={12} style={{ padding: 5 }}>
                <MDButton
                  style={{ marginRight: 10 }}
                  variant="gradient"
                  color="info"
                  onClick={handleSaveChanges}
                >
                  Зберегти
                </MDButton>
                <MDButton
                  variant="gradient"
                  color="dark"
                  onClick={() => {
                    fetchData();
                    ShowAlert("Зміни Відмінено", AlertTypes.Success, true);
                  }}
                >
                  Відміна
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
        )}
      </MDBox>
    </DashboardLayout>
  );
}
