/* eslint-disable */
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import CustomCard from "components/CustomCard";
import { Divider, Grid, Tooltip } from "@mui/material";
import { ScoringLevels, GetAgeRestrictionLabel } from "isu/ScoringRules";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import { GetJumpDescription } from "isu/Elements/JumpElements";
import { GetRotationDescription } from "isu/Elements/RotatingElements";
import { GetSequencesDescription } from "isu/Elements/SequenceElements";
import { SequencesLevels } from "isu/Elements/SequenceElements";

export default function TestingSettings() {




  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <CustomCard
          title="Критерії Оцінювання"
          content={
            <div>
              {ScoringLevels.map(isuLevel => <MDBox key={isuLevel.code} style={{paddingBottom: 50}}>
                <MDTypography variant="h6">{`Рівень ${isuLevel.level}: ${isuLevel.name}`}</MDTypography>
                <Divider style={{margin: 3 }}/>
                <MDTypography variant="caption">{`Обмеженя по віку: ${isuLevel.rule?.age ? GetAgeRestrictionLabel(isuLevel.rule?.age) : "Відсутні"}`}</MDTypography>
                <MDTypography variant="subtitle2">Набір необхідних елементів на категорію</MDTypography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <MDBox
                      bgColor="light"
                      variant="gradient"
                      borderRadius="lg"
                      shadow="lg"
                      opacity={1}
                      p={2}
                    >
                      <MDTypography variant="h6">Стрибки</MDTypography>
                      {isuLevel.rule?.jumps ? <>
                        {isuLevel.rule?.jumps.map(j => 
                        <Tooltip title={GetJumpDescription(j.code, j.number)} key={j.code} style={{marginLeft: 5}}>
                          <MDBadge badgeContent={j.number + j.code} size="md" container />
                        </Tooltip> )}
                      </> : 
                      <MDTypography>Відсутні</MDTypography>}
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MDBox
                      bgColor="light"
                      variant="gradient"
                      borderRadius="lg"
                      shadow="lg"
                      opacity={1}
                      p={2}
                    >
                      <MDTypography variant="h6">Обертання</MDTypography>
                      {isuLevel.rule?.rotations ? <>
                        {isuLevel.rule?.rotations.map(r => 
                        <Tooltip title={GetRotationDescription(r.code)} key={r.code} style={{marginLeft: 5}}>
                          <MDBadge badgeContent={r.code} size="md" container />
                        </Tooltip> )}
                      </> : 
                      <MDTypography>Відсутні</MDTypography>}
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MDBox
                      bgColor="light"
                      variant="gradient"
                      borderRadius="lg"
                      shadow="lg"
                      opacity={1}
                      p={2}
                    >
                      <MDTypography variant="h6">Доріжки</MDTypography>
                      {isuLevel.rule?.sequence ? <>
                        {isuLevel.rule?.sequence.map(r => 
                        <Tooltip title={GetSequencesDescription(r.code, r.level)} key={r.code} style={{marginLeft: 5}}>
                          <MDBadge badgeContent={(r.level === "Base" ? "" : r.level) + r.code} size="md" container />
                        </Tooltip> )}
                      </> : 
                      <MDTypography>Відсутні</MDTypography>}
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>)}
            </div>
          }
        />

      </MDBox>
    </DashboardLayout>
  );
}
