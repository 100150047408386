const TestingMarks = {
  A: "A",
  B: "B",
  C: "C",
  D: "D",
};

module.exports = {
  TestingMarks,
};
