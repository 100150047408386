/* eslint-disable no-undef */
import { useState } from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDInput";
import ReactPlayer from "react-player";

import "assets/reactplayer.css";
import { PhotoCamera } from "@mui/icons-material";
import MDButton from "components/MDButton";

const uuid = require("uuid");

function VideoStep({ context, onChange }) {
  const [videoURL, setVideoURL] = useState(context.url ?? "");
  const [coverImage, setCoverImage] = useState();
  // const [articleTitle, setArticleTitle] = (props.context.articleTitle ?? "");

  const handleAddCoverImage = (e) => {
    const coverImg = {
      id: uuid.v1(),
      file: e.target.files[0],
      url: URL.createObjectURL(e.target.files[0]),
    };
    setCoverImage(coverImg);

    onChange({ newCoverImage: coverImg, coverImageChanged: true });
  };

  return (
    <MDBox>
      <Grid container alignItems="center">
        <Grid item xs={12} style={{ padding: 10 }}>
          <input
            style={{ display: "none" }}
            accept="image/*"
            id="img-files"
            multiple
            type="file"
            onChange={(e) => handleAddCoverImage(e, "img")}
          />
          <img
            style={{ width: "100%", marginBottom: 5 }}
            src={
              context.coverImageChanged
                ? context.newCoverImage.url
                : coverImage?.url ?? context.cover_image ?? ""
            }
            alt=""
          />
        </Grid>
        <Grid item xs={12} style={{ padding: 10, marginBottom: 25 }}>
          <label htmlFor="img-files">
            <MDButton variant="contained" color="primary" component="span">
              <PhotoCamera /> Фонове Зображення
            </MDButton>
          </label>
        </Grid>
        <Grid item xs={10} md={8} style={{ marginBottom: 10 }}>
          <MDInput
            fullWidth
            label="Посилання"
            value={videoURL}
            onChange={(event) => {
              setVideoURL(event.target.value);
              onChange({ url: event.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <div style={{ width: 400 }}>
            <div className="player-wrapper">
              <ReactPlayer
                url={videoURL}
                light=""
                width="100%"
                height="100%"
                className="react-player"
                controls
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                    },
                  },
                }}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </MDBox>
  );
}

VideoStep.defaultProps = {};

VideoStep.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  context: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default VideoStep;
