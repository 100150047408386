/* eslint-disable */
/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import CustomCard from "components/CustomCard";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Autocomplete } from "@mui/material";
import { ShowAlert, AlertTypes } from "components/Alerts";
import ConfigService, { ConfigResources } from "api/ConfigService";

import { isEmpty } from "helpers/strhelper";

function EditUserForm({ context, onSubmit, onCancel }) {
  const [editEmail, setEditEmail] = useState(context.accountid);
  const [editName, setEditName] = useState(context.firstname);
  const [editFamilyName, setEditFamilyName] = useState(context.familyname);
  const [editBirthdate, setEditBirthdate] = useState(new Date(context.birthdate));

  const [editGroup, setEditGroup] = useState(null);
  const [editCategory, setEditCategory] = useState(null);

  const [groupsList, setGroupsList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);

  
  const fetchData = async () => {
    try {
      const gpList = (await ConfigService.getResources(ConfigResources.Groups)).map((x) => ({
        id: x.id,
        label: x.title,
      }));

      const ctList = (await ConfigService.getResources(ConfigResources.InternalEvaluation)).map(
        (x) => ({
          id: x.id,
          label: x.title,
        })
      );

      setGroupsList(gpList);
      setCategoriesList(ctList);

      if (!isEmpty(context.groupid)) {
        setEditGroup(gpList.find((x) => x.id === context.groupid) ?? "");
      }
      if (!isEmpty(context.categoryid)) {
        setEditCategory(ctList.find((x) => x.id === context.categoryid) ?? "");
      }
    } catch (error) {
      console.error(error);
      const msg = "Невдалося отримати данні з сервера для сторінки";
      ShowAlert(msg, AlertTypes.Error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <MDBox>
      <MDBox style={{ paddingBottom: 20 }}>
        <CustomCard
          title="Редагування Профайлу"
          iconName="person"
          content={
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={5}>
                <MDInput
                  fullWidth
                  label="Email"
                  value={editEmail}
                  onChange={(event) => {
                    setEditEmail((event.target.value ?? "").toLowerCase());
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <DatePicker
                  mask="__.__.____"
                  label="Дата Народження"
                  value={editBirthdate}
                  onChange={(date) => {
                    setEditBirthdate(date);
                  }}
                />
              </Grid>
              <Grid item xs={10} md={8}>
                <MDInput
                  fullWidth
                  label="Ім'я"
                  value={editName}
                  onChange={(event) => {
                    setEditName(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={10} md={8}>
                <MDInput
                  fullWidth
                  label="Прізвище"
                  value={editFamilyName}
                  onChange={(event) => {
                    setEditFamilyName(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={10} md={8}>
                <Autocomplete
                  fullWidth
                  disablePortal
                  id="group-select"
                  options={groupsList}
                  value={editGroup}
                  renderInput={(params) => <MDInput {...params} label="Група" />}
                  onChange={(event, newValue) => {
                    setEditGroup(newValue);
                  }}
                />
              </Grid>
              <Grid item xs={10} md={8}>
                <Autocomplete
                  fullWidth
                  disablePortal
                  id="category-select"
                  options={categoriesList}
                  value={editCategory}
                  renderInput={(params) => <MDInput {...params} label="Спортивна Категорія" />}
                  onChange={(event, newValue) => {
                    setEditCategory(newValue);
                  }}
                />
              </Grid>
            </Grid>
          }
        />
      </MDBox>
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <MDButton
            style={{ marginRight: 10, width: 125 }}
            variant="gradient"
            color="info"
            onClick={() => {
              onSubmit({
                ...context,
                birthdate: editBirthdate,
                accountid: editEmail,
                firstname: editName,
                familyname: editFamilyName,
                groupid: editGroup?.id ?? "",
                categoryid: editCategory?.id ?? "",
              });
            }}
          >
            Зберегти
          </MDButton>
          <MDButton
            variant="gradient"
            color="dark"
            style={{ width: 125 }}
            onClick={() => {
              onCancel();
            }}
          >
            Відміна
          </MDButton>
        </Grid>
      </Grid>
    </MDBox>
  );
}

EditUserForm.defaultProps = {};

EditUserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  context: PropTypes.object.isRequired,
};

export default EditUserForm;
