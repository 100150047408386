/* eslint-disable */
/* eslint-disable no-console */
import { isEmpty } from "helpers/strhelper";
import { rrulestr } from "rrule";
import { getMonth, getYear } from "date-fns";
import { appConfig } from "config/appConfig";
import { API, Auth } from "aws-amplify";
import UsersService from "./UsersService";

const accScheduleCacheKey = "accschedule";
const accTraienrScheduleCacheKey = "acctrainerschedule";
const indyvsCallendarCacheKey = "indyvsCalendar";

const ScheduleService = {
  // get complete calendar for admins
  getCalendar() {
    const path = "/schedule";
    const params = {
      response: true,
      queryStringParameters: {
        type: "calendar",
      },
    };
    return API.get(appConfig.SECURE_API_NAME, path, params);
  },

  // get regular callendar for particular group
  async getCalendarByGroupId(groupid, month, day) {
    try {
      const cacheKey = `group_calendar_${groupid}_${month}`;
      const cacheData = sessionStorage.getItem(cacheKey);
      if (!isEmpty(cacheData)) {
        return JSON.parse(cacheData);
      }

      const path = "/schedule";
      const params = {
        response: true,
        queryStringParameters: {
          type: "calendar",
          groupid,
          month,
        },
      };
      const response = await API.get(appConfig.SECURE_API_NAME, path, params);

      let appointments = [];
      const currentDate = new Date();
      response.data.forEach((element) => {
        if (element.rRule) {
          const startdate = element.startDate
            .replaceAll("-", "")
            .replaceAll(":", "")
            .replace(".000", "");
          const rule = rrulestr(`DTSTART:${startdate}\n${element.rRule}`);

          const curentYear = getYear(currentDate);
          const currentMonth = getMonth(currentDate);
          const appDates = rule.between(
            new Date(Date.UTC(curentYear, currentMonth, 1)),
            new Date(
              day
                ? Date.UTC(curentYear, currentMonth, day)
                : Date.UTC(curentYear, currentMonth + 1, 1)
            )
          );

          appDates.forEach((record) => {
            const datestr = record.toISOString();
            const nextApp = {
              id: element.id,
              trainingid: element.trainingid,
              groupid: element.groupid,
              startDate: datestr,
              endDate: element.endDate,
              trainers: element.trainers,
              locationid: element.locationid,
            };

            appointments = appointments.concat(nextApp);
          });
        } else {
          appointments = appointments.concat(element);
        }
      });

      const sortedData = appointments.sort((a, b) => (a.startDate > b.startDate ? 1 : -1));

      sessionStorage.setItem(cacheKey, JSON.stringify(sortedData));
      return sortedData;
    } catch (error) {
      return [];
    }
  },

  // get personal schedule
  async getSchedule(accountname, accountgroups) {
    const distinct = (value, index, self) => self.indexOf(value) === index;

    try {
      const cacheData = sessionStorage.getItem(accScheduleCacheKey);
      if (!isEmpty(cacheData)) {
        return JSON.parse(cacheData);
      }

      const path = "/schedule";
      const params = {
        response: true,
        queryStringParameters: {
          type: "shedule",
          groupid: accountgroups.filter(distinct).join(),
          useraccount: accountname,
        },
      };
      console.log("params", params);
      const response = await API.get(appConfig.SECURE_API_NAME, path, params);

      sessionStorage.setItem(accScheduleCacheKey, JSON.stringify(response.data));
      return response.data;
    } catch (error) {
      return [];
    }
  },

  // get personal schedule for a trainer
  async getTrainerSchedule(trainerid) {
    try {
      const cacheData = sessionStorage.getItem(accTraienrScheduleCacheKey);
      if (!isEmpty(cacheData)) {
        return JSON.parse(cacheData);
      }

      const path = "/schedule";
      const params = {
        response: true,
        queryStringParameters: {
          type: "trainerschedule",
          trainerid,
        },
      };
      const response = await API.get(appConfig.SECURE_API_NAME, path, params);

      sessionStorage.setItem(accTraienrScheduleCacheKey, JSON.stringify(response.data));
      return response.data;
    } catch (error) {
      return [];
    }
  },

  // get all indyv slots
  async getIndyvsCalendar(refreshCache) {
    if (refreshCache) {
      sessionStorage.removeItem(indyvsCallendarCacheKey);
    }

    const cachedValue = sessionStorage.getItem(indyvsCallendarCacheKey);
    if (!isEmpty(cachedValue)) {
      return JSON.parse(cachedValue);
    }

    const path = "/schedule";
    const params = {
      response: true,
      queryStringParameters: {
        type: "indyvs",
      },
    };
    const response = await API.get(appConfig.SECURE_API_NAME, path, params);

    sessionStorage.setItem(indyvsCallendarCacheKey, JSON.stringify(response.data));
    return response.data;
  },

  async assignIndyvCalendarEntry(entry) {
    try {
      const path = "/indyvs/assignindyv";
      const params = {
        response: true,
        body: entry,
      };

      await API.post(appConfig.SECURE_API_NAME, path, params);

      sessionStorage.removeItem(accScheduleCacheKey);
      sessionStorage.removeItem(indyvsCallendarCacheKey);
    } catch (error) {
      console.log("Unable to assign indyv entry:", error);
      if (error.response?.data && error.response.data?.code === "ConditionalCheckFailedException") {
        sessionStorage.removeItem(accScheduleCacheKey);
        sessionStorage.removeItem(indyvsCallendarCacheKey);
        throw new Error("Не вдалося зареєструвати індивідуалку.");
      }
      throw new Error(error);
    }
  },

  // get/scheck indyvs for particular user
  getIndyvsbyUserId(userid, accountid) {
    const path = "/schedule";
    const params = {
      response: true,
      queryStringParameters: {
        type: "indyvs",
        userid,
        accountid,
      },
    };
    return API.get(appConfig.SECURE_API_NAME, path, params);
  },

  async updateCalendarEntry(entry) {
    try {
      const path = `/schedule/${entry.id}`;
      const params = {
        response: true,
        body: entry,
      };
      await API.put(appConfig.SECURE_API_NAME, path, params);

      sessionStorage.removeItem(accScheduleCacheKey);
      sessionStorage.removeItem(indyvsCallendarCacheKey);
    } catch (error) {
      if (error.response?.data && error.response.data?.code === "ConditionalCheckFailedException") {
        sessionStorage.removeItem(accScheduleCacheKey);
        sessionStorage.removeItem(indyvsCallendarCacheKey);
        throw new Error("Не вдалося зареєструвати індивідуалку. ");
      }
      throw new Error(error);
    }
  },

  async cancelIndyvEntry(entry) {
    try {
      const path = "/indyvs/cancelindyv";
      const params = {
        response: true,
        body: entry,
      };
      await API.post(appConfig.SECURE_API_NAME, path, params);

      sessionStorage.removeItem(accScheduleCacheKey);
      sessionStorage.removeItem(indyvsCallendarCacheKey);
    } catch (error) {
      if (error.response?.data && error.response.data?.code === "ConditionalCheckFailedException") {
        sessionStorage.removeItem(accScheduleCacheKey);
        sessionStorage.removeItem(indyvsCallendarCacheKey);
      }
      throw new Error(error);
    }
  },

  async loadCallendar() {
    const profileRecord = await Auth.currentAuthenticatedUser();
    const accountProfiles = await UsersService.getCurrentAccountProfiles();

    const groups = accountProfiles.map((x) => x.groupname);
    const path = "/schedule";
    const params = {
      response: true,
      queryStringParameters: {
        groupid: groups.join(),
        indyv: profileRecord.username,
      },
    };
    const response = await API.get(appConfig.SECURE_API_NAME, path, params);

    if (response?.data) {
      sessionStorage.setItem("accountCalendar", JSON.stringify(response.data));
      return response.data;
    }
    return [];
  },

  getAccountCalendar() {
    const cachedValue = sessionStorage.getItem("accountCalendar");
    if (!isEmpty(cachedValue)) {
      console.log("get cached calendar");
      return JSON.parse(cachedValue);
    }

    return this.loadCallendar();
  },

  async getAccountNextAppointment() {
    const calendarData = (await this.getAccountCalendar()).filter(
      (x) => x.groupName !== "Індивідуалка"
    );

    return this.calculateNextAppointment(calendarData);
  },

  async getAccountIndyvs() {
    const profileRecord = await Auth.currentAuthenticatedUser();
    return (await this.getAccountCalendar()).filter(
      (x) => x.groupName === "Індивідуалка" && x.indyvusername === profileRecord.username
    );
  },

  calculateNextAppointment(data) {
    let appointments = [];
    const currentDate = new Date();
    data.forEach((element) => {
      if (element.rRule) {
        const startdate = element.startDate
          .replaceAll("-", "")
          .replaceAll(":", "")
          .replace(".000", "");
        const rule = rrulestr(`DTSTART:${startdate}\n${element.rRule}`);
        const nextAppDate = rule.after(currentDate, false);
        if (nextAppDate) {
          const nextApp = element;
          nextApp.startDate = nextAppDate.toISOString();
          appointments = appointments.concat(nextApp);
        }
      } else {
        appointments = appointments.concat(element);
      }
    });
    const sorted = appointments.sort((a, b) => (a.startDate > b.startDate ? 1 : -1));
    const itemToshow = sorted.find((x) => x.startDate > currentDate.toISOString());
    return itemToshow;
  },

  clearCalendarCache() {
    sessionStorage.removeItem("accountCalendar");
    sessionStorage.removeItem("indyvCalendar");
  },

  deleteCalendarEntry(deleteid) {
    const path = `/schedule/${deleteid}`;
    return API.del(appConfig.SECURE_API_NAME, path);
  },

  //NEW
  // get complete calendar for admins
  getBauerCalendar() {
    const path = "/bauerschedule";
    const params = {
      response: false,
      queryStringParameters: {
        // from: "calendar",
        // to:
      },
    };
    return API.get(appConfig.SECURE_API_NAME, path, params);
  },

  async createEvent(event) {
    try {
      const path = `/bauerschedule`;
      const params = {
        body: event,
      };
      await API.post(appConfig.SECURE_API_NAME, path, params);
    } catch (error) {
      throw new Error(error);
    }
  },

  async updateEvent(event) {
    try {
      const path = `/bauerschedule`;
      const params = {
        body: event,
      };
      await API.put(appConfig.SECURE_API_NAME, path, params);
    } catch (error) {
      throw new Error(error);
    }
  },

  async deleteEvent(event) {
    try {
      const path = `/bauerschedule`;
      const params = {
        body: event,
      };
      await API.del(appConfig.SECURE_API_NAME, path, params);
    } catch (error) {
      throw new Error(error);
    }
  }
};

export default ScheduleService;
