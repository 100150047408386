/* eslint-disable */
import { useState } from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import { AddCircleOutline } from "@mui/icons-material";
import MDButton from "components/MDButton";
import CustomCard from "components/CustomCard";
import MDTypography from "components/MDTypography";
import { TextField } from "@mui/material";

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import uaLocale from "date-fns/locale/uk";
import { Autocomplete } from "@mui/material";
import ConfigService from "api/ConfigService";
import { ConfigResources } from "api/ConfigService";
import { useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";

function NewAccountForm({ onSubmit, onCancel }) {
  const [editEmail, setEditEmail] = useState("");
  const [editName, setEditName] = useState("");
  const [editFamilyName, setEditFamilyName] = useState("");
  const [editAddress, setEditAddress] = useState("");
  const [editZoneInfo, setEditZoneInfo] = useState("");
  const [editTempPass, setEditTempPass] = useState("");

  const [userName, setUserName] = useState("");
  const [userBirthdate, setUserBirthdate] = useState(new Date());
  const [editGroup, setEditGroup] = useState('');
  const [editCategory, setEditCategory] = useState(null);

  
  const [groupsList, setGroupsList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);

  const fetchData = async () => {
    try {
      const gpList = (await ConfigService.getResources(ConfigResources.Groups)).map((x) => ({
        id: x.id,
        label: x.title,
      }));

      const ctList = (await ConfigService.getResources(ConfigResources.InternalEvaluation)).map(
        (x) => ({
          id: x.id,
          label: x.title,
        })
      );

      setGroupsList(gpList);
      setCategoriesList(ctList);

    } catch (error) {
      console.error(error);
      // const msg = "Невдалося отримати данні з сервера для сторінки";
      // ShowAlert(msg, AlertTypes.Error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <MDBox>
      <CustomCard
        title=""
        content={
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                value={editEmail}
                onChange={(event) => {
                  setEditEmail(event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Ім'я"
                value={editName}
                onChange={(event) => {
                  setEditName(event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Прізвище"
                value={editFamilyName}
                onChange={(event) => {
                  setEditFamilyName(event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Адреса"
                value={editAddress}
                onChange={(event) => {
                  setEditAddress(event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Посада"
                value={editZoneInfo}
                onChange={(event) => {
                  setEditZoneInfo(event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="password"
                inputProps={{
                  autoComplete: "new-password",
                }}
                label="Пароль"
                value={editTempPass}
                onChange={(event) => {
                  setEditTempPass(event.target.value);
                }}
              />
              <MDTypography variant="overline" pl={1}>
                Мінімальна довжина 8 символів
              </MDTypography>
            </Grid>
            <Grid item xs={12}>
              <hr />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Ім'я Спортсмена"
                value={userName}
                onChange={(event) => {
                  setUserName(event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={uaLocale}>
                <DatePicker
                  mask="__.__.____"
                  renderInput={(params) => <TextField {...params} />}
                  label="Дата Народження"
                  value={userBirthdate}
                  onChange={(date) => {
                    setUserBirthdate(date);
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={10} md={8}>
                <Autocomplete
                  fullWidth
                  disablePortal
                  id="group-select"
                  options={groupsList}
                  value={editGroup}
                  renderInput={(params) => <TextField {...params} label="Група" />}
                  onChange={(event, newValue) => {
                 
                    setEditGroup(newValue);
                  }}
                />
              </Grid>
              <Grid item xs={10} md={8}>
                <Autocomplete
                  fullWidth
                  disablePortal
                  id="category-select"
                  options={categoriesList}
                  value={editCategory}
                  renderInput={(params) => <TextField {...params} label="Спортивна Категорія" />}
                  onChange={(event, newValue) => {
                    setEditCategory(newValue);
                  }}
                />
              </Grid>
          </Grid>
        }
      />
      <MDBox style={{ marginTop: 10 }}>
        <Grid container>
          <Grid item xs={12}>
            <MDButton
              style={{ marginRight: 10, width: 125 }}
              variant="gradient"
              color="info"
              onClick={() => {
                onSubmit({
                  email: editEmail,
                  name: editName,
                  familyName: editFamilyName,
                  address: editAddress,
                  zoneInfo: editZoneInfo,
                  tempPass: editTempPass,

                  userName: userName,
                  userBirthdate: userBirthdate,
                  userGroup: editGroup,
                  userCategory: editCategory,
                });
              }}
            >
              <AddCircleOutline style={{ marginRight: 5 }} /> Створити
            </MDButton>
            <MDButton
              variant="gradient"
              color="dark"
              style={{ width: 125 }}
              onClick={() => {
                onCancel();
              }}
            >
              Відміна
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

NewAccountForm.defaultProps = {};

NewAccountForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default NewAccountForm;
