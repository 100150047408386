import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { ShowAlert, AlertTypes } from "components/Alerts";
import { useEffect, useState } from "react";
import { Grid, Skeleton } from "@mui/material";
import CustomCard from "components/CustomCard";
import MDButton from "components/MDButton";
import { AddAPhoto } from "@mui/icons-material";
import MDInput from "components/MDInput";
import ConfigService from "api/ConfigService";
import resizeFile from "helpers/ImageResizer";
import MediaService from "api/MediaService";

const currentPageName = "landingpage";

export default function MainPageSettings() {
  const [pageSettings, setPageSettings] = useState();
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [, setRequestInProgress] = useState(false);

  const [iceImageChanged, setIceImageChanged] = useState(false);
  const [rollersImageChanged, setRollersImageChanged] = useState(false);

  const [newImages, setNewImages] = useState({
    iceskating: null,
    rollerskating: null,
  });

  const [imagePreviews, setImagePreviews] = useState({
    iceskating: "",
    rollerskating: "",
  });

  const handleChangeSettings = (prop) => {
    const newSettings = { ...pageSettings };
    const newDep = newSettings.values.find((x) => x.id === prop.id);
    if (newDep) {
      newDep.title = prop.title;
      newDep.description = prop.description;
    }
    setPageSettings(newSettings);
  };

  const handleChangeCoverImage = (e, depId) => {
    const imgFile = {
      file: e.target.files[0],
      url: URL.createObjectURL(e.target.files[0]),
    };

    switch (depId) {
      case "iceskating":
        setIceImageChanged(true);
        break;
      case "rollerskating":
        setRollersImageChanged(true);
        break;
      default:
        break;
    }
    const newImg = { ...newImages };
    newImg[depId] = imgFile.file;
    setNewImages(newImg);
    const newChanged = { ...imagePreviews };
    newChanged[depId] = imgFile.url;
    setImagePreviews(newChanged);
  };

  const handleSaveChanges = async () => {
    try {
      setRequestInProgress(true);
      const recordToUpdate = { ...pageSettings };
      let filepath = "";
      let filename = "";
      let response = {};

      if (iceImageChanged) {
        // upload new image to S3
        // console.log('upload new version of kids cover image');
        const imagetoSave = await resizeFile(newImages.iceskating, 720, 720);
        filepath = "pages/landingpage";
        filename = `ice_cover_img.${newImages.iceskating.name.split(".")[1]}`;
        response = await MediaService.uploadMediaToS3(imagetoSave, filepath, filename);
        // response.location - file path

        const icedep = recordToUpdate.values.find((x) => x.id === "iceskating");
        icedep.img_url = response.Location;
        setIceImageChanged(false);
      }
      if (rollersImageChanged) {
        // upload new image to S3
        // console.log('upload new version of school cover image');
        const imagetoSave = await resizeFile(newImages.rollerskating, 720, 720);
        filepath = "pages/landingpage";
        filename = `rollers_cover_img.${newImages.rollerskating.name.split(".")[1]}`;
        response = await MediaService.uploadMediaToS3(imagetoSave, filepath, filename);
        // response.location - file path

        const rdep = recordToUpdate.values.find((x) => x.id === "rollerskating");
        rdep.img_url = response.Location;
        setRollersImageChanged(false);
      }

      await ConfigService.updatePageSettings(recordToUpdate);

      ShowAlert("Зміни Збережено", AlertTypes.Success, true);
    } catch (error) {
      const msg = "Невдалося отримати данні з сервера для сторінки";
      ShowAlert(msg, AlertTypes.Error);
    } finally {
      setRequestInProgress(false);
    }
  };

  const fetchData = async () => {
    try {
      const response = await ConfigService.getPageSettings(currentPageName);
      setPageSettings(response);
      setImagePreviews({
        iceskating: response.values.find((x) => x.id === "iceskating")?.img_url ?? "",
        rollerskating: response.values.find((x) => x.id === "rollerskating")?.img_url ?? "",
      });

      setIceImageChanged(false);
      setRollersImageChanged(false);
    } catch (error) {
      const msg = "Невдалося отримати данні з сервера для сторінки";
      ShowAlert(msg, AlertTypes.Error);
    } finally {
      setPageIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {pageIsLoading ? (
          <div>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
        ) : (
          <div>
            <Grid container>
              {(pageSettings?.values ?? []).map((dep) => (
                <Grid
                  item
                  key={dep.id}
                  xs={12}
                  md={12}
                  lg={6}
                  style={{ padding: 5, paddingBottom: 20 }}
                >
                  <CustomCard
                    title={dep.title}
                    content={
                      <div>
                        <div
                          style={{
                            backgroundImage: `url(${imagePreviews[dep.id]})`,
                            backgroundSize: "cover",
                          }}
                        >
                          <div
                            style={{
                              color: "#FFF",
                              paddingTop: 100,
                              paddingBottom: 100,
                              paddingLeft: 25,
                              paddingRight: 25,
                              background: "rgba(0, 0, 0, 0.5)",
                              textAlign: "center",
                              height: 555,
                            }}
                          >
                            <div style={{ textAlign: "right", top: -80, position: "relative" }}>
                              <input
                                style={{ display: "none" }}
                                accept="image/*"
                                id={`img-files${dep.id}`}
                                multiple
                                type="file"
                                onChange={(e) => handleChangeCoverImage(e, dep.id)}
                              />
                              <label htmlFor={`img-files${dep.id}`}>
                                <MDButton variant="contained" color="primary" component="span">
                                  <AddAPhoto />
                                </MDButton>
                              </label>
                            </div>
                            <h2>{dep.title}</h2>
                            <h4>{dep.description}</h4>
                          </div>
                        </div>
                        <hr />
                        <Grid container style={{ paddingTop: 20 }}>
                          <Grid item xs={6} style={{ padding: 5 }}>
                            <MDInput
                              fullWidth
                              label="Заголовок"
                              id={`title_${dep.id}`}
                              value={dep.title ?? ""}
                              onChange={(event) =>
                                handleChangeSettings({ ...dep, title: event.target.value })
                              }
                            />
                          </Grid>
                          <Grid item xs={6} style={{ padding: 5 }}>
                            <MDInput
                              fullWidth
                              label="Заголовок (EN)"
                              id={`title_en_${dep.id}`}
                              value={dep.title_en ?? ""}
                              onChange={(event) =>
                                handleChangeSettings({ ...dep, title_en: event.target.value })
                              }
                            />
                          </Grid>
                          <Grid item xs={6} style={{ padding: 5 }}>
                            <MDInput
                              fullWidth
                              label="Короткий Опис"
                              id={`descr${dep.id}`}
                              value={dep.description ?? ""}
                              multiline
                              rows={5}
                              onChange={(event) =>
                                handleChangeSettings({ ...dep, description: event.target.value })
                              }
                            />
                          </Grid>
                          <Grid item xs={6} style={{ padding: 5 }}>
                            <MDInput
                              fullWidth
                              label="Короткий Опис (EN)"
                              id={`descr${dep.id}`}
                              value={dep.description_en ?? ""}
                              onChange={(event) =>
                                handleChangeSettings({ ...dep, description_en: event.target.value })
                              }
                              multiline
                              rows={5}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    }
                  />
                </Grid>
              ))}
            </Grid>

            <Grid container alignItems="center" style={{ paddingTop: 0 }}>
              <Grid item xs={12}>
                <MDButton
                  style={{ marginRight: 10 }}
                  variant="gradient"
                  color="info"
                  onClick={handleSaveChanges}
                >
                  Зберегти
                </MDButton>
                <MDButton
                  variant="gradient"
                  color="dark"
                  onClick={() => {
                    fetchData();
                    ShowAlert("Зміни Відмінено", AlertTypes.Success);
                  }}
                >
                  Відміна
                </MDButton>
              </Grid>
            </Grid>
          </div>
        )}
      </MDBox>
    </DashboardLayout>
  );
}
