import MDBox from "components/MDBox";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import MDButton from "components/MDButton";
import { useState } from "react";
import MDInput from "components/MDInput";
import CustomCard from "components/CustomCard";
import { AddAPhoto } from "@mui/icons-material";
import defaultImage from "assets/images/image_placeholder.jpg";
import { isEmpty } from "helpers/strhelper";

function EditTeamSectionItem({ context, onSubmit, onCancel }) {
  const [firstName, setFirstName] = useState(context?.firstname ?? "");
  const [firstNameEn, setFirstNameEn] = useState(context?.firstname_en ?? "");

  const [familyName, setFamilyName] = useState(context?.familyname ?? "");
  const [familyNameEn, setFamilyNameEn] = useState(context?.familyname_en ?? "");

  const [position, setPosition] = useState(context?.position ?? "");
  const [positionEn, setPositionEn] = useState(context?.position_en ?? "");

  const [info, setInfo] = useState(context?.info ?? "");
  const [infoEn, setInfoEn] = useState(context?.info_en ?? "");

  const [itemImage, setItemImage] = useState();
  const [imageChanged, setImageChanged] = useState(false);

  const [imagePrevUrl, setImagePrevUrl] = useState(context?.avatarurl ?? "");

  const handleChangeImage = (e) => {
    const imgFile = {
      file: e.target.files[0],
      url: URL.createObjectURL(e.target.files[0]),
    };

    setImageChanged(true);
    setItemImage(imgFile.file);
    setImagePrevUrl(imgFile.url);
  };

  return (
    <MDBox py={3} style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 10, paddingRight: 10 }}>
      <MDBox style={{ marginBottom: 10, paddingTop: 0 }}>
        <CustomCard
          title="Редагування Запису"
          content={
            <Grid container alignItems="flex-start" justifyContent="center">
              <Grid item xs={12}>
                <img
                  src={isEmpty(imagePrevUrl) ? defaultImage : imagePrevUrl}
                  alt="..."
                  style={{ maxWidth: 150, marginTop: 35, marginBottom: 5, borderRadius: 10 }}
                />

                <input
                  style={{ display: "none" }}
                  accept="image/*"
                  id="img-file"
                  multiple
                  type="file"
                  onChange={(e) => {
                    handleChangeImage(e);
                  }}
                />
                <label htmlFor="img-file">
                  <MDButton variant="gradient" color="info" component="span" style={{ margin: 10 }}>
                    <AddAPhoto style={{ marginRight: 5 }} />
                    Змінити Зображення
                  </MDButton>
                </label>
              </Grid>
              <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                <MDInput
                  label="Ім'я"
                  id="name"
                  fullWidth
                  value={firstName}
                  onChange={(event) => setFirstName(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                <MDInput
                  label="Ім'я (EN)"
                  id="name_en"
                  fullWidth
                  value={firstNameEn}
                  onChange={(event) => setFirstNameEn(event.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                <MDInput
                  label="Прізвище"
                  id="familyname"
                  fullWidth
                  multiline
                  rows={2}
                  value={familyName}
                  onChange={(event) => setFamilyName(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                <MDInput
                  label="Прізвище (EN)"
                  id="familyname_en"
                  fullWidth
                  multiline
                  rows={2}
                  value={familyNameEn}
                  onChange={(event) => setFamilyNameEn(event.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                <MDInput
                  label="Посада"
                  id="position"
                  fullWidth
                  multiline
                  rows={3}
                  value={position}
                  onChange={(event) => setPosition(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                <MDInput
                  label="Посада (EN)"
                  id="position_en"
                  fullWidth
                  multiline
                  rows={3}
                  value={positionEn}
                  onChange={(event) => setPositionEn(event.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                <MDInput
                  label="Опис"
                  id="info"
                  fullWidth
                  multiline
                  rows={6}
                  value={info}
                  onChange={(event) => setInfo(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                <MDInput
                  label="Опис (EN)"
                  id="info_en"
                  fullWidth
                  multiline
                  rows={6}
                  value={infoEn}
                  onChange={(event) => setInfoEn(event.target.value)}
                />
              </Grid>
            </Grid>
          }
        />
      </MDBox>

      <Grid container alignItems="center">
        <Grid item xs={12} style={{ padding: 5 }}>
          <MDButton
            style={{ marginRight: 10, width: 125 }}
            variant="gradient"
            color="info"
            onClick={() => {
              onSubmit({
                id: context?.id,
                firstname: firstName,
                firstname_en: firstNameEn,
                familyname: familyName,
                familyname_en: familyNameEn,
                position,
                position_en: positionEn,
                info,
                info_en: infoEn,
                imageChanged,
                imagePrevUrl,
                itemImage,
              });
            }}
          >
            OK
          </MDButton>
          <MDButton variant="gradient" color="dark" style={{ width: 125 }} onClick={onCancel}>
            Відміна
          </MDButton>
        </Grid>
      </Grid>
    </MDBox>
  );
}

EditTeamSectionItem.defaultProps = {
  context: null,
};

EditTeamSectionItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  context: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EditTeamSectionItem;
