import Resizer from "react-image-file-resizer";

export default function resizeFile(file, maxWidth, maxHeight) {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth || 3840,
      maxHeight || 2400,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });
}
