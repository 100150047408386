/* eslint-disable no-console */
// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/main-bg.jpg";
import { Auth } from "aws-amplify";
import { useEffect } from "react";

function SignOut() {
  const handleSignOut = () => {
    try {
      Auth.signOut().then(() => {
        window.location.href = "/sign-in";
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    handleSignOut();
  }, []);

  return (
    <BasicLayout image={bgImage}>
      <Card />
    </BasicLayout>
  );
}

export default SignOut;
