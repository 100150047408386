/* eslint-disable react/forbid-prop-types */
import PropTypes from "prop-types";
import { Box, Chip, Divider, Grid, IconButton } from "@mui/material";
import {
  AccessAlarm,
  Delete,
  Edit,
  Event,
  Map,
  Person,
  ContentCopy,
  People,
  PersonAdd,
} from "@mui/icons-material";
import format from "date-fns/format";
import ukLocale from "date-fns/locale/uk";
import MDTypography from "components/MDTypography";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: 2,
  boxShadow: 24,
  px: 2,
  py: 1,
};

function EventPreviewCard({ event, onDelete, onEdit, onCopy }) {
  return (
    <Box sx={style}>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        >
          <MDTypography variant="h5">{event.title}</MDTypography>
          <div>
            <IconButton aria-label="edit" onClick={() => onEdit(event)}>
              <Edit />
            </IconButton>
            <IconButton aria-label="edit" onClick={() => onCopy(event)}>
              <ContentCopy />
            </IconButton>
            <IconButton aria-label="delete" onClick={() => onDelete(event)}>
              <Delete />
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
          <Event />
          <MDTypography variant="button" sx={{ ml: 1 }}>
            {format(new Date(event.start), "eeee, dd MMMM yyyy р.", {
              locale: ukLocale,
            })}
          </MDTypography>
        </Grid>
        <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
          <AccessAlarm />
          <MDTypography variant="body2" sx={{ ml: 1 }}>
            {`${format(new Date(event.start), "HH:mm")} - ${format(new Date(event.end), "HH:mm")}`}
          </MDTypography>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ m: 1 }} />
        </Grid>
        <Grid item xs={12} sx={{ display: "flex" }}>
          <Map />
          <MDTypography variant="body2" sx={{ ml: 1 }}>
            {event.locationTitle}
          </MDTypography>
        </Grid>
        <Grid item xs={12} sx={{ display: "flex" }}>
          <Map />
          <MDTypography variant="body2" sx={{ ml: 1 }}>
            {event.trainingTitle}
          </MDTypography>
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
          <Person sx={{ mr: 1 }} />

          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {event.trainersTitle.map((value) => (
              <Chip key={value} label={value} color="primary" sx={{ mr: 1 }} />
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <People sx={{ mr: 1 }} />

          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {event.groupsTitle.map((value) => (
              <Chip key={value} label={value} sx={{ mr: 1 }} />
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <PersonAdd sx={{ mr: 1 }} />

          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {event.usersTitle.map((value) => (
              <Chip key={value} label={value} sx={{ mr: 1 }} />
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

EventPreviewCard.defaultProps = {
  event: null,
  onEdit: () => {},
  onDelete: () => {},
  onCopy: () => {},
};

// Typechecking props for the SimpleBlogCard
EventPreviewCard.propTypes = {
  event: PropTypes.object,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onCopy: PropTypes.func,
};

export default EventPreviewCard;
