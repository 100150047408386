/* eslint-disable no-console */
import { API } from "aws-amplify";
import { appConfig } from "config/appConfig";
import { isEmpty } from "helpers/strhelper";

const uuid = require("uuid");

const configCacheKey = "app_dict_resources";

export const ConfigResources = {
  GlobalConfigs: "globalconfigs",
  Groups: "groups",
  HomePageVideos: "homepagevideos",
  Trainers: "trainers",
  Locations: "locations",
  Events: "events",
  SportCategories: "sportcategories",
  Trainings: "trainings",
  InternalEvaluation: "internal_evaluations",
};

const ConfigService = {
  async getPageSettings(pagename, department) {
    const pageid = pagename + (department ? `_${department}` : "");
    const ppath = `/config/pages/${pageid}`;
    const params = {
      response: false,
    };

    const response = await API.get(appConfig.SECURE_API_NAME, ppath, params);
    if (response && response.length > 0) {
      // localStorage.setItem(cacheKey, JSON.stringify(response[0]));
      return response[0];
    }
    return {};
  },

  async updatePageSettings(pageSettings) {
    const ppath = `/config/pages/${pageSettings.id}`;
    const response = await API.put(appConfig.SECURE_API_NAME, ppath, {
      body: pageSettings,
      response: false,
    });

    return response;
  },

  async getSectionSettings(sectionName, department) {
    const sectionid = sectionName + (department ? `_${department}` : "");
    const spath = `/config/sections/${sectionid}`;
    const params = {
      response: false,
    };

    const response = await API.get(appConfig.SECURE_API_NAME, spath, params);
    if (response && response.length > 0) {
      // localStorage.setItem(cacheKey, JSON.stringify(response[0]));
      return response[0];
    }
    return {};
  },

  async updateSectionSettings(sectionSettings) {
    const spath = `/config/sections/${sectionSettings.id}`;
    const response = await API.put(appConfig.SECURE_API_NAME, spath, {
      body: sectionSettings,
      response: false,
    });

    return response;
  },

  async getGeneralSettings() {
    const gpath = "/config/general";
    const params = {
      response: false,
    };

    const response = await API.get(appConfig.SECURE_API_NAME, gpath, params);
    if (response && response.length > 0) {
      // localStorage.setItem(cacheKey, JSON.stringify(response[0]));
      return response;
    }
    return [];
  },

  async updateGeneralSettings(generalsettings) {
    const gpath = `/config/general/${generalsettings.id}`;
    const response = await API.put(appConfig.SECURE_API_NAME, gpath, {
      body: generalsettings,
      response: false,
    });

    return response;
  },

  // PORTAL OLD SETTINGS
  async loadResources() {
    const path = "/resources";
    const response = await API.get(appConfig.SECURE_API_NAME, path);
    // sessionStorage.setItem(configCacheKey, JSON.stringify(response));
    return response;
  },

  async getResources(typename, refreshCache) {
    const cacheKey = `configCacheKey_${typename}`;
    try {
      if (isEmpty(typename)) {
        return [];
      }
      const cacheData = sessionStorage.getItem(cacheKey);
      if (!refreshCache && !isEmpty(cacheData) && cacheData !== "undefined") {
        return JSON.parse(cacheData);
      }
      const path = `/resources?type=${typename}`;
      const response = await API.get(appConfig.SECURE_API_NAME, path);
      if (response && response.length > 0) {
        sessionStorage.setItem(cacheKey, JSON.stringify(response[0].values));
        return response[0].values;
      }
      return [];
    } catch (error) {
      console.error("error", error);
      return [];
    }
  },

  getCachedResources(typename) {
    const cacheKey = `configCacheKey_${typename}`;
    const cacheData = sessionStorage.getItem(cacheKey);
    return JSON.parse(cacheData);
  },

  // Names

  getNamebyId(itemid, resourcename) {
    const dict = this.getCachedResources(resourcename);

    return dict?.find((x) => x.id === itemid)?.title;
  },

  async updateConfiguration(configRecod) {
    const path = "/resources";
    const response = await API.put(appConfig.SECURE_API_NAME, path, {
      body: configRecod,
    });
    sessionStorage.removeItem(configCacheKey);
    return response;
  },

  async addNewConfigValue(config, value) {
    try {
      if (config) {
        config.values.push({
          id: uuid.v1(),
          title: value,
        });
      }
      return this.updateConfiguration(config);
    } catch (error) {
      // console.log("error", error);
      return null;
    }
  },

  async init() {
    try {
      // const cacheData = sessionStorage.getItem(configCacheKey);
      // if (!isEmpty(cacheData)) {
      //   return JSON.parse(cacheData);
      // }
      return await this.loadResources();
    } catch (error) {
      return undefined;
    }
  },

  async getConfigResources(filterby) {
    const dataset = await this.init();
    return dataset
      .filter((x) => !filterby || filterby.includes(x.type))
      .map((element) => ({
        fieldName: this.getResourceName(element.type),
        title: element.title,
        allowMultiple: element.allowMultiple ?? false,
        instances: element.values.map((x) => ({
          id: x.id,
          text: x.title,
        })),
      }));
  },

  getResourceName(type) {
    if (type === "locations") return "locationid";
    if (type === "trainings") return "trainingid";

    return type;
  },

  getDefaultResource() {
    return [
      {
        fieldName: "locationid",
        title: "Locations",
        instances: [{ id: "", text: "" }],
      },
      {
        fieldName: "trainingid",
        title: "Trainings",
        instances: [{ id: "", text: "" }],
      },
      {
        fieldName: "trainers",
        title: "Trainers",
        allowMultiple: true,
        instances: [{ id: "", text: "" }],
      },
      {
        fieldName: "groupid",
        title: "Groups",

        instances: [{ id: "", text: "" }],
      },
    ];
  },
};

export default ConfigService;
