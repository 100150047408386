/* eslint-disable */
import PropTypes from "prop-types";
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
} from "@mui/material";
import { Map } from "@mui/icons-material";
import MDTypography from "components/MDTypography";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import MDSelect from "components/MDSelect";
import { useState } from "react";
import MDButton from "components/MDButton";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import MDInput from "components/MDInput";
import format from "date-fns/format";
import { isEmpty } from "helpers/strhelper";
import { addDays, addMinutes } from "date-fns";

function EventEditCard({
  eventEntry,
  locations,
  trainings,
  trainers,
  groups,
  users,
  onCancel,
  onEdit,
  saving,
}) {
  console.log('eventEntry?.extendedProps', eventEntry?.extendedProps)
  const [eventTitle, setEventTitle] = useState(eventEntry?.title ?? "");
  const [invalidTitle, setInvalidTitle] = useState(false);
  const [startDate, setStartDate] = useState(eventEntry ? new Date(eventEntry.start) : new Date());
  const [startTime, setStartTime] = useState(eventEntry ? new Date(eventEntry.start) : new Date());
  const [invalidTime, setInvalidTime] = useState(false);
  const [endTime, setEndTime] = useState(
    isEmpty(eventEntry?.end) ? addMinutes(new Date(eventEntry.start), 60) : new Date(eventEntry.end)
  );

  const [selectedLocation, setSelectedLocation] = useState(
    eventEntry?.extendedProps?.location ?? ""
  );
  const [selectedTraining, setSelectedTraining] = useState(
    eventEntry?.extendedProps?.training ?? ""
  );

  const [availableSpots, setAvailableSpots] = useState(
    eventEntry?.extendedProps?.availableSpots ?? 1
  );
  const [availableFor, setAvailableFor] = useState(eventEntry?.extendedProps?.availableFor ?? "");

  const [selectedTrainers, setSelectedTrainers] = useState(
    eventEntry?.extendedProps?.trainers ?? []
  );
  const [selectedGroups, setSelectedGroups] = useState(eventEntry?.extendedProps?.groups ?? []);
  const [selectedUsers, setSelectedUsers] = useState(eventEntry?.extendedProps?.users ?? []);

  ////////////////////////////

  const [recurringEvent, setRecurringEvent] = useState(false);
  const [recurringEndDate, setRecuringEndDate] = useState(
    eventEntry ? new Date(eventEntry.start) : new Date()
  );
  const [invalidRecurringDate, setInvalidRecurringDate] = useState(false);
  const [mo, setMo] = useState(false);
  const [tu, setTu] = useState(false);
  const [we, setWe] = useState(false);
  const [th, setTh] = useState(false);
  const [fr, setFr] = useState(false);
  const [sa, setSa] = useState(false);
  const [su, setSu] = useState(false);

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        maxWidth: 600,
        // height: "100vh",
        // maxHeight: 800,
        bgcolor: "background.paper",

        borderRadius: 2,
        boxShadow: 24,
        px: 2,
        py: 1,
      }}
    >
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        >
          <MDTypography variant="h5">
            {isEmpty(eventEntry?.id) ? "Створення Нової Події" : "Редагування Події"}
          </MDTypography>
        </Grid>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <TextField
            error={invalidTitle}
            fullWidth
            label="Назва Події"
            variant="outlined"
            value={eventTitle}
            onChange={(e) => {
              setEventTitle(e.target.value);
              setInvalidTitle(false);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <DatePicker
            label="Дата Події"
            defaultValue={new Date()}
            value={startDate}
            onChange={(newValue) => {
              setStartDate(newValue);
              if (newValue.toISOString() > recurringEndDate.toISOString()) {
                setRecuringEndDate(newValue);
              }
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TimePicker
            ampm={false}
            label="Початок"
            views={["hours", "minutes"]}
            value={startTime}
            onChange={(newValue) => setStartTime(newValue)}
          />
        </Grid>
        <Grid item xs={4}>
          <TimePicker
            ampm={false}
            minTime={addMinutes(startTime, 15)}
            onError={(newError) => setInvalidTime(newError)}
            label="Завершення"
            views={["hours", "minutes"]}
            value={endTime}
            onChange={(newValue) => setEndTime(newValue)}
          />
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={recurringEvent}
                  onChange={(event) => setRecurringEvent(event.target.checked)}
                />
              }
              label="Подія Повторюється"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
          <ButtonGroup disabled={!recurringEvent}>
            <Button
              variant={mo ? "contained" : "outlined"}
              style={{ color: mo ? "white" : "gray" }}
              onClick={() => setMo(!mo)}
            >
              Пн
            </Button>
            <Button
              variant={tu ? "contained" : "outlined"}
              style={{ color: tu ? "white" : "gray" }}
              onClick={() => setTu(!tu)}
            >
              Вт
            </Button>
            <Button
              variant={we ? "contained" : "outlined"}
              style={{ color: we ? "white" : "gray" }}
              onClick={() => setWe(!we)}
            >
              Ср
            </Button>
            <Button
              variant={th ? "contained" : "outlined"}
              style={{ color: th ? "white" : "gray" }}
              onClick={() => setTh(!th)}
            >
              Чт
            </Button>
            <Button
              variant={fr ? "contained" : "outlined"}
              style={{ color: fr ? "white" : "gray" }}
              onClick={() => setFr(!fr)}
            >
              Пт
            </Button>
            <Button
              variant={sa ? "contained" : "outlined"}
              style={{ color: sa ? "white" : "gray" }}
              onClick={() => setSa(!sa)}
            >
              Сб
            </Button>
            <Button
              variant={su ? "contained" : "outlined"}
              style={{ color: su ? "white" : "gray" }}
              onClick={() => setSu(!su)}
            >
              Нд
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
        >
          <MDTypography variant="body2" sx={{ mr: 2 }}>
            Завершити подію після
          </MDTypography>
          <DatePicker
            disabled={!recurringEvent}
            defaultValue={addDays(startDate, 1)}
            minDate={addDays(startDate, 1)}
            onError={(newError) => setInvalidRecurringDate(newError)}
            value={recurringEndDate}
            onChange={(newValue) => setRecuringEndDate(newValue)}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ m: 1 }} />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        >
          <MDTypography variant="body2">
            <Map sx={{ mr: 1 }} />
            Вибрати локацію
          </MDTypography>

          <MDSelect
            label="Локація"
            dataset={locations}
            value={selectedLocation}
            defaultValue=""
            onChange={(e) => setSelectedLocation(e.target.value)}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        >
          <MDTypography variant="body2">
            <Map sx={{ mr: 1 }} />
            Вибрати Тренування
          </MDTypography>
          <MDSelect
            label="Тренування"
            dataset={trainings}
            value={selectedTraining}
            defaultValue=""
            onChange={(e) => {
              setSelectedTraining(e.target.value);
              console.log(e.target.value);
              if (e.target.value === "indyv") {
                setEventTitle("Індив");
              }
            }}
          />
        </Grid>
        {selectedTraining === "indyv" && (
          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <MDTypography variant="body2">
                <Map sx={{ mr: 1 }} />
                Кількість місць
              </MDTypography>
              <MDInput
                type="number"
                label="Місць"
                value={availableSpots}
                onChange={(e) => {
                  const slots = parseInt(e.target.value);
                  setAvailableSpots(slots);
                }}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <MDTypography variant="body2">
                <Map sx={{ mr: 1 }} />
                Показувати тільки для
              </MDTypography>
              <MDSelect
                label="Показувати тільки для"
                dataset={groups}
                defaultValue=""
                value={availableFor}
                onChange={(e) => setAvailableFor(e.target.value)}
              />
            </div>
            <Divider sx={{ m: 1 }} />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        >
          <MDTypography variant="body2">
            <Map sx={{ mr: 1 }} />
            Вибрати Тренера
          </MDTypography>
          <MDSelect
            label="Тренер"
            dataset={trainers}
            multiple
            value={selectedTrainers}
            onChange={(e) =>
              setSelectedTrainers(
                typeof e.target.value === "string" ? e.target.value.split(",") : e.target.value
              )
            }
            renderValue={(selected) => (
              <Box sx={{ display: "flex", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={trainers.find((x) => x.id === value)?.title} />
                ))}
              </Box>
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        >
          <MDTypography variant="body2">
            <Map sx={{ mr: 1 }} />
            Вибрати Групу
          </MDTypography>
          <MDSelect
            label="Група"
            multiple
            disabled={selectedTraining === "indyv"}
            dataset={groups}
            value={selectedGroups}
            onChange={(e) =>
              setSelectedGroups(
                typeof e.target.value === "string" ? e.target.value.split(",") : e.target.value
              )
            }
            renderValue={(selected) => (
              <Box sx={{ display: "flex", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={groups.find((x) => x.id === value)?.title} />
                ))}
              </Box>
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        >
          <MDTypography variant="body2">
            <Map sx={{ mr: 1 }} />
            Вибрати Спортсмена
          </MDTypography>
          <MDSelect
            label="Спортсмени"
            dataset={users}
            value={selectedUsers}
            multiple
            onChange={(e) => {
              if (selectedTraining === "indyv" && (e.target.value ?? []).length > availableSpots) {
                return;
              }
              setSelectedUsers(
                typeof e.target.value === "string" ? e.target.value.split(",") : e.target.value
              );
            }}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={users.find((x) => x.id === value)?.title} />
                ))}
              </Box>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ m: 1 }} />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1 }}
        >
          <LoadingButton
            loading={saving}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            color="primary"
            style={{ color: "white", marginRight: 5 }}
            variant="contained"
            onClick={() => {
              if (isEmpty(eventTitle)) {
                setInvalidTitle(true);
                return;
              }

              onEdit({
                // ...eventEntry,
                id: eventEntry?.id,
                // groupId: eventEntry.groupId,
                title: eventTitle,
                start: format(startDate, "yyyy-MM-dd") + "T" + format(startTime, "HH:mm:00"),
                end: format(startDate, "yyyy-MM-dd") + "T" + format(endTime, "HH:mm:00"),
                extendedProps: {
                  location: selectedLocation,
                  training: selectedTraining,
                  availableSpots: selectedTraining === "indyv" ? availableSpots : null,
                  availableFor: availableFor,
                  trainers: selectedTrainers,
                  groups: selectedGroups,
                  users: selectedUsers,
                },
                isRecurring: recurringEvent,
                recurringEndDate: recurringEvent
                  ? format(recurringEndDate, "yyyy-MM-dd") + "T23:59:00"
                  : null,
                recurringDays: recurringEvent
                  ? {
                      mo: mo,
                      tu: tu,
                      we: we,
                      th: th,
                      fr: fr,
                      sa: sa,
                      su: su,
                    }
                  : null,
              });
            }}
          >
            Зберегти
          </LoadingButton>

          <MDButton
            disabled={saving}
            variant="gradient"
            color="dark"
            onClick={() => {
              onCancel();
            }}
          >
            Відміна
          </MDButton>
        </Grid>
      </Grid>
    </Box>
  );
}

EventEditCard.defaultProps = {
  eventEntry: {},
  onEdit: () => {},
  onCancel: () => {},
  locations: [],
  trainers: [],
  trainings: [],
  groups: [],
  users: [],
};

// Typechecking props for the SimpleBlogCard
EventEditCard.propTypes = {
  eventEntry: PropTypes.object,
  locations: PropTypes.array,
  trainers: PropTypes.array,
  trainings: PropTypes.array,
  groups: PropTypes.array,
  users: PropTypes.array,
  onEdit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default EventEditCard;
