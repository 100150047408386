/* eslint-disable */
/* eslint-disable react/no-array-index-key */
import MDBox from "components/MDBox";
import PropTypes from "prop-types";
import { isEmpty } from "helpers/strhelper";
import { Divider, Grid } from "@mui/material";
import MDButton from "components/MDButton";
import { Close, Edit } from "@mui/icons-material";
import MDTypography from "components/MDTypography";
import defaultImage from "assets/images/image_placeholder.jpg";
import MDEditor from "components/MDEditor";

function SectionItemWithImage({ context, onEdit, onDelete }) {
  return (
    <>
      <MDBox
        display="flex"
        py={3}
        style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 10, paddingRight: 10, justifyContent: "space-between" }}>
        <MDBox>

          {context.itemImage && (
            <img
              src={context.imagePrevUrl}
              alt="..."
              style={{ maxWidth: 150, marginTop: 35, marginBottom: 5, borderRadius: 10 }}
            />
          )}
          {isEmpty(context.itemImage) && (
            <img
              src={!isEmpty(context.img_url) ? context.img_url : defaultImage}
              alt="..."
              style={{ maxWidth: 150, marginTop: 35, marginBottom: 5, borderRadius: 10 }}
            />
          )}

          <MDBox>
            <MDTypography variant="h6">{context.title}</MDTypography>
            <MDTypography variant="overline">
              {context.category?.split("\n").map((x, index) => (
                <div key={index}>{x}</div>
              ))}
            </MDTypography>
            <MDEditor fullWidth value={context.description} readOnly theme="bubble" />
          </MDBox>


        </MDBox>
        <MDBox>

          <MDButton
            iconOnly
            circular
            variant="outlined"
            color="dark"
            onClick={onEdit}
            style={{ margin: 5 }}
          >
            <Edit />
          </MDButton>
          <MDButton
            iconOnly
            circular
            variant="outlined"
            color="error"
            onClick={onDelete}
            style={{ margin: 5 }}
          >
            <Close />
          </MDButton>

        </MDBox>
      </MDBox>
      <Divider style={{ marginTop: 20, backgroundColor: "black" }} />
    </>
  );
}

SectionItemWithImage.defaultProps = {};

SectionItemWithImage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  context: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default SectionItemWithImage;
