/* eslint-disable no-console */

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import React, { useState } from "react";
import { ShowAlert, AlertTypes } from "components/Alerts";
import NotificationService from "api/NotificationService";
import UsersService from "api/UsersService";
import { isEmpty } from "helpers/strhelper";
import DefaultInfoCard from "components/Cards/InfoCards/DefaultInfoCard";
// import { useLocation } from "react-router";

export default function Analytics() {
  // const { search } = useLocation();

  const [newTrialRequestsCount, setTrialRequestsCount] = useState(0);
  const [newContactUsForsmCount, setNewContactUsForsmCount] = useState(0);
  
  const [withoutCategory, setWithoutCategory] = useState([]);
  const [withoutGroup, setWithoutGroup] = useState([]);

  const loadUsers = async () => {
    try {
      const response = await UsersService.loadUnassigned();

      setWithoutGroup(response.data.filter((x) => isEmpty(x.groupid)));
      setWithoutCategory(response.data.filter((x) => isEmpty(x.categoryid)));
    } catch (error) {
      console.error("error", error);
    }
  };

  const fetchData = async () => {
    try {
      loadUsers();
      const requestForms = await NotificationService.getNewRequestFormsCount();
      const contactusForms = await NotificationService.getNewContactUsFormsCount();
      
      setTrialRequestsCount(requestForms.length);
      setNewContactUsForsmCount(contactusForms.length);
      // setSubscriptionsCount(subscriptions.length);
    } catch (error) {
      const msg = "Невдалося отримати данні з сервера для сторінки";
      console.error(msg, error);
      ShowAlert(msg, AlertTypes.Error);
    } finally {
      // setPageIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {alert}
        <Grid container>
          <Grid item xs={12} md={6} lg={4} style={{ padding: 15 }}>
            <DefaultInfoCard
              icon="account_balance"
              title="Пробні Тренування"
              description="Отримано нових заявок"
              value={`+${newTrialRequestsCount}`}
              color={newTrialRequestsCount > 0 ? "warning" : "info"}
            />
          </Grid>
         
          <Grid item xs={12} md={6} lg={4} style={{ padding: 15 }}>
            <DefaultInfoCard
              icon="question_answer"
              title="Зворотній Звязок"
              description="Отримано нових заявок"
              value={`+${newContactUsForsmCount}`}
              color={newContactUsForsmCount > 0 ? "warning" : "info"}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} style={{ padding: 15 }}>
            <DefaultInfoCard
              icon="groups"
              title="Відсутня Група"
              color={withoutGroup.length > 0 ? "warning" : "info"}
              value={
                <div>
                  {withoutGroup.length === 0 && <p>Відсутні</p>}
                  {withoutGroup.map((x) => (
                    <p key={x.id}>{`${x.firstname} ${x.familyname}`}</p>
                  ))}
                </div>
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} style={{ padding: 15 }}>
            <DefaultInfoCard
              icon="category"
              title="Відсутня Спортивна Категорія"
              color={withoutCategory.length > 0 ? "warning" : "info"}
              value={
                <div>
                  {withoutCategory.length === 0 && <p>Відсутні</p>}
                  {withoutCategory.map((x) => (
                    <p key={x.id}>{`${x.firstname} ${x.familyname}`}</p>
                  ))}
                </div>
              }
            />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
