/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from "react";

// core components
import { ShowAlert, AlertTypes, ShowConfirmationAlert } from "components/Alerts";

import { compareAsc } from "date-fns";

import format from "date-fns/format";
import ukLocale from "date-fns/locale/uk";
import { isEmpty } from "helpers/strhelper";
import MDBox from "components/MDBox";

import exportToCSV from "helpers/Exporter";

import ScheduleService from "api/ScheduleService";
import ConfigService, { ConfigResources } from "api/ConfigService";

import Grid from "@mui/material/Grid";
import { FormControlLabel, Skeleton, Switch } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import CustomCard from "components/CustomCard";
import MDButton from "components/MDButton";
import { Close, DeleteForever, GetApp } from "@mui/icons-material";

export default function IndyvsListPage() {
  // const search = useLocation().search;

  const [data, setData] = useState([]);
  const [pageIsLoading, setPageIsLoading] = useState(true);

  const [showOnlyOccupied, setShowOnlyOccupied] = useState(true);
  const [showOnlyFuture, setShowOnlyFuture] = useState(true);

  const [filteredIndyvs, setFilteredIndyvs] = useState([]);

  const handleFilterChange = (dataset, filterByDate, filterByRegistration) => {
    const dateStamp = new Date();
    const ds = dataset
      .filter((x) => filterByDate === false || compareAsc(new Date(x.startDate), dateStamp) === 1)
      .filter((x) => filterByRegistration === false || !isEmpty(x.userid))
      .sort((a, b) => (a.startDate > b.startDate ? 1 : -1));

    setFilteredIndyvs(ds);
  };

  const fetchData = async () => {
    try {
      await ConfigService.loadResources();
      const indyvs = await ScheduleService.getIndyvsCalendar(true);
      const sorted = indyvs.sort((a, b) => (a.startDate > b.startDate ? 1 : -1));
      setData(sorted);
      handleFilterChange(sorted, showOnlyFuture, showOnlyOccupied);
    } catch (error) {
      const msg = `Невдалося отримати данні з сервера для сторінки. ${error.message}`;
      ShowAlert(msg, AlertTypes.Error);
    } finally {
      setPageIsLoading(false);
    }
  };

  const formatExportJson = (exportData) =>
    // "Індивідуалка", "Дата", "Час", "Тренери", "Локація";
    exportData.map((record) => ({
      // id: record.id,
      Індивідуалка: isEmpty(record.useraccount)
        ? "Вільно"
        : record.title.replace("Індивідуалка: ", ""),
      Дата: format(new Date(record.startDate), "dd MMMM yyyy", { locale: ukLocale }),
      Час: `${format(new Date(record.startDate), "HH:mm", { locale: ukLocale })} - ${format(
        new Date(record.endDate),
        "HH:mm",
        { locale: ukLocale }
      )}`,
      Тренери: record.trainers
        .map((t) => `${ConfigService.getNamebyId(t, ConfigResources.Trainers)} `)
        .join("\n"),
      Локація: ConfigService.getNamebyId(record.locationid, ConfigResources.Locations),
      Оновлено: format(new Date(record.updatedAt), "dd MMMM yyyy, HH:mm", { locale: ukLocale }),
    }));

  const handleDeleteRecord = async (params) => {
    try {
      if (isEmpty(params.useraccount)) {
        // complete delete
        await ScheduleService.deleteCalendarEntry(params.id);
      } else {
        // cancel
        await ScheduleService.cancelIndyvEntry(params);
      }
      fetchData();
      ShowAlert("Видалено", AlertTypes.Success, true);
    } catch (error) {
      const msg = `Невдалося видалити запис. ${error.message}`;
      ShowAlert(msg, AlertTypes.Error);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      field: "actions",
      headerName: " ",
      width: 50,
      renderCell: (params) => (
        <div>
          <MDButton
            circular
            iconOnly
            color={isEmpty(params.row.useraccount) ? "dark" : "info"}
            variant="gradient"
            onClick={() => {
              ShowConfirmationAlert(
                "Видалити запис?",
                "Так, видалити!",
                handleDeleteRecord,
                params.row
              );
            }}
          >
            {isEmpty(params.row.useraccount) ? <DeleteForever /> : <Close />}
          </MDButton>
        </div>
      ),
    },
    {
      field: "useraccount",
      headerName: "Індивідуалка",
      width: 200,
      renderCell: (record) => (
        <div style={{ color: isEmpty(record.row.useraccount) ? "gray" : "black" }}>
          {isEmpty(record.row.useraccount)
            ? "Вільно"
            : record.row.title.replace("Індивідуалка: ", "")}
        </div>
      ),
    },
    {
      field: "startDate1",
      headerName: "Дата",
      width: 160,
      sortable: true,
      valueGetter: (record) =>
        format(new Date(record.row.startDate), "dd MMMM yyyy", { locale: ukLocale }),
    },
    {
      field: "startDate2",
      headerName: "Час",
      width: 130,
      valueGetter: (record) =>
        `${format(new Date(record.row.startDate), "HH:mm", { locale: ukLocale })} - ${format(
          new Date(record.row.endDate),
          "HH:mm",
          { locale: ukLocale }
        )}`,
    },
    {
      field: "Trainers",
      headerName: "Тренери",
      width: 130,
      valueGetter: (record) =>
        record.row.trainers
          .map((t) => `${ConfigService.getNamebyId(t, ConfigResources.Trainers)} `)
          .join("\n"),
    },
    {
      field: "locationid",
      headerName: "Локація",
      width: 200,
      valueGetter: (record) =>
        ConfigService.getNamebyId(record.row.locationid, ConfigResources.Locations),
    },
    {
      field: "updatedAt",
      headerName: "Оновлено",
      width: 200,
      valueGetter: (record) =>
        format(new Date(record.row.updatedAt), "dd MMMM yyyy, HH:mm", { locale: ukLocale }),
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {pageIsLoading ? (
        <div>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>
      ) : (
        <MDBox py={3}>
          <CustomCard
            title=""
            content={
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      exportToCSV(formatExportJson(filteredIndyvs), "Розклад Індивідуалок");
                    }}
                  >
                    <GetApp /> Експорт в ексель
                  </MDButton>
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showOnlyFuture}
                        onChange={(event) => {
                          setShowOnlyFuture(event.target.checked);
                          handleFilterChange(data, event.target.checked, showOnlyOccupied);
                        }}
                      />
                    }
                    label="Відображати тільки майбутні"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showOnlyOccupied}
                        onChange={(event) => {
                          setShowOnlyOccupied(event.target.checked);
                          handleFilterChange(data, showOnlyFuture, event.target.checked);
                        }}
                      />
                    }
                    label="Відображати тільки зайняті"
                  />
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      height: window.innerHeight < 850 ? 600 : window.innerHeight - 300,
                      width: "100%",
                    }}
                  >
                    <DataGrid rows={filteredIndyvs} columns={columns} />
                  </div>
                </Grid>
              </Grid>
            }
          />
        </MDBox>
      )}
    </DashboardLayout>
  );
}
