/* eslint-disable */
/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
import ScheduleService from "api/ScheduleService";
import React, { useState } from "react";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import { Modal, Skeleton, Autocomplete, Paper } from "@mui/material";
import CustomCard from "components/CustomCard";
import { addMonths, format } from "date-fns";
import { isEmpty } from "helpers/strhelper";
import ConfigService from "api/ConfigService";
import Calendar from "components/Calendar";
import MDInput from "components/MDInput";
import { RRule } from "rrule";
import UsersService from "api/UsersService";
import EventPreviewCard from "components/Calendar/EventPreviewCard";
import EventEditCard from "components/Calendar/EventEditCard";
import { ShowConfirmationAlert, AlertTypes, ShowAlert } from "components/Alerts";

export default function CalendarPage() {
  const [pageIsLoading, setPageIsLoading] = useState(true);

  const [locationsConfig, setLocationsConfig] = useState([]);
  const [trainingsConfig, setTrainingsConfig] = useState([]);
  const [trainersConfig, setTrainersConfig] = useState([]);
  const [groupsConfig, setGroupsConfig] = useState([]);
  const [users, setUsers] = useState([]);

  const [filterbygroup, setFilterbygroup] = useState(null);
  const [filterbytrainer, setFilterbytrainer] = useState(null);
  const [filterbytype, setFilterbytype] = useState(null);

  const [calendarDataset, setCalendarDataset] = useState([]);
  const [filteredDataset, setFilteredDataset] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openPreview, setOpenPreview] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [savingInProgres, setSavingInProgress] = useState(false);

  const handlePreviewOpen = (el) => {
    const prevElement = { ...el };

    prevElement.locationTitle =
      locationsConfig.find((x) => x.id === el.extendedProps.location)?.title ?? "";
    prevElement.trainingTitle =
      trainingsConfig.find((x) => x.id === el.extendedProps.training)?.title ?? "";
    prevElement.trainersTitle = el.extendedProps.trainers.map(
      (x) => trainersConfig.find((t) => t.id === x)?.title ?? ""
    );
    prevElement.groupsTitle = (el.extendedProps.groups ?? []).map(
      (x) => groupsConfig.find((t) => t.id === x)?.title ?? ""
    );
    prevElement.usersTitle = (el.extendedProps.users ?? []).map(
      (x) => users.find((t) => t.id === x)?.title ?? ""
    );

    setAnchorEl(prevElement);
    setOpenPreview(true);
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
    setAnchorEl(null);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
    setAnchorEl(null);
  };

  const handleOpenEdit = (el) => {
    setAnchorEl({
      id: el.id,
      start: el.start,
      end: el.end,
      title: el.title,
      extendedProps: el.extendedProps,
    });

    setOpenEdit(true);
  };

  const getClassName = (trainingType) => {
    switch (trainingType) {
      case "indyv":
        return "event-primary";
      case "icering":
        return "event-info";
      case "gym":
        return "event-secondary";
      default:
        return "event-info";
    }
  };

  const fetchDictionaryData = async () => {
    setLocationsConfig(await ConfigService.getResources("locations", true));
    setTrainingsConfig(await ConfigService.getResources("trainings", true));
    setTrainersConfig(await ConfigService.getResources("trainers", true));
    setGroupsConfig(await ConfigService.getResources("groups", true));

    const ulResponse = await UsersService.loadAll();
    // console.log("ulResponse", ulResponse);
    setUsers(
      ulResponse
        .map((u) => ({
          id: u.id,
          title: `${u.familyname} ${u.firstname}`,
        }))
        .sort((a, b) => (a.title > b.title ? 1 : -1))
    );
  };

  const fetchData = async () => {
    try {
      let response = [];
      let ds = [];

      response = await ScheduleService.getBauerCalendar();
      ds = response.map((x) => ({
        id: x.id,
        start: x.start,
        end: x.end,
        title: x.title,
        extendedProps: {
          location: x.location,
          training: x.training,
          trainers: x.trainers,
          groups: x.groups,
          users: x.users,
          availableSpots: x.availableSpots,
          availableFor: x.availableFor,
        },
        className: getClassName(x.training),
      }));

      setCalendarDataset(ds);
    } catch (error) {
      const msg = `Невдалося отримати данні з сервера для сторінки. ${error.message}`;
      ShowAlert(msg, AlertTypes.Error);
    } finally {
      setPageIsLoading(false);
    }
  };

  const filterDataset = (calendarDataset) => {
    if (calendarDataset) {
    
      return calendarDataset
      .filter(
        (r) =>
          // r.startDate >= "2023-04-01T12:00:00.000Z" &&
          (filterbygroup === null || (r.extendedProps.groups ?? []).includes(filterbygroup.id)) &&
          (filterbytype === null || r.extendedProps.training === filterbytype.id) &&
          (filterbytrainer === null || r.extendedProps.trainers.includes(filterbytrainer.id))
      );
    }
    return [];
  };

  const handleCreateEvents = async (events) => {
    // console.log("entries to create", events);

    for (let index = 0; index < events.length; index++) {
      const event = events[index];
      const eventToSubmit = {
        groupId: event.groupId,
        title: event.title,
        start: event.start,
        end: event.end,
        location: event.extendedProps.location,
        training: event.extendedProps.training,
        availableSpots: event.extendedProps.availableSpots,
        availableFor: event.extendedProps.availableFor,
        trainers: event.extendedProps.trainers,
        groups: event.extendedProps.groups,
        users: event.extendedProps.users,
      };
      eventToSubmit.ttl = Math.round(addMonths(new Date(), 2).valueOf() / 1000);

      await ScheduleService.createEvent(eventToSubmit);

      // await new Promise((r) => setTimeout(r, 2000));
      // console.log("event to create", JSON.stringify(eventToSubmit));
    }
  };

  const handleUpdateEvents = async (event) => {
    const eventToSubmit = {
      id: event.id,
      title: event.title,
      start: event.start,
      end: event.end,
      location: event.extendedProps.location,
      training: event.extendedProps.training,
      availableSpots: event.extendedProps.availableSpots,
      availableFor: event.extendedProps.availableFor,
      trainers: event.extendedProps.trainers,
      groups: event.extendedProps.groups,
      users: event.extendedProps.users,
    };
    eventToSubmit.ttl = Math.round(addMonths(new Date(), 2).valueOf() / 1000);

    await ScheduleService.updateEvent(eventToSubmit);
  };

  const handleDeleteEvent = async (event) => {
    await ScheduleService.deleteEvent({
      id: event.id,
      // start: event.start,
    });
    await fetchData();
  };

  const handleComitChange = async (e) => {
    try {
      // console.log("handleEventEdit", e);

      setSavingInProgress(true);

      const recurringDates = [];
      if (e.isRecurring) {
        const weekdays = [];
        if (e.recurringDays.mo) {
          weekdays.push(RRule.MO);
        }
        if (e.recurringDays.tu) {
          weekdays.push(RRule.TU);
        }
        if (e.recurringDays.we) {
          weekdays.push(RRule.WE);
        }
        if (e.recurringDays.th) {
          weekdays.push(RRule.TH);
        }
        if (e.recurringDays.fr) {
          weekdays.push(RRule.FR);
        }
        if (e.recurringDays.sa) {
          weekdays.push(RRule.SA);
        }
        if (e.recurringDays.su) {
          weekdays.push(RRule.SU);
        }
        // Create a rule:
        const rule = new RRule({
          freq: RRule.WEEKLY,
          byweekday: weekdays,
          dtstart: new Date(e.start),
          until: new Date(e.recurringEndDate),
        });

        // Get all occurrence dates (Date instances):
        recurringDates.push(...rule.all());
      }
      if (isEmpty(e.id) || e.id === "undefined") {
        if (e.isRecurring) {
          await handleCreateEvents(
            recurringDates.map((r) => ({
              title: e.title,
              start: `${format(r, "yyyy-MM-dd")}T${format(r, "HH:mm:00")}`,
              end: `${format(r, "yyyy-MM-dd")}T${format(new Date(e.end), "HH:mm:00")}`,
              extendedProps: e.extendedProps,
            }))
          );
        } else {
          await handleCreateEvents([
            {
              title: e.title,
              start: e.start,
              end: e.end,
              extendedProps: e.extendedProps,
            },
          ]);
        }
      } else {
        await handleUpdateEvents({
          id: e.id,
          title: e.title,
          start: e.start,
          end: e.end,
          extendedProps: e.extendedProps,
        });
      }
      await fetchData();
      setOpenEdit(false);
      setAnchorEl(null);
      // //refresh calendar
    } catch (error) {
      const errorMsg = `Невдалось Зберегти зміни. ${error}`;
      console.error("Помилка", error);
      ShowAlert(errorMsg, AlertTypes.Error);
    } finally {
      setSavingInProgress(false);
    }
  };

  const handleMoveEvents = async (newEvents) => {
    for (let index = 0; index < newEvents.length; index++) {
      const event = newEvents[index];
      const eventToSubmit = {
        id: event.id,
        title: event.title,
        start: event.start,
        end: event.end,
        location: event.extendedProps.location,
        training: event.extendedProps.training,
        availableSpots: event.extendedProps.availableSpots,
        availableFor: event.extendedProps.availableFor,
        trainers: event.extendedProps.trainers,
        groups: event.extendedProps.groups,
        users: event.extendedProps.users,
      };
      eventToSubmit.ttl = Math.round(addMonths(new Date(), 2).valueOf() / 1000);

      await ScheduleService.updateEvent(eventToSubmit);
    }

    await fetchData();
  };

  React.useEffect(() => {
    fetchDictionaryData();
    fetchData();
  }, []);

  React.useEffect(() => {
    setFilteredDataset(filterDataset(calendarDataset));
  }, [calendarDataset, filterbygroup, filterbytrainer, filterbytype]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {pageIsLoading && (
        <div>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>
      )}
      {!pageIsLoading && (
        <MDBox py={3}>
          {/* calendar section */}
          <CustomCard
            title=""
            content={
              <div>
                <Grid container spacing={2}>
                  {/* select groups */}
                  <Grid item xs={12} sm={3} md={3}>
                    <Autocomplete
                      fullWidth
                      disablePortal
                      id="group-select"
                      options={groupsConfig}
                      value={filterbygroup}
                      getOptionLabel={(option) => option.title}
                      renderInput={(params) => <MDInput {...params} label="Фільтр По Групах" />}
                      onChange={(event, newValue) => {
                        setFilterbygroup(newValue);
                      }}
                    />
                  </Grid>

                  {/* select trainers */}
                  <Grid item xs={12} sm={3} md={3}>
                    <Autocomplete
                      fullWidth
                      disablePortal
                      id="trainer-select"
                      options={trainersConfig}
                      value={filterbytrainer}
                      getOptionLabel={(option) => option.title}
                      renderInput={(params) => <MDInput {...params} label="Фільтр По Тренерах" />}
                      onChange={(event, newValue) => {
                        setFilterbytrainer(newValue);
                      }}
                    />
                  </Grid>

                  {/* select traini type */}
                  <Grid item xs={12} sm={3} md={3}>
                    <Autocomplete
                      fullWidth
                      disablePortal
                      id="type-select"
                      options={trainingsConfig}
                      value={filterbytype}
                      getOptionLabel={(option) => option.title}
                      renderInput={(params) => (
                        <MDInput {...params} label="Фільтр По Тренуваннях" />
                      )}
                      onChange={(event, newValue) => {
                        setFilterbytype(newValue);
                      }}
                    />
                  </Grid>
                </Grid>
                {/* <Box style={{ width: "100%" }}> */}
                <Paper>
                  <Calendar
                    // key={syncDate}
                    initialView="timeGridWeek"
                    eventTimeFormat={{
                      // like '14:30:00'
                      hour: "2-digit",
                      minute: "2-digit",
                      meridiem: false,
                      hour12: false,
                    }}
                    allDaySlot={false}
                    // initialView="dayGridDay"
                    weekends="true"
                    firstDay={1}
                    slotMinTime="06:00:00"
                    slotMaxTime="24:00:00"
                    editable
                    eventStartEditable
                    eventDurationEditable
                    datasource={filteredDataset}
                    locations={locationsConfig}
                    trainers={trainersConfig}
                    trainings={trainingsConfig}
                    groups={groupsConfig}
                    users={users}
                    onCreate={(entries) => handleCreateEvents(entries)}
                    onUpdate={(entries) => handleUpdateEvents(entries)}
                    onMove={(newEntries, oldEntries) => handleMoveEvents(newEntries, oldEntries)}
                    onDelete={(event) => handleDeleteEvent(event)}
                    onPreview={(event) => handlePreviewOpen(event)}
                    onEdit={(e) => handleComitChange(e)}
                    onDateClick={(e) => handleOpenEdit(e)}
                  />
                </Paper>
                <Modal open={openPreview} onClose={handleClosePreview} disableAutoFocus>
                  <div>
                    <EventPreviewCard
                      event={anchorEl}
                      onEdit={(e) => {
                        setOpenPreview(false);
                        handleOpenEdit(e);
                      }}
                      onCopy={(e) => {
                        setOpenPreview(false);

                        setAnchorEl({ ...e, id: null, groupId: null });
                        setOpenEdit(true);
                      }}
                      onDelete={(e) => {
                        setOpenPreview(false);
                        ShowConfirmationAlert("Видалити подію?", "Так!", handleDeleteEvent, e);
                      }}
                    />
                  </div>
                </Modal>
                <Modal open={openEdit} onClose={handleCloseEdit} disableAutoFocus>
                  <div>
                    <EventEditCard
                      saving={savingInProgres}
                      eventEntry={anchorEl}
                      locations={locationsConfig}
                      trainings={trainingsConfig}
                      trainers={trainersConfig}
                      groups={groupsConfig}
                      users={users}
                      onEdit={handleComitChange}
                      onCancel={handleCloseEdit}
                    />
                  </div>
                </Modal>
              </div>
            }
          />
        </MDBox>
      )}
    </DashboardLayout>
  );
}
