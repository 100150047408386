import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import PropTypes from "prop-types";

function CustomCard({ title, iconName, content }) {
  return (
    <Card sx={{ width: "100%" }}>
      <MDBox display="flex">
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          variant="gradient"
          bgColor="success"
          color="white"
          shadow="md"
          borderRadius="xl"
          ml={3}
          mt={-2}
        >
          <Icon fontSize="medium" color="inherit">
            {iconName}
          </Icon>
        </MDBox>
        <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
          {title}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>{content}</MDBox>
    </Card>
  );
}

CustomCard.defaultProps = {
  iconName: "info",
  content: <div />,
};

CustomCard.propTypes = {
  iconName: PropTypes.string,
  title: PropTypes.string.isRequired,
  content: PropTypes.element,
};
export default CustomCard;
