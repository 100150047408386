/* eslint-disable */
import { useState } from "react";
import PropTypes from "prop-types";

import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import PhoneInput from "react-phone-input-2";
import CustomCard from "components/CustomCard";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import AccountService from "api/AccountService";
import { ShowAlert, AlertTypes } from "components/Alerts";

import "react-phone-input-2/lib/material.css";
import MDTypography from "components/MDTypography";
import ConfigService from "api/ConfigService";
import { ConfigResources } from "api/ConfigService";

function EditAccountForm({ context, onSubmit, onCancel }) {
  const [editEmail, setEditEmail] = useState(context.email);
  const [editName, setEditName] = useState(context.first_name);
  const [editFamilyName, setEditFamilyName] = useState(context.family_name);
  const [editPhone, setEditPhone] = useState(context.phone);
  const [editAddress, setEditAddress] = useState(context.address);
  const [editZoneInfo, setEditZoneInfo] = useState(context.zoneInfo);
  const [editTempPass, setEditTempPass] = useState(context.tempPass);
  const [editGroups, setEditGoups] = useState(context.accountGroups ?? []);

  const handleAddGroup = async (e) => {
    try {
      await AccountService.assignUserToGroup(editEmail, e);
    } catch (error) {
      const msg = "Невдалося видалити користувача з групи";
      ShowAlert(msg, AlertTypes.Error);
    }
  };

  const handleDeleteGroup = async (e) => {
    try {
      await AccountService.removeUserFromGroup(editEmail, e);
    } catch (error) {
      const msg = "Невдалося видалити користувача з групи";
      ShowAlert(msg, AlertTypes.Error);
    }
  };

  const getTitle = (name) => {
    let value = "";
    switch (name) {
      case "trainers":
        value = "Тренери";
        break;
      case "groups":
        value = "Групи";
        break;
      case "locations":
        value = "Локації";
        break;
      case "trainings":
        value = "Тренування";
        break;
      default:
        break;
    }
    return value;
  };

  const handleChangeGroup = async (e, group) => {
    if (e) {
      handleAddGroup(group);
      setEditGoups([...editGroups, group]);
    } else {
      handleDeleteGroup(group);
      setEditGoups([...editGroups.filter((x) => x !== group)]);
    }
    if (group === "Trainers") {
      const trainersConfig = await ConfigService.getResources(ConfigResources.Trainers, true);
      console.log("trainersConfig", trainersConfig);
      console.log("group", group);
      const settingsToUpdate = {
        id: ConfigResources.Trainers,
        title: getTitle(ConfigResources.Trainers),
      };
      if (e) {
        settingsToUpdate.values = [
          ...trainersConfig,
          { id: context.sub, title: `${context.family_name} ${context.first_name}` },
        ];
      } else {
        settingsToUpdate.values = (trainersConfig ?? []).filter((x) => x.id !== context.sub);
      }
     
      await ConfigService.updateConfiguration(settingsToUpdate);
    }
  };

  return (
    <MDBox>
      <MDBox style={{ paddingBottom: 30 }}>
        <CustomCard
          title={`Редагування Профайлу (id: ${context.id})`} // eslint-disable-line
          iconName="person"
          content={
            <Grid container alignItems="center">
              <Grid item xs={10} md={8} style={{ padding: 10 }}>
                <PhoneInput
                  specialLabel="Телефон"
                  country="ua"
                  inputStyle={{ maxWidth: "100%" }}
                  containerStyle={{ marginTop: 0 }}
                  value={editPhone}
                  onChange={(phone) => setEditPhone(phone)}
                  inputProps={{ name: "input_phone" }}
                />
              </Grid>
              <Grid item xs={10} md={8} style={{ padding: 10 }}>
                <MDInput
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  label="Email"
                  value={editEmail}
                  onChange={(event) => {
                    setEditEmail(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={10} md={8} style={{ padding: 10 }}>
                <MDInput
                  fullWidth
                  label="Ім'я"
                  value={editName}
                  onChange={(event) => {
                    setEditName(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={10} md={8} style={{ padding: 10 }}>
                <MDInput
                  fullWidth
                  label="Прізвище"
                  value={editFamilyName}
                  onChange={(event) => {
                    setEditFamilyName(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={10} md={8} style={{ padding: 10 }}>
                <MDInput
                  fullWidth
                  label="Адреса"
                  value={editAddress}
                  onChange={(event) => {
                    setEditAddress(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={10} md={8} style={{ padding: 10 }}>
                <MDInput
                  fullWidth
                  label="Посада"
                  value={editZoneInfo}
                  onChange={(event) => {
                    setEditZoneInfo(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={10} md={8} style={{ padding: 10 }}>
                <form>
                  <MDInput
                    fullWidth
                    type="password"
                    inputProps={{
                      autoComplete: "new-password",
                    }}
                    label="Пароль"
                    value={editTempPass ?? ""}
                    onChange={(event) => {
                      setEditTempPass(event.target.value);
                    }}
                  />
                </form>
                <MDTypography variant="overline" pl={1}>
                  Мінімальна довжина 8 символів
                </MDTypography>
              </Grid>
            </Grid>
          }
        />
      </MDBox>
      <MDBox style={{ paddingBottom: 10 }}>
        <CustomCard
          style={{ marginTop: 25 }}
          title="Редагування Груп"
          iconName="group"
          content={
            <Grid container alignItems="center" style={{ width: "100%" }}>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!editGroups.find((x) => x === "Admins")}
                        onChange={(e) => handleChangeGroup(e.target.checked, "Admins")}
                      />
                    }
                    label="Адміністратор"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!editGroups.find((x) => x === "Trainers")}
                        onChange={(e) => handleChangeGroup(e.target.checked, "Trainers")}
                      />
                    }
                    label="Тренер"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!editGroups.find((x) => x === "Users")}
                        onChange={(e) => handleChangeGroup(e.target.checked, "Users")}
                      />
                    }
                    label="Користувач"
                  />
                </FormGroup>
              </Grid>
            </Grid>
          }
        />
      </MDBox>
      <Grid container alignItems="center">
        <Grid item xs={12} style={{ padding: 5 }}>
          <MDButton
            style={{ marginRight: 10 }}
            variant="gradient"
            color="info"
            onClick={() => {
              onSubmit({
                phone: editPhone,
                email: editEmail,
                name: editName,
                familyName: editFamilyName,
                address: editAddress,
                zoneInfo: editZoneInfo,
                newPass: editTempPass,
              });
            }}
          >
            Зберегти
          </MDButton>
          <MDButton
            variant="gradient"
            color="dark"
            onClick={() => {
              onCancel();
            }}
          >
            Відміна
          </MDButton>
        </Grid>
      </Grid>
    </MDBox>
  );
}

EditAccountForm.defaultProps = {};

EditAccountForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  context: PropTypes.object.isRequired,
};

export default EditAccountForm;
