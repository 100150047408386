import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { ShowAlert, AlertTypes } from "components/Alerts";

import Grid from "@mui/material/Grid";
import { Skeleton } from "@mui/material";

import { useEffect, useState } from "react";

import CustomCard from "components/CustomCard";
import { Auth } from "aws-amplify";
import PhoneInput from "react-phone-input-2";
import MDInput from "components/MDInput";
import { isEmpty } from "helpers/strhelper";

export default function ProfilePage() {
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [, setRequestInProgress] = useState(false);

  const [editPhone, setEditPhone] = useState("");
  const [editEmail, setEditEmail] = useState("");
  const [editName, setEditName] = useState("");
  const [editFamilyName, setEditFamilyName] = useState("");
  const [editAddress, setEditAddress] = useState("");
  const [editZoneInfo, setEditZoneInfo] = useState("");

  const handleSaveChanges = async () => {
    try {
      setRequestInProgress(true);

      const attributesToUpdate = {};
      if (!isEmpty(editName)) {
        attributesToUpdate.name = editName;
      }
      if (!isEmpty(editFamilyName)) {
        attributesToUpdate.family_name = editFamilyName;
      }
      if (!isEmpty(editAddress)) {
        attributesToUpdate.address = editAddress;
      }

      if (!isEmpty(editPhone)) {
        attributesToUpdate.phone_number = `+${editPhone}`;
      }

      const profileRecord = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(profileRecord, attributesToUpdate);

      ShowAlert("Зміни Збережено", AlertTypes.Success, true);
    } catch (error) {
      const msg = "Виникла помилка при обробці запиту";
      ShowAlert(msg, AlertTypes.Error);
    } finally {
      setRequestInProgress(false);
    }
  };

  const fetchData = async () => {
    try {
      const profileRecord = await Auth.currentAuthenticatedUser();
      setEditEmail(profileRecord.attributes.email);
      setEditPhone(profileRecord.attributes.phone_number);
      setEditName(profileRecord.attributes.name);
      setEditFamilyName(profileRecord.attributes.family_name);
      setEditAddress(profileRecord.attributes.address);
      setEditZoneInfo(profileRecord.attributes.zoneinfo);
    } catch (error) {
      const msg = "Невдалося отримати данні з сервера для сторінки";
      ShowAlert(msg, AlertTypes.Error);
    } finally {
      setPageIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {pageIsLoading ? (
          <div>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
        ) : (
          <div>
            <MDBox style={{ marginBottom: 15 }}>
              <CustomCard
                title="Редагування Профайлу"
                iconName="person"
                content={
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={10} md={8}>
                      <PhoneInput
                        specialLabel="Телефон"
                        country="ua"
                        inputStyle={{ maxWidth: "100%" }}
                        containerStyle={{ marginTop: 0 }}
                        value={editPhone}
                        onChange={(phone) => setEditPhone(phone)}
                        inputProps={{ name: "input_phone" }}
                      />
                    </Grid>
                    <Grid item xs={10} md={8}>
                      <MDInput
                        fullWidth
                        label="Email"
                        value={editEmail}
                        onChange={(event) => {
                          setEditEmail(event.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={10} md={8}>
                      <MDInput
                        fullWidth
                        label="Ім'я"
                        value={editName}
                        onChange={(event) => {
                          setEditName(event.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={10} md={8}>
                      <MDInput
                        fullWidth
                        label="Прізвище"
                        value={editFamilyName}
                        onChange={(event) => {
                          setEditFamilyName(event.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={10} md={8}>
                      <MDInput
                        fullWidth
                        label="Адреса"
                        value={editAddress}
                        onChange={(event) => {
                          setEditAddress(event.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={10} md={8}>
                      <MDInput
                        fullWidth
                        label="Посада"
                        value={editZoneInfo}
                        onChange={(event) => {
                          setEditZoneInfo(event.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                }
              />
            </MDBox>
            <Grid container alignItems="center">
              <Grid item xs={12}>
                <MDButton
                  style={{ marginRight: 10, width: 125 }}
                  variant="gradient"
                  color="info"
                  onClick={handleSaveChanges}
                >
                  Зберегти
                </MDButton>
                <MDButton
                  variant="gradient"
                  color="dark"
                  style={{ width: 125 }}
                  onClick={() => {
                    fetchData();
                    ShowAlert("Зміни Відмінено", AlertTypes.Success);
                  }}
                >
                  Відміна
                </MDButton>
              </Grid>
            </Grid>
          </div>
        )}
      </MDBox>
    </DashboardLayout>
  );
}
