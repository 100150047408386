const SequencesLevels = [
  {
    Code: "Base",
    Name: "Базовий",
    Description: "Менше п’яти складних поворотів та кроків",
  },
  {
    Code: "1",
    Name: "Перший",
    Description: "П’ять складних поворотів та кроків",
  },
  {
    Code: "2",
    Name: "Другий",
    Description: "Сім складних поворотів та кроків",
  },
  {
    Code: "3",
    Name: "Третій",
    Description: "Дев’ять складних поворотів та кроків",
  },
  {
    Code: "4",
    Name: "Четвертий",
    Description:
      "Одинадцять складних поворотів та кроків. П’ять типів складних поворотів та кроків мають бути виконані в обидві сторони.",
  },
];

const SequencesElements = [
  { code: "StSq", name: "Доріжка кроків" },
  { code: "ChSq", name: "Хореографічна послідовність" },
];

const GetSequencesDescription = (rcode, levelcode) => {
  const el = SequencesElements.find((x) => x.code === rcode);
  const level = SequencesLevels.find((l) => l.Code === (levelcode ?? ""));
  return el ? `${el.name}${level ? ` (${level.Name} рівень)` : ""}` : "";
};

export { SequencesElements, GetSequencesDescription, SequencesLevels };
