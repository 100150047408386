/* eslint-disable */
import { Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import { useState } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
/* eslint-disable */
export default function MDSelect({ label, dataset, disabled, ...rest }) {
  

  

  return (
    <FormControl sx={{ width: 300 }} disabled={disabled}>
      <InputLabel id="demo-multiple-name-label">{label}</InputLabel>
      <Select
        {...rest}
        input={<OutlinedInput label="Chip" sx={{height: 48}}/>}
        // renderValue={(selected) => (
        //   <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
        //     {multiple ? (selected.map((value) => (
        //       <Chip key={value} label={value} />
        //     ))) : (<Chip key={value} label={value} />)}
        //   </Box>
        // )}
        MenuProps={MenuProps}
      >
        {(dataset ??
          []).map((x) => (
            <MenuItem key={x.id} value={x.id}>
              {x.title}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
