import Analytics from "layouts/dashboards/analytics";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import Birthdays from "layouts/dashboards/birthdays";
import CalendarPage from "layouts/schedule/CalendarPage";
import SignOut from "layouts/authentication/sign-out";
import ChangePasswordPage from "layouts/authentication/change-password";
import IndyvsListPage from "layouts/schedule/IndyvsListPage";
import VideosPage from "layouts/media/VideosPage";
import RequestFormsPage from "layouts/requests/RequestFormsPage";
import ContactUsPage from "layouts/requests/ContactUsPage";
import AccountsPage from "layouts/users/AccountsPage";
import UsersPage from "layouts/users/UsersPage";
import MainPageSettings from "layouts/settings/pages/MainPageSettings";
import HomePageSettings from "layouts/settings/pages/HomePageSettings";
import GeneralPageSettings from "layouts/settings/pages/GeneralPageSettings";
import SupportUsPageSettings from "layouts/settings/pages/SupportUsPageSettings";
import SectionWithCoverImageSettings from "layouts/settings/sections/SectionWithCoverImageSettings";
import SectionTeamSettings from "layouts/settings/sections/SectionTeamSettings";
import SectionWithImagesSettings from "layouts/settings/sections/SectionWithImagesSettings";
import SectionGeneralSettings from "layouts/settings/sections/SectionGeneralSettings";
import ProfilePage from "layouts/users/ProfilePage";
import TestingResultsPage from "layouts/testing/TestingResultsPage";
import { ConfigResources } from "api/ConfigService";
import SettingsPage from "layouts/settings/general/SettingsPage";
import TestingSettings from "layouts/settings/testing";
import ISUTestingResultsPage from "layouts/testing/IsuTestingResultsPage";
// import VideosPage from "layouts/media/VideosPage";

const routes = [
  {
    type: "collapse",
    name: "user_profile",
    key: "user_profile",
    icon: <Icon fontSize="medium">account_circle</Icon>,
    collapse: [
      {
        name: "Мій Профайл",
        key: "Мій Профайл",
        route: "/pages/profile/profile-overview",
        component: <ProfilePage />,
      },
      {
        name: "Змінити Пароль",
        key: "Змінити Пароль",
        icon: <Icon fontSize="medium">vpn_key</Icon>,
        route: "/auth/changepassword",
        component: <ChangePasswordPage />,
      },
      {
        name: "Вийти з Системи",
        key: "Вийти з Системи",
        route: "/auth/sign-out",
        icon: <Icon fontSize="medium">exit_to_app</Icon>,
        component: <SignOut />,
      },
    ],
  },
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Дашборди",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Звіти",
        key: "Звіти",
        route: "/dashboards/analytics",
        component: <Analytics />,
      },
      {
        name: "Дні Народження",
        key: "Дні Народження",
        route: "/dashboards/birthdays",
        component: <Birthdays />,
      },
    ],
  },
  { type: "title", title: "Сторінки", key: "title-pages" },
  {
    type: "collapse",
    name: "Розклад",
    key: "Розклад",
    icon: <Icon fontSize="medium">event</Icon>,
    collapse: [
      {
        name: "Розклад Занять",
        key: "Розклад Занять",
        route: "/schedule/calendar",
        component: <CalendarPage />,
      },
      {
        name: "Журнал Індивідуалок",
        key: "Журнал Індивідуалок",
        route: "/schedule/indyvs",
        component: <IndyvsListPage />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Медіа Бібліотека",
    key: "Медіа Бібліотека",
    icon: <Icon fontSize="medium">video_library</Icon>,
    collapse: [
      {
        name: "Відео",
        key: "відео",
        route: "/media/videos",
        component: <VideosPage />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Зворотній Звязок",
    key: "Зворотній Звязок",
    icon: <Icon fontSize="medium">contact_mail</Icon>,
    collapse: [
      {
        name: "Заявки на Пробне",
        key: "Заявки на Пробне",
        route: "/requests/new-requests",
        component: <RequestFormsPage />,
      },
      {
        name: "Зворотній Звязок",
        key: "Зворотній Звязок",
        route: "/requests/contactus",
        component: <ContactUsPage />,
      },
    ],
  },
  { type: "divider", key: "divider-testing" },
  { type: "title", title: "Тестування", key: "Тестування-settings" },
  {
    type: "collapse",
    name: "Тестування",
    key: "Тестування",
    icon: <Icon fontSize="medium">quiz</Icon>,
    collapse: [
      {
        name: "Внутрішнє",
        key: "Внутрішнє Тестування",
        route: "/testing/results",
        component: <TestingResultsPage />,
      },
      {
        name: "ISU",
        key: "ISU Тестування",
        route: "/testing/isuresults",
        component: <ISUTestingResultsPage />,
      },
    ],
  },
  { type: "divider", key: "divider-1" },
  { type: "title", title: "Аккаунти", key: "аккаунти-settings" },
  {
    type: "collapse",
    name: "Аккаунти",
    key: "Аккаунти",
    icon: <Icon fontSize="medium">group</Icon>,
    collapse: [
      {
        name: "Користувачі",
        key: "Користувачі",
        icon: <Icon fontSize="medium">group</Icon>,
        route: "/users/accounts",
        component: <AccountsPage />,
      },
      {
        name: "Спортсмени",
        key: "Спортсмени",
        icon: <Icon fontSize="medium">group</Icon>,
        route: "/users/users",
        component: <UsersPage />,
      },
    ],
  },
  { type: "divider", key: "divider-2" },
  { type: "title", title: "Налаштування", key: "title-settings" },
  {
    type: "collapse",
    name: "Загальні",
    key: "Загальні",
    icon: <Icon fontSize="medium">settings</Icon>,
    collapse: [
      {
        name: "Загальні",
        key: "Загальні",
        route: "/settings/general",
      },
      {
        name: "Групи",
        key: "Групи",
        route: "/settings/groups",
        component: <SettingsPage settingsName={ConfigResources.Groups} />,
      },
      {
        name: "Локації",
        key: "Локації",
        route: "/settings/locations",
        component: <SettingsPage settingsName={ConfigResources.Locations} />,
      },
      {
        name: "Тренери",
        key: "Тренери",
        route: "/settings/coaches",
        component: <SettingsPage settingsName={ConfigResources.Trainers} />,
      },
      {
        name: "Спортивні Категорії",
        key: "Спортивні Категорії",
        route: "/settings/sportcategoies",
        component: <SettingsPage settingsName={ConfigResources.SportCategories} />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Сторінки",
    key: "Сторінки",
    icon: <Icon fontSize="medium">settings</Icon>,
    collapse: [
      {
        name: "Головна",
        key: "Головна",
        route: "/settings/pages/main",
        component: <MainPageSettings />,
      },
      {
        name: "Домашня",
        key: "Домашня",
        route: "/settings/pages/home",
        component: <HomePageSettings />,
      },
      {
        name: "Події",
        key: "Події",
        route: "/settings/pages/events",
        component: <GeneralPageSettings />,
      },
      {
        name: "Блог-Статті",
        key: "Блог-Статті",
        route: "/settings/pages/blogarticles",
        component: <GeneralPageSettings />,
      },
      {
        name: "Блог-Відео",
        key: "Блог-Відео",
        route: "/settings/pages/blogvideos",
        component: <GeneralPageSettings />,
      },
      {
        name: "Ціни",
        key: "Ціни",
        route: "/settings/pages/prices",
        component: <GeneralPageSettings />,
      },
      {
        name: "Підтримка Школи",
        key: "Підтримка Школи",
        collapse: [
          {
            name: "Підтримка Школи",
            key: "Підтримка Школи",
            route: "/settings/pages/supportus",
            component: <SupportUsPageSettings />,
          },
          {
            name: "Разова Підтримка",
            key: "Разова Підтримка",
            route: "/settings/pages/donatenow",
            component: <GeneralPageSettings />,
          },
        ],
      },
    ],
  },
  {
    type: "collapse",
    name: "Секції Сторінок",
    key: "Секції Сторінок",
    icon: <Icon fontSize="medium">settings</Icon>,
    collapse: [
      {
        name: "Відео",
        key: "Відео",
        route: "/settings/sections/mainvideosection",
        component: <SectionGeneralSettings />,
      },
      {
        name: "Спортивні Групи",
        key: "Спортивні Групи",
        route: "/settings/sections/groupssection",
        component: <SectionWithImagesSettings />,
      },
      {
        name: "Додаткові Послуги",
        key: "Додаткові Послуги",
        route: "/settings/sections/servicessection",
        component: <SectionWithCoverImageSettings />,
      },
      {
        name: "Наша Команда",
        key: "Наша Команда",
        route: "/settings/sections/teamsection",
        component: <SectionTeamSettings />,
      },
      {
        name: "Ціни",
        key: "Ціни",
        route: "/settings/sections/pricessection",
        component: <SectionWithImagesSettings />,
      },
      {
        type: "collapse",
        name: "Секції Підтримка",
        key: "Секції Підтримка",
        icon: <Icon fontSize="medium">view_in_ar</Icon>,
        collapse: [
          {
            name: "Підтримка",
            key: "Підтримка",
            route: "/settings/sections/support",
            component: <SectionWithImagesSettings />,
          },
          {
            name: "Цінності",
            key: "Цінності",
            route: "/settings/sections/vision",
            component: <SectionWithImagesSettings />,
          },
        ],
      },
    ],
  },
  {
    type: "collapse",
    name: "ISU Тестування",
    key: "ISU Тестування",
    icon: <Icon fontSize="medium">settings</Icon>,
    collapse: [
      {
        name: "Тестування",
        key: "Відео",
        route: "/settings/testing",
        component: <TestingSettings />,
      },
    ],
  },
];

export default routes;
