/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import ReactPlayer from "react-player";
import "assets/reactplayer.css";

function SimpleVideoCard({ url, title, category, description }) {
  return (
    <Card>
      <MDBox position="relative" borderRadius="lg" mt={-3} mx={2} style={{ textAlign: "center" }}>
        <div style={{ width: 400 }}>
          <div className="player-wrapper">
            <ReactPlayer
              url={url}
              light=""
              width="100%"
              height="100%"
              className="react-player"
              controls
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                  },
                },
              }}
            />
          </div>
        </div>
        <MDBox
          borderRadius="lg"
          shadow="md"
          width="100%"
          height="100%"
          position="absolute"
          left={0}
          top="3%"
          sx={{
            backgroundImage: `url('https://media.bauer.school/static/main_theme_bg.jpg`,
            transform: "scale(0.94)",
            filter: "blur(12px)",
            backgroundSize: "cover",
          }}
        />
      </MDBox>
      <MDBox p={3}>
        <MDTypography display="inline" variant="h3" textTransform="capitalize" fontWeight="bold">
          {title}
        </MDTypography>
        <MDTypography variant="h6" textTransform="capitalize">
          {category}
        </MDTypography>
        <MDBox mt={2} mb={3}>
          <MDEditor fullWidth value={description} readOnly theme="bubble" />
        </MDBox>
      </MDBox>
    </Card>
  );
}

SimpleVideoCard.defaultProps = {
  category: "",
};

SimpleVideoCard.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  category: PropTypes.string,
  description: PropTypes.string.isRequired,
};

export default SimpleVideoCard;
