/* eslint-disable react/forbid-prop-types */
import React, { useState } from "react";
import PropTypes from "prop-types";

import CustomCard from "components/CustomCard";
import { Autocomplete, Divider, Grid, Tooltip } from "@mui/material";

import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { JumpElements, GetJumpDescription } from "isu/Elements/JumpElements";
import { TestingMarks } from "isu/Marks";
import { RotatingElements, GetRotationDescription } from "isu/Elements/RotatingElements";
import { SequencesElements, GetSequencesDescription } from "isu/Elements/SequenceElements";
import format from "date-fns/format";
import ukLocale from "date-fns/locale/uk";
import { CalculateGOE, GetElementBaseValue } from "isu/ScoringRules";

const uuid = require("uuid");

const JumpLevels = ["1", "2", "3", "4"];
const RotationLevels = ["B", "1", "2", "3", "4"];
export default function IsuEditForm({ user, ts, onCancel, onSubmit }) {
  const [jumps, setJumps] = useState([]);
  const [baseSpins, setBaseSpins] = useState([]);
  const [complexSpins, setComplexSpins] = useState([]);
  const [programSpins, setProgramSpins] = useState([]);

  const [sequences, setSequences] = useState([]);

  const changeElement = (t, elements, code, newSelected, newLevel, newMark, j1, j2, j3) => {
    const updatedItems = [...elements];
    const updateIndex = updatedItems.findIndex((x) => x.code === code);
    updatedItems[updateIndex].selected = newSelected;
    if (newSelected === true) {
      const baseValue = GetElementBaseValue(t === "jumps" ? newLevel + code : code + newLevel);
      const goeValue = CalculateGOE(baseValue, j1, j2, j3);
      updatedItems[updateIndex] = {
        code,
        selected: true,
        level: newLevel,
        mark: newMark,
        j1,
        j2,
        j3,
        bsv: parseFloat(baseValue),
        goe: parseFloat(goeValue),
      };
    } else {
      updatedItems[updateIndex] = {
        code,
        selected: false,
        level: "1",
        mark: "",
        j1: 0,
        j2: 0,
        j3: 0,
      };
    }
    return updatedItems;
  };

  const handleChangeJumps = (code, newSelected, newLevel, newMark, j1, j2, j3) => {
    setJumps(changeElement("jumps", jumps, code, newSelected, newLevel, newMark, j1, j2, j3));
  };

  const handleChangeRotations = (type, code, newSelected, newLevel, newMark, j1, j2, j3) => {
    switch (type) {
      case "base":
        setBaseSpins(
          changeElement("rotation", baseSpins, code, newSelected, newLevel, newMark, j1, j2, j3)
        );
        break;
      case "program":
        setProgramSpins(
          changeElement("rotation", programSpins, code, newSelected, newLevel, newMark, j1, j2, j3)
        );
        break;
      case "complex":
        setComplexSpins(
          changeElement("rotation", complexSpins, code, newSelected, newLevel, newMark, j1, j2, j3)
        );
        break;
      default:
        break;
    }
  };

  const handleChangeSequences = (code, newSelected, newLevel, newMark, j1, j2, j3) => {
    setSequences(
      changeElement("sequences", sequences, code, newSelected, newLevel, newMark, j1, j2, j3)
    );
  };

  const handleSaveClick = () => {
    const jumpsToSave = jumps
      .filter((x) => x.selected === true)
      .map((y) => ({
        code: y.code,
        mark: y.mark,
        level: y.level,
        j1: y.j1,
        j2: y.j2,
        j3: y.j3,
        bsv: y.bsv,
        goe: y.goe,
      }));

    const baseSpinsToSave = baseSpins
      .filter((x) => x.selected === true)
      .map((y) => ({
        code: y.code,
        mark: y.mark,
        level: y.level,
        j1: y.j1,
        j2: y.j2,
        j3: y.j3,
        bsv: y.bsv,
        goe: y.goe,
      }));

    const complexSpinsToSave = complexSpins
      .filter((x) => x.selected === true)
      .map((y) => ({
        code: y.code,
        mark: y.mark,
        level: y.level,
        j1: y.j1,
        j2: y.j2,
        j3: y.j3,
        bsv: y.bsv,
        goe: y.goe,
      }));

    const programSpinsToSave = programSpins
      .filter((x) => x.selected === true)
      .map((y) => ({
        code: y.code,
        mark: y.mark,
        level: y.level,
        j1: y.j1,
        j2: y.j2,
        j3: y.j3,
        bsv: y.bsv,
        goe: y.goe,
      }));

    const sequencesToSave = sequences
      .filter((x) => x.selected === true)
      .map((y) => ({
        code: y.code,
        mark: y.mark,
        level: y.level,
        j1: y.j1,
        j2: y.j2,
        j3: y.j3,
        bsv: y.bsv,
        goe: y.goe,
      }));

    const newResults = {
      id: ts ? ts.id : uuid.v1(),
      accountid: user.accountid,
      profileid: user.id,
      jumps: jumpsToSave,
      baseSpins: baseSpinsToSave,
      complexSpins: complexSpinsToSave,
      programSpins: programSpinsToSave,
      sequences: sequencesToSave,
      updateDate: new Date().toISOString(),
      createDate: new Date().toISOString(),
    };
    onSubmit(newResults);
  };

  const renderElement = (element, descr, levelOptions, levelTitle, onElementChange) => (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Tooltip title={descr}>
          <MDButton
            size="small"
            variant={element.selected ?? false ? "gradient" : "outlined"}
            color="info"
            style={{ width: 100, marginRight: 5 }}
            onClick={() => {
              onElementChange(
                element.code,
                !element.selected,
                element.level,
                element.mark,
                element.j1,
                element.j2,
                element.j3
              );
            }}
          >
            {element.code}
          </MDButton>
        </Tooltip>
        <Autocomplete
          size="small"
          sx={{ display: "inline-block", mr: 0.5 }}
          options={levelOptions}
          renderInput={(params) => <MDInput {...params} label={levelTitle} />}
          getOptionLabel={(option) => `${option}`}
          value={element.level ?? ""}
          onChange={(event, newValue) => {
            onElementChange(
              element.code,
              true,
              newValue,
              element.mark,
              element.j1,
              element.j2,
              element.j3
            );
          }}
        />
        <Autocomplete
          size="small"
          style={{ display: "inline-block", width: 150 }}
          options={[TestingMarks.A, TestingMarks.B, TestingMarks.C, TestingMarks.D]}
          renderInput={(params) => <MDInput {...params} label="Внутрішня Оцінка" />}
          getOptionLabel={(option) => `${option}`}
          value={element.mark ?? ""}
          onChange={(event, newValue) => {
            onElementChange(
              element.code,
              true,
              element.level,
              newValue,
              element.j1,
              element.j2,
              element.j3
            );
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <MDTypography variant="caption">BV: {element.bsv} | </MDTypography>
        <MDTypography variant="caption">GOE: {element.goe}</MDTypography>
      </Grid>
      <Grid item xs={4}>
        <MDInput
          size="small"
          sx={{ width: 80, mr: 0.5 }}
          type="number"
          label="Оцінка 1"
          value={element.j1}
          onChange={(e) =>
            onElementChange(
              element.code,
              true,
              element.level,
              element.mark,
              parseFloat(e.target.value),
              element.j2,
              element.j3
            )
          }
        />

        <MDInput
          size="small"
          sx={{ width: 80, mr: 0.5 }}
          type="number"
          label="Оцінка 2"
          value={element.j2}
          onChange={(e) =>
            onElementChange(
              element.code,
              true,
              element.level,
              element.mark,
              element.j1,
              parseFloat(e.target.value),
              element.j3
            )
          }
        />

        <MDInput
          size="small"
          sx={{ width: 80, mr: 0.5 }}
          type="number"
          label="Оцінка 3"
          value={element.j3}
          onChange={(e) =>
            onElementChange(
              element.code,
              true,
              element.level,
              element.mark,
              element.j1,
              element.j2,
              parseFloat(e.target.value)
            )
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ m: 0.5 }} />
      </Grid>
    </Grid>
  );

  const initElements = (baseElements, results) => {
    const initialResults = [];
    baseElements.forEach((element) => {
      const editItem = (results ?? []).find((x) => x.code === element.code);
      if (editItem) {
        initialResults.push({
          code: element.code,
          selected: true,
          level: editItem.level,
          mark: editItem.mark,
          j1: editItem.j1,
          j2: editItem.j2,
          j3: editItem.j3,
          bsv: editItem.bsv,
          goe: editItem.goe,
        });
      } else {
        initialResults.push({
          code: element.code,
          selected: false,
          level: "",
          mark: "",
          j1: 0.0,
          j2: 0.0,
          j3: 0.0,
        });
      }
    });
    return initialResults;
  };

  React.useEffect(() => {
    const initialJumpResults = initElements(JumpElements, ts?.jumps);
    setJumps(initialJumpResults);

    const bsResults = initElements(
      RotatingElements.filter((x) => x.type === "BaseSpins"),
      ts?.baseSpins
    );
    setBaseSpins(bsResults);

    const csResults = initElements(
      RotatingElements.filter((x) => x.type === "ComplexSpins"),
      ts?.complexSpins
    );
    setComplexSpins(csResults);

    const psRResults = initElements(
      RotatingElements.filter((x) => x.type === "ProgramSpins"),
      ts?.programSpins
    );
    setProgramSpins(psRResults);

    const initialSequencesResults = initElements(SequencesElements, ts?.sequences);
    setSequences(initialSequencesResults);
  }, []);

  return (
    <MDBox>
      <MDBox style={{ paddingBottom: 20 }}>
        <CustomCard
          title={`${user.firstname} ${user.familyname}: ${
            ts?.id
              ? format(new Date(ts.createDate), "dd MMMM yyyy, HH:mm", {
                  locale: ukLocale,
                })
              : "Нове Тестування"
          }`}
          iconName="person"
          content={
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MDBox bgColor="light" borderRadius="lg" shadow="lg" opacity={1} p={2}>
                  <MDTypography variant="h6">Стрибки</MDTypography>
                  <Grid container alignItems="center" spacing={1}>
                    {jumps.map((j) => (
                      <Grid item key={j.code} xs={12}>
                        {renderElement(
                          j,
                          GetJumpDescription(j.code, j.level),
                          JumpLevels,
                          "Обертання",
                          handleChangeJumps
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </MDBox>
              </Grid>
              <Grid item xs={12}>
                <MDBox bgColor="light" borderRadius="lg" shadow="lg" opacity={1} p={2}>
                  <MDTypography variant="h6">Базові Обертання</MDTypography>
                  <Grid container alignItems="center" spacing={1}>
                    {baseSpins.map((j) => (
                      <Grid item key={j.code} xs={12}>
                        {renderElement(
                          j,
                          GetRotationDescription(j.code, j.level),
                          RotationLevels,
                          "Рівень",
                          (code, newSelected, newLevel, newMark, j1, j2, j3) =>
                            handleChangeRotations(
                              "base",
                              code,
                              newSelected,
                              newLevel,
                              newMark,
                              j1,
                              j2,
                              j3
                            )
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </MDBox>
              </Grid>
              <Grid item xs={12}>
                <MDBox bgColor="light" borderRadius="lg" shadow="lg" opacity={1} p={2}>
                  <MDTypography variant="h6">Складні Обертання</MDTypography>
                  <Grid container alignItems="center" spacing={1}>
                    {complexSpins.map((j) => (
                      <Grid item key={j.code} xs={12}>
                        {renderElement(
                          j,
                          GetRotationDescription(j.code, j.level),
                          RotationLevels,
                          "Рівень",
                          (code, newSelected, newLevel, newMark, j1, j2, j3) =>
                            handleChangeRotations(
                              "complex",
                              code,
                              newSelected,
                              newLevel,
                              newMark,
                              j1,
                              j2,
                              j3
                            )
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </MDBox>
              </Grid>
              <Grid item xs={12}>
                <MDBox bgColor="light" borderRadius="lg" shadow="lg" opacity={1} p={2}>
                  <MDTypography variant="h6">Обертання в Програмах</MDTypography>
                  <Grid container alignItems="center" spacing={1}>
                    {programSpins.map((j) => (
                      <Grid item key={j.code} xs={12}>
                        {renderElement(
                          j,
                          GetRotationDescription(j.code),
                          RotationLevels,
                          "Рівень",
                          (code, newSelected, newLevel, newMark, j1, j2, j3) =>
                            handleChangeRotations(
                              "program",
                              code,
                              newSelected,
                              newLevel,
                              newMark,
                              j1,
                              j2,
                              j3
                            )
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </MDBox>
              </Grid>
              <Grid item xs={12}>
                <MDBox bgColor="light" borderRadius="lg" shadow="lg" opacity={1} p={2}>
                  <MDTypography variant="h6">Послідовності</MDTypography>
                  <Grid container alignItems="center" spacing={1}>
                    {sequences.map((j) => (
                      <Grid item key={j.code} xs={12}>
                        {renderElement(
                          j,
                          GetSequencesDescription(j.code),
                          RotationLevels,
                          "Рівень",
                          handleChangeSequences
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </MDBox>
              </Grid>
            </Grid>
          }
        />
      </MDBox>
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <MDButton
            style={{ marginRight: 10, width: 125 }}
            variant="gradient"
            color="info"
            onClick={() => {
              handleSaveClick();
            }}
          >
            Зберегти
          </MDButton>
          <MDButton
            variant="gradient"
            color="dark"
            style={{ width: 125 }}
            onClick={() => {
              onCancel();
            }}
          >
            Відміна
          </MDButton>
        </Grid>
      </Grid>
    </MDBox>
  );
}

IsuEditForm.defaultProps = {
  ts: null,
};

IsuEditForm.propTypes = {
  user: PropTypes.object.isRequired,
  ts: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
