/* eslint-disable */
/* eslint-disable react/destructuring-assignment */
import PropTypes from "prop-types";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import ConfigService from "api/ConfigService";

import { useEffect, useState } from "react";
import { ShowAlert, AlertTypes, ShowConfirmationAlert } from "components/Alerts";
import { Grid, Skeleton } from "@mui/material";
import CustomCard from "components/CustomCard";
import MDButton from "components/MDButton";
import { DataGrid } from "@mui/x-data-grid";
import { DeleteForever, Edit } from "@mui/icons-material";
import { isEmpty } from "helpers/strhelper";
import EditSettingForm from "./Forms/EditSettingForm";

const uuid = require("uuid");

export default function SettingsPage({ settingsName }) {
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [showEditForm, setShowEditForm] = useState(false);
  const [configuration, setConfiguration] = useState(null);
  const [editContext, setEditContext] = useState(null);
  const [sortModel, setSortModel] = useState([
    {
      field: "title",
      sort: "asc",
    },
  ]);

  const fetchData = async () => {
    try {
      console.log("settingsName", settingsName);

      const config = await ConfigService.getResources(settingsName, true);
      setConfiguration(config);
    } catch (error) {
      const msg = "Невдалося отримати данні з сервера для сторінки";
      ShowAlert(msg, AlertTypes.Error);
    } finally {
      setPageIsLoading(false);
    }
  };
  const getTitle = (name) => {
    let value = "";
    switch (name) {
      case "trainers":
        value = "Тренери";
        break;
      case "groups":
        value = "Групи";
        break;
      case "locations":
        value = "Локації";
        break;
      case "trainings":
        value = "Тренування";
        break;
      default:
        break;
    }
    return value;
  };

  const handleUpdateRecord = async (e) => {
    try {
      const recordToUpdate = { ...e };
      if (isEmpty(recordToUpdate.id)) {
        // new record
        recordToUpdate.id = uuid.v1();
      }

      const settingsToUpdate = { id: settingsName, title: getTitle(settingsName) };
      // console.log('configuration', configuration)
      // console.log('settingsName', settingsName)
      settingsToUpdate.values = (configuration ?? []).filter((x) => x.id !== recordToUpdate.id);
      // console.log('recordToUpdate', recordToUpdate);
      settingsToUpdate.values.push(recordToUpdate);
      await ConfigService.updateConfiguration(settingsToUpdate);
      ShowAlert("Зміни Збережено", AlertTypes.Success, true);
      await fetchData();
      setShowEditForm(false);
    } catch (error) {
      const msg = `Невдалося зберегти зміни. ${error.message}`;
      ShowAlert(msg, AlertTypes.Error);
      console.log("error", error);
    } finally {
      setPageIsLoading(false);
    }
  };

  const handleDeleteRecord = async (id) => {
    try {
      const settingsToUpdate = { id: settingsName, title: getTitle(settingsName) };
      
      settingsToUpdate.values = (configuration ?? []).filter((x) => x.id !== id);
      await ConfigService.updateConfiguration(settingsToUpdate);
      ShowAlert("Зміни Збережено", AlertTypes.Success, true);
      await fetchData();
      setShowEditForm(false);
    } catch (error) {
      console.log("error", error)
      const msg = `Невдалося зберегти зміни. ${error.message}`;
      ShowAlert(msg, AlertTypes.Error);
    } finally {
      setPageIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [settingsName]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 200,
      flex: 0.4,
    },
    {
      field: "title",
      headerName: "Значення",
      minWidth: 200,
      flex: 0.6,
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      renderCell: (params) => (
        <div>
          <MDButton
            circular
            iconOnly
            color="info"
            variant="gradient"
            style={{ margin: 5 }}
            // eslint-disable-next-line react/destructuring-assignment
            key={`edit_${params?.row?.id}`}
            onClick={() => {
              setEditContext(params.row);
              setShowEditForm(true);
            }}
          >
            <Edit />
          </MDButton>
          <MDButton
            circular
            iconOnly
            color="dark"
            variant="gradient"
            onClick={() => {
              ShowConfirmationAlert(
                "Видалити запис?",
                "Так, видалити!",
                handleDeleteRecord,
                params.row.id
              );
            }}
          >
            <DeleteForever />
          </MDButton>
        </div>
      ),
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {pageIsLoading && (
          <div>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
        )}
        {!pageIsLoading && showEditForm && (
          <EditSettingForm
            context={editContext}
            onSubmit={handleUpdateRecord}
            onCancel={() => setShowEditForm(false)}
          />
        )}
        {!pageIsLoading && !showEditForm && (
          <MDBox py={3}>
            <CustomCard
              title=""
              iconName="settings"
              content={
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <MDButton
                      style={{ marginRight: 10, width: 250 }}
                      variant="gradient"
                      color="info"
                      onClick={() => {
                        setEditContext({});
                        setShowEditForm(true);
                      }}
                    >
                      Добавити Новий Запис
                    </MDButton>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        height: window.innerHeight < 850 ? 450 : window.innerHeight - 400,
                        width: "100%",
                      }}
                    >
                      <DataGrid
                        //  sortModel={sortModel}
                        //  onSortModelChange={(model) => setSortModel(model)}
                        rows={configuration ?? []}
                        columns={columns}
                      />
                    </div>
                  </Grid>
                </Grid>
              }
            />
          </MDBox>
        )}
      </MDBox>
    </DashboardLayout>
  );
}

SettingsPage.defaultProps = {};

SettingsPage.propTypes = {
  settingsName: PropTypes.string.isRequired,
};
