export function isEmpty(str) {
  return !str || str.length === 0;
}
 
export function verifyEmail(value) {
  const emailRex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
}

export function getDepartment(serchParam) {
  const linkdep = new URLSearchParams(serchParam).get("dep");
  switch (linkdep) {
    case "iceskating":
      return "iceskating";
    case "rollerskating":
      return "rollerskating";
    default:
      return "";
  }
}