import { useState } from "react";
import PropTypes from "prop-types";

import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import { AddCircleOutline } from "@mui/icons-material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import uaLocale from "date-fns/locale/uk";
import { TextField } from "@mui/material";
import CustomCard from "components/CustomCard";

function NewUserForm({ onSubmit, onCancel }) {
  const [editEmail, setEditEmail] = useState("");
  const [editName, setEditName] = useState("");
  const [editFamilyName, setEditFamilyName] = useState("");
  const [editBirthdate, setEditBirthdate] = useState(new Date());

  return (
    <MDBox>
      <CustomCard
        title=""
        content={
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <MDInput
                fullWidth
                label="Email"
                value={editEmail}
                onChange={(event) => {
                  setEditEmail((event.target.value ?? "").toLowerCase());
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <MDInput
                fullWidth
                label="Ім'я"
                value={editName}
                onChange={(event) => {
                  setEditName(event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <MDInput
                fullWidth
                label="Прізвище"
                value={editFamilyName}
                onChange={(event) => {
                  setEditFamilyName(event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={uaLocale}>
                <DatePicker
                  mask="__.__.____"
                  renderInput={(params) => <TextField {...params} />}
                  label="Дата Народження"
                  value={editBirthdate}
                  onChange={(date) => {
                    setEditBirthdate(date);
                  }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        }
      />
      <MDBox style={{ marginTop: 10 }}>
        <Grid container>
          <Grid item xs={12}>
            <MDButton
              style={{ marginRight: 10, width: 125 }}
              variant="gradient"
              color="info"
              onClick={() => {
                onSubmit({
                  email: editEmail,
                  firstname: editName,
                  familyname: editFamilyName,
                  birthdate: editBirthdate,
                });
              }}
            >
              <AddCircleOutline style={{ marginRight: 5 }} /> Створити
            </MDButton>
            <MDButton
              variant="gradient"
              color="dark"
              style={{ width: 125 }}
              onClick={() => {
                onCancel();
              }}
            >
              Відміна
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

NewUserForm.defaultProps = {};

NewUserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default NewUserForm;
