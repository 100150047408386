const JumpElements = [
  {
    code: "A",
    name: "Аксель",
  },
  {
    code: "T",
    name: "Тулуп",
  },
  {
    code: "S",
    name: "Сальхов",
  },
  {
    code: "Lo",
    name: "Ріттберегер",
  },
  {
    code: "F",
    name: "Фліп",
  },
  {
    code: "Lz",
    name: "Лутц",
  },
  {
    code: "Eu",
    name: "Ойлер",
  },
];

const GetJumpDescription = (jcode, number) => {
  const el = JumpElements.find((x) => x.code === jcode);
  let levellbl = "";
  switch (number) {
    case "1":
      levellbl = "Одинарний ";
      break;
    case "2":
      levellbl = "Двійний ";
      break;
    case "3":
      levellbl = "Трійний ";
      break;
    case "4":
      levellbl = "Четверний ";
      break;
    default:
      break;
  }
  return el ? levellbl + el.name : "";
};

export { JumpElements, GetJumpDescription };
