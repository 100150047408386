/* eslint-disable */
import { createRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CalendarRoot from "components/Calendar/CalendarRoot";
import uaLocale from '@fullcalendar/core/locales/uk';
import { useMaterialUIController } from "context";
import { ShowConfirmationAlert } from "components/Alerts";
import { format } from "date-fns";

function Calendar({
  header,
  datasource,
  locations,
  trainings,
  trainers,
  groups,
  users,
  onCreate,
  onUpdate,
  onDelete,
  onPreview,
  onEdit,
  onDateClick,
  onMove,
  ...rest
}) {
  const calendarRef = createRef();

  const [calendarInitDate, setCalendarInitDate] = useState(new Date());
  const [controller] = useMaterialUIController();

  const { darkMode } = controller;

  const [calendarDataset, setCalendarDataset] = useState([]);

  const handleEventDrop = (eventDropInfo) => {
    const newEvents = [
      {
        id: eventDropInfo.event.id,
        title: eventDropInfo.event.title,
        start: `${format(eventDropInfo.event.start, "yyyy-MM-dd")}T${format(
          eventDropInfo.event.start,
          "HH:mm:00"
        )}`,
        end: `${format(eventDropInfo.event.end, "yyyy-MM-dd")}T${format(
          eventDropInfo.event.end,
          "HH:mm:00"
        )}`,
        extendedProps: eventDropInfo.event.extendedProps,
      },
    ];
    const oldEvents = [
      {
        id: eventDropInfo.event.id,
        start: `${format(eventDropInfo.oldEvent.start, "yyyy-MM-dd")}T${format(
          eventDropInfo.oldEvent.start,
          "HH:mm:00"
        )}`,
      },
    ];
    onMove(newEvents, oldEvents);
  };
  const handleEventResize = (eventResizeInfo) => {
    onUpdate({
      id: eventResizeInfo.event.id,
      // groupId: eventResizeInfo.event.groupId,
      title: eventResizeInfo.event.title,
      start: `${format(eventResizeInfo.event.start, "yyyy-MM-dd")}T${format(
        eventResizeInfo.event.start,
        "HH:mm:00"
      )}`,
      end: `${format(eventResizeInfo.event.end, "yyyy-MM-dd")}T${format(
        eventResizeInfo.event.end,
        "HH:mm:00"
      )}`,
      extendedProps: eventResizeInfo.event.extendedProps,
    });
  };

  useEffect(() => {
  // console.log('datasource changed', datasource)
  }, [datasource]);

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={header.title || header.date ? 2 : 0} px={2} lineHeight={1}>
        {header.title ? (
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {header.title}
          </MDTypography>
        ) : null}
        {header.date ? (
          <MDTypography component="p" variant="button" color="text" fontWeight="regular">
            {header.date}
          </MDTypography>
        ) : null}
      </MDBox>
      <CalendarRoot p={2} ownerState={{ darkMode }}>
        <FullCalendar
          ref={calendarRef}
          initialDate={calendarInitDate}
          locale={uaLocale}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          {...rest}
          events={datasource}
          height="100%"
          customButtons={{
            prev: {
              text: "<",
              click() {
                const calendarApi = calendarRef.current.getApi();
                calendarApi.prev();
                setCalendarInitDate(calendarApi.getDate());
              },
            },
            next: {
              text: ">",
              click() {
                const calendarApi = calendarRef.current.getApi();
                calendarApi.next();
                setCalendarInitDate(calendarApi.getDate());
              },
            },
            today: {
              text: "Сьогодні",
              click() {
                const calendarApi = calendarRef.current.getApi();
                calendarApi.today();
                setCalendarInitDate(calendarApi.getDate());
              },
            },
          }}
          // slotDuration="00:15:00"
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "timeGridWeek,timeGridDay",
          }}
          eventClick={(info) => {
            onPreview({
              id: info.event.id,
              title: info.event.title,
              start: `${format(info.event.start, "yyyy-MM-dd")}T${format(
                info.event.start,
                "HH:mm:00"
              )}`,
              end: `${format(info.event.end, "yyyy-MM-dd")}T${format(info.event.end, "HH:mm:00")}`,

              extendedProps: info.event.extendedProps,
            });
          }}
          dateClick={(info) => {
            onDateClick({
              start: `${format(info.date, "yyyy-MM-dd")}T${format(info.date, "HH:mm:00")}`,
            });
          }}
          eventDrop={(eventDropInfo) => {
            ShowConfirmationAlert("Зберегти зміни?", "Так!", handleEventDrop, eventDropInfo, () => {
              eventDropInfo.revert();
            });
          }}
          eventResize={(eventResizeInfo) => {
            ShowConfirmationAlert(
              "Зберегти зміни?",
              "Так!",
              handleEventResize,
              eventResizeInfo,
              () => {
                eventResizeInfo.revert();
              }
            );
          }}
        />
      </CalendarRoot>
    </Card>
  );
}

// Setting default values for the props of Calendar
Calendar.defaultProps = {
  header: {
    title: "",
    date: "",
  },
  datasource: [],
  locations: [],
  trainings: [],
  trainers: [],
  groups: [],
  users: [],
  onCreate: () => {},
  onUpdate: () => {},
  onDelete: () => {},
  onPreview: () => {},
  onEdit: () => {},
  onDateClick: () => {},
  onMove: () => {},
};

// Typechecking props for the Calendar
Calendar.propTypes = {
  header: PropTypes.shape({
    title: PropTypes.string,
    date: PropTypes.string,
  }),
  datasource: PropTypes.arrayOf(PropTypes.object),
  locations: PropTypes.arrayOf(PropTypes.object),
  trainings: PropTypes.arrayOf(PropTypes.object),
  trainers: PropTypes.arrayOf(PropTypes.object),
  groups: PropTypes.arrayOf(PropTypes.object),
  users: PropTypes.arrayOf(PropTypes.object),
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  onPreview: PropTypes.func,
  onEdit: PropTypes.func,
  onDateClick: PropTypes.func,
  onMove: PropTypes.func,
};

export default Calendar;
