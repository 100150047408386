import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import { ShowAlert, AlertTypes, ShowConfirmationAlert } from "components/Alerts";

import Grid from "@mui/material/Grid";
import { Autocomplete, Skeleton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Edit, DeleteForever } from "@mui/icons-material";

import format from "date-fns/format";
import ukLocale from "date-fns/locale/uk";
import CustomCard from "components/CustomCard";

import UsersService from "api/UsersService";
import MDInput from "components/MDInput";
import EditResultsForm from "./Forms/EditResultsForm";
import AddResultsForm from "./Forms/AddResultsForm";

export default function TestingResultsPage() {
  const [pageIsLoading, setPageIsLoading] = useState(true);

  const [usersList, setUsersList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const [testResults, setTestResults] = useState([]);

  const [showEditForm, setShowEditForm] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [editContext, setEditContext] = useState();

  const [sortModel, setSortModel] = useState([
    {
      field: "date",
      sort: "desc",
    },
  ]);

  const fetchData = async () => {
    try {
      const response = await UsersService.loadAll();
      setUsersList(
        (response ?? []).sort((a, b) =>
          a.firstname + a.familyname > b.firstname + b.familyname ? 1 : -1
        )
      );
    } catch (error) {
      const msg = "Невдалося отримати данні з сервера для сторінки";
      ShowAlert(msg, AlertTypes.Error);
    } finally {
      setPageIsLoading(false);
    }
  };

  const handleLoadTestingResults = async (user) => {
    try {
      if (user) {
        const response = await UsersService.getTestResults(user.accountid, user.id);
        setTestResults(response);
      } else {
        setTestResults([]);
      }
    } catch (error) {
      const msg = "Невдалося отримати данні з сервера для сторінки";
      ShowAlert(msg, AlertTypes.Error);
    } finally {
      setPageIsLoading(false);
    }
  };

  const handleUpdateRecord = async (e) => {
    try {
      await UsersService.updateProfileTestResults(e);
      setShowEditForm(false);
      handleLoadTestingResults(selectedUser);
      ShowAlert("Запис оновлено", AlertTypes.Success, true);
      setEditContext(null);
    } catch (error) {
      const msg = "Невдалося зберегти зміни";
      ShowAlert(msg, AlertTypes.Error);
    }
  };

  const handleAddRecord = async (e) => {
    try {
      await UsersService.updateProfileTestResults(e);
      setShowAddForm(false);
      handleLoadTestingResults(selectedUser);
      ShowAlert("Запис оновлено", AlertTypes.Success, true);
    } catch (error) {
      const msg = `Невдалося зберегти зміни. ${error.message}`;
      ShowAlert(msg, AlertTypes.Error);
    }
  };

  const handleDeleteRecord = async (e) => {
    try {
      await UsersService.deleteTestResults(e);
      handleLoadTestingResults(selectedUser);
      ShowAlert("Запис Видалено", AlertTypes.Success, true);
    } catch (error) {
      const msg = `Невдалося видалити запис. ${error.message}`;
      ShowAlert(msg, AlertTypes.Error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      field: "date",
      headerName: "Дата Тестування",
      width: 200,
      valueGetter: (record) =>
        format(new Date(record.row.date), "dd MMMM yyyy, HH:mm", { locale: ukLocale }),
    },
    {
      field: "categoryname",
      headerName: "Категорія",
      minWidth: 300,
      flex: 1,
    },

    {
      field: "testMark",
      headerName: "Оцінка",
      width: 100,
      valueGetter: (record) => {
        const numberOfPassed = record.row.elements.filter((e) => e.passed === true).length;
        const percentage = Math.round((numberOfPassed * 100) / record.row.elements.length);
        return `${percentage} %`;
      },
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      renderCell: (params) => (
        <div>
          <MDButton
            circular
            iconOnly
            color="info"
            variant="gradient"
            style={{ margin: 5 }}
            // eslint-disable-next-line react/destructuring-assignment
            key={`edit_${params?.row?.id}`}
            onClick={() => {
              setEditContext(params.row);
              setShowEditForm(true);
            }}
          >
            <Edit />
          </MDButton>
          <MDButton
            circular
            iconOnly
            color="dark"
            variant="gradient"
            onClick={() => {
              ShowConfirmationAlert(
                "Видалити запис?",
                "Так, видалити!",
                handleDeleteRecord,
                params.row.id
              );
            }}
          >
            <DeleteForever />
          </MDButton>
        </div>
      ),
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {pageIsLoading && (
          <div>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
        )}
        {!pageIsLoading && showEditForm && (
          <EditResultsForm
            context={editContext}
            onSubmit={handleUpdateRecord}
            onCancel={() => setShowEditForm(false)}
          />
        )}
        {!pageIsLoading && showAddForm && (
          <AddResultsForm
            context={selectedUser}
            onSubmit={handleAddRecord}
            onCancel={() => setShowAddForm(false)}
          />
        )}
        {!pageIsLoading && !showEditForm && !showAddForm && (
          <MDBox py={3}>
            <CustomCard
              title=""
              iconName="quiz"
              content={
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Autocomplete
                      fullWidth
                      disablePortal
                      id="users-select"
                      options={usersList}
                      renderInput={(params) => <MDInput {...params} label="Спортсмен" />}
                      getOptionLabel={(option) => `${option.firstname} ${option.familyname}`}
                      value={selectedUser}
                      onChange={(event, newValue) => {
                        setSelectedUser(newValue);
                        handleLoadTestingResults(newValue);
                      }}
                    />
                  </Grid>
                  {selectedUser && (
                    <Grid item xs={12}>
                      <MDButton
                        style={{ marginRight: 10, width: 250 }}
                        variant="gradient"
                        color="info"
                        onClick={() => {
                          setShowAddForm(true);
                        }}
                      >
                        Добавити Нове Тестування
                      </MDButton>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <div
                      style={{
                        height: window.innerHeight < 850 ? 450 : window.innerHeight - 400,
                        width: "100%",
                      }}
                    >
                      <DataGrid
                        sortModel={sortModel}
                        onSortModelChange={(model) => setSortModel(model)}
                        rows={testResults}
                        columns={columns}
                      />
                    </div>
                  </Grid>
                </Grid>
              }
            />
          </MDBox>
        )}
      </MDBox>
    </DashboardLayout>
  );
}
