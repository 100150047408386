/* eslint-disable no-undef */
/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
/* eslint-disable react/destructuring-assignment */
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { useLocation } from "react-router-dom";
import { ShowAlert, AlertTypes, ShowConfirmationAlert } from "components/Alerts";
import { useEffect, useState } from "react";
import { isEmpty, getDepartment } from "helpers/strhelper";
import ConfigService from "api/ConfigService";
import CustomCard from "components/CustomCard";
import { Grid, Skeleton } from "@mui/material";
import MDButton from "components/MDButton";
import { AddCircleOutline } from "@mui/icons-material";
import MDInput from "components/MDInput";
import resizeFile from "helpers/ImageResizer";
import MediaService from "api/MediaService";

import SectionItemWithImage from "./Forms/SectionItemWithImage";
import EditImgSectionItem from "./Forms/EditImgSectionItem";

const uuid = require("uuid");

export default function SectionWithImagesSettings() {
  const { search } = useLocation();
  const route = useLocation();

  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [, setRequestInProgress] = useState(false);

  const [currentDepartment, setCurrentDepartment] = useState("");
  const [sectionSettings, setSectionSettings] = useState();

  const [showEditForm, setShowEditForm] = useState(false);
  const [editContext, setEditContext] = useState(null);

  const fetchData = async () => {
    try {
      const linkdep = getDepartment(search);
      setCurrentDepartment(linkdep);
      const currentName = route.pathname.replace("/settings/sections/", "");

      const response = await ConfigService.getSectionSettings(currentName, linkdep);
      setSectionSettings(response?.id ? response : { id: `${currentName}_${linkdep}` });
    } catch (error) {
      const msg = "Невдалося отримати данні з сервера для сторінки";
      ShowAlert(msg, AlertTypes.Error);
    } finally {
      setPageIsLoading(false);
    }
  };

  const handleSaveChanges = async () => {
    try {
      setRequestInProgress(true);

      const recordToUpdate = {
        id: sectionSettings.id,
        title: sectionSettings.title,
        title_en: sectionSettings.title_en,

        values: sectionSettings.values.map((x) => ({
          id: x.id,
          img_url: x.img_url ?? "",
          title: x.title,
          title_en: x.title_en,
          description: x.description,
          description_en: x.description_en,
          category: x.category,
          category_en: x.category_en,
          displaytype: x.displaytype,
        })),
      };

      // upload images
      const itemsWithChangedImage = sectionSettings.values.filter((x) => x.imageChanged);
      if ((itemsWithChangedImage ?? []).length > 0) {
        const currentPageName = `${route.pathname.replace("/settings/sections/", "")}`;

        for (let index = 0; index < itemsWithChangedImage.length; index++) {
          const element = itemsWithChangedImage[index];
          if (element.itemImage) {
            const filepath = `sections/${currentDepartment}/${currentPageName}`;
            const filename = `${element.id}.${element.itemImage.name.split(".")[1]}`;

            const imagetoSave = await resizeFile(element.itemImage, 720, 720);
            const response = await MediaService.uploadMediaToS3(imagetoSave, filepath, filename);
            const el = recordToUpdate.values.find((x) => x.id === element.id);
            el.img_url = response.Location;
          }
        }
      }

      await ConfigService.updateSectionSettings(recordToUpdate);

      fetchData();
      ShowAlert("Зміни Збережено", AlertTypes.Success, true);
    } catch (error) {
      const msg = "Невдалося зберегти зміни";
      ShowAlert(msg, AlertTypes.Error);
    } finally {
      setRequestInProgress(false);
    }
  };

  const handleDeleteSectionItem = (itemId) => {
    const newValues = (sectionSettings?.values ?? []).filter((x) => x.id !== itemId);
    const newSettings = { ...sectionSettings, values: newValues };

    setSectionSettings(newSettings);
  };

  const handleSectionItemChange = (newItem) => {
    if (isEmpty(newItem.id)) {
      const newValues = [
        ...(sectionSettings?.values ?? []),
        {
          ...newItem,
          id: uuid.v1(),
        },
      ];
      const newSettings = { ...sectionSettings, values: newValues };
      setSectionSettings(newSettings);
    } else {
      const itemsWithoutOld = (sectionSettings?.values ?? []).filter((x) => x.id !== newItem.id);
      const newSettings = { ...sectionSettings, values: [...itemsWithoutOld, newItem] };
      setSectionSettings(newSettings);
    }
    setShowEditForm(false);
  };

  useEffect(() => {
    fetchData(search);
  }, [search]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {isEmpty(currentDepartment) && (
          <div>
            <br />
            <h2>Виберіть секцію: ковзани або ролики</h2>
          </div>
        )}
        {!isEmpty(currentDepartment) && pageIsLoading && (
          <div>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
        )}
        {!isEmpty(currentDepartment) && !pageIsLoading && showEditForm && (
          <EditImgSectionItem
            context={editContext}
            onSubmit={handleSectionItemChange}
            onCancel={() => {
              setEditContext(null);
              setShowEditForm(false);
            }}
          />
        )}
        {!isEmpty(currentDepartment) && !pageIsLoading && !showEditForm && (
          <MDBox>
            <MDBox style={{ marginBottom: 25 }}>
              <CustomCard
                title="Заголовок Секції"
                content={
                  <div>
                    <Grid container style={{ paddingTop: 15 }}>
                      <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                        <MDInput
                          label="Заголовок"
                          id="title"
                          fullWidth
                          multiline
                          rows={2}
                          value={sectionSettings?.title ?? ""}
                          onChange={(event) =>
                            setSectionSettings({ ...sectionSettings, title: event.target.value })
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                        <MDInput
                          label="Заголовок (EN)"
                          id="title_en"
                          fullWidth
                          multiline
                          rows={2}
                          value={sectionSettings?.title_en ?? ""}
                          onChange={(event) =>
                            setSectionSettings({ ...sectionSettings, title_en: event.target.value })
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                        <MDInput
                          label="Короткий Опис"
                          id="description"
                          fullWidth
                          multiline
                          rows={3}
                          value={sectionSettings?.description ?? ""}
                          onChange={(event) =>
                            setSectionSettings({
                              ...sectionSettings,
                              description: event.target.value,
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                        <MDInput
                          label="Короткий Опис (EN)"
                          id="description_en"
                          fullWidth
                          multiline
                          rows={3}
                          value={sectionSettings?.description_en ?? ""}
                          onChange={(event) =>
                            setSectionSettings({
                              ...sectionSettings,
                              description_en: event.target.value,
                            })
                          }
                        />
                      </Grid>
                    </Grid>
                  </div>
                }
              />
            </MDBox>
            <MDBox style={{ marginBottom: 10 }}>
              <CustomCard
                title="Елементи Секції"
                content={
                  <Grid container>
                    {(sectionSettings?.values ?? []).map((prop) => (
                      <Grid item xs={12} key={prop.id}>
                        <SectionItemWithImage
                          context={prop}
                          onEdit={() => {
                            setShowEditForm(true);
                            setEditContext(prop);
                          }}
                          onDelete={() => {
                            ShowConfirmationAlert(
                              "Видалити запис?",
                              "Так, видалити!",
                              handleDeleteSectionItem,
                              prop.id
                            );
                          }}
                        />
                      </Grid>
                    ))}
                    <Grid item xs={12}>
                      <MDButton
                        color="success"
                        onClick={() => {
                          setEditContext(null);
                          setShowEditForm(true);
                        }}
                      >
                        <AddCircleOutline /> Добавити Елемент
                      </MDButton>
                    </Grid>
                  </Grid>
                }
              />
            </MDBox>
            <Grid container alignItems="center">
              <Grid item xs={12} style={{ padding: 5 }}>
                <MDButton
                  style={{ marginRight: 10, width: 125 }}
                  variant="gradient"
                  color="info"
                  onClick={handleSaveChanges}
                >
                  Зберегти
                </MDButton>
                <MDButton
                  style={{ marginRight: 10, width: 125 }}
                  variant="gradient"
                  color="dark"
                  onClick={() => {
                    fetchData();
                    ShowAlert("Зміни Відмінено", AlertTypes.Success);
                  }}
                >
                  Відміна
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
        )}
      </MDBox>
    </DashboardLayout>
  );
}
