/* eslint-disable react/destructuring-assignment */
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { useLocation } from "react-router-dom";
import { ShowAlert, AlertTypes } from "components/Alerts";
import { useEffect, useState } from "react";
import { isEmpty, getDepartment } from "helpers/strhelper";
import ConfigService from "api/ConfigService";
import CustomCard from "components/CustomCard";
import { Grid, Skeleton } from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

export default function SectionGeneralSettings() {
  const { search } = useLocation();
  const route = useLocation();

  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [, setRequestInProgress] = useState(false);

  const [currentDepartment, setCurrentDepartment] = useState("");
  const [sectionSettings, setSectionSettings] = useState();

  const handleSaveChanges = async () => {
    try {
      setRequestInProgress(true);
      const recordToUpdate = { ...sectionSettings };

      await ConfigService.updateSectionSettings(recordToUpdate);

      ShowAlert("Зміни Збережено", AlertTypes.Success, true);
    } catch (error) {
      const msg = "Невдалося отримати данні з сервера для сторінки";
      ShowAlert(msg, AlertTypes.Error);
    } finally {
      setRequestInProgress(false);
    }
  };

  const fetchData = async () => {
    try {
      const linkdep = getDepartment(search);
      setCurrentDepartment(linkdep);
      const currentName = route.pathname.replace("/settings/sections/", "");

      const response = await ConfigService.getSectionSettings(currentName, linkdep);
      setSectionSettings(response?.id ? response : { id: `${currentName}_${linkdep}` });
    } catch (error) {
      const msg = "Невдалося отримати данні з сервера для сторінки";
      ShowAlert(msg, AlertTypes.Error);
    } finally {
      setPageIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(search);
  }, [search]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {isEmpty(currentDepartment) && (
          <div>
            <br />
            <h2>Виберіть секцію: ковзани або ролики</h2>
          </div>
        )}
        {!isEmpty(currentDepartment) && pageIsLoading && (
          <div>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
        )}
        {!isEmpty(currentDepartment) && !pageIsLoading && (
          <MDBox>
            <MDBox style={{ marginBottom: 25 }}>
              <CustomCard
                title="Заголовок Секції"
                content={
                  <div>
                    <Grid container style={{ paddingTop: 15 }}>
                      <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                        <MDInput
                          label="Заголовок"
                          id="title"
                          fullWidth
                          multiline
                          rows={2}
                          value={sectionSettings?.title ?? ""}
                          onChange={(event) =>
                            setSectionSettings({ ...sectionSettings, title: event.target.value })
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                        <MDInput
                          label="Заголовок (EN)"
                          id="title_en"
                          fullWidth
                          multiline
                          rows={2}
                          value={sectionSettings?.title_en ?? ""}
                          onChange={(event) =>
                            setSectionSettings({ ...sectionSettings, title_en: event.target.value })
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                        <MDInput
                          label="Короткий Опис"
                          id="description"
                          fullWidth
                          multiline
                          rows={3}
                          value={sectionSettings?.description ?? ""}
                          onChange={(event) =>
                            setSectionSettings({
                              ...sectionSettings,
                              description: event.target.value,
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={6} style={{ padding: 5 }}>
                        <MDInput
                          label="Короткий Опис (EN)"
                          id="description_en"
                          fullWidth
                          multiline
                          rows={3}
                          value={sectionSettings?.description_en ?? ""}
                          onChange={(event) =>
                            setSectionSettings({
                              ...sectionSettings,
                              description_en: event.target.value,
                            })
                          }
                        />
                      </Grid>
                    </Grid>
                  </div>
                }
              />
            </MDBox>
            <Grid container alignItems="center">
              <Grid item xs={12} style={{ padding: 5 }}>
                <MDButton
                  style={{ marginRight: 10 }}
                  variant="gradient"
                  color="info"
                  onClick={handleSaveChanges}
                >
                  Зберегти
                </MDButton>
                <MDButton
                  variant="gradient"
                  color="dark"
                  onClick={() => {
                    fetchData();
                    ShowAlert("Зміни Відмінено", AlertTypes.Success);
                  }}
                >
                  Відміна
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
        )}
      </MDBox>
    </DashboardLayout>
  );
}
