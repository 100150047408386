import { API } from "aws-amplify";
import { appConfig } from "config/appConfig";

const NotificationService = {
  async getRequestForms(department) {
    const path = `/requestforms?dep=${department}`;
    const params = {
      response: false,
    };

    const response = await API.get(appConfig.SECURE_API_NAME, path, params);
    if (response && response.length > 0) {
      // localStorage.setItem(cacheKey, JSON.stringify(response[0]));
      return response;
    }
    return [];
  },

  async getNewRequestFormsCount(department) {
    const path = "/requestforms";
    const params = {
      response: false,
      queryStringParameters: {
        dep: department,
        count: true,
        new: true,
      },
    };

    const response = await API.get(appConfig.SECURE_API_NAME, path, params);
    if (response && response.length > 0) {
      // localStorage.setItem(cacheKey, JSON.stringify(response[0]));
      return response;
    }
    return [];
  },

  async updateRequestForm(id, fields) {
    const path = `/requestforms/${id}`;
    const params = {
      response: false,
      body: fields,
    };

    const response = await API.put(appConfig.SECURE_API_NAME, path, params);
    return response;
  },

  async deleteRequestForm(id) {
    const path = `/requestforms/${id}`;
    const params = {
      response: false,
    };

    const response = await API.del(appConfig.SECURE_API_NAME, path, params);
    return response;
  },

  // CONTACT US FORMS
  async getContactUsForms() {
    const path = "/contactusforms";
    const params = {
      response: false,
    };

    const response = await API.get(appConfig.SECURE_API_NAME, path, params);
    if (response && response.length > 0) {
      // localStorage.setItem(cacheKey, JSON.stringify(response[0]));
      return response;
    }
    return [];
  },

  async getNewContactUsFormsCount() {
    const path = "/contactusforms";
    const params = {
      response: false,
      queryStringParameters: {
        // dep: department,
        count: true,
        new: true,
      },
    };

    const response = await API.get(appConfig.SECURE_API_NAME, path, params);
    if (response && response.length > 0) {
      // localStorage.setItem(cacheKey, JSON.stringify(response[0]));
      return response;
    }
    return [];
  },

  async updateContactUsForm(id, fields) {
    const path = `/contactusforms/${id}`;
    const params = {
      response: false,
      body: fields,
    };

    const response = await API.put(appConfig.SECURE_API_NAME, path, params);
    return response;
  },

  async deleteContactUsForm(id) {
    const path = `/contactusforms/${id}`;
    const params = {
      response: false,
    };

    const response = await API.del(appConfig.SECURE_API_NAME, path, params);
    return response;
  },

  // SUBSCRIPTIONS
  async getSubscriptions() {
    const path = "/subscriptions";
    const params = {
      response: false,
    };

    const response = await API.get(appConfig.SECURE_API_NAME, path, params);
    if (response && response.length > 0) {
      return response;
    }
    return [];
  },

  async getSubscriptionsCount() {
    const path = "/subscriptions";
    const params = {
      response: false,
      queryStringParameters: {
        count: true,
        new: true,
      },
    };

    const response = await API.get(appConfig.SECURE_API_NAME, path, params);
    if (response && response.length > 0) {
      return response;
    }
    return [];
  },

  async updateSubscription(id, fields) {
    const path = `/subscriptions/${id}`;
    const params = {
      response: false,
      body: fields,
    };

    const response = await API.put(appConfig.SECURE_API_NAME, path, params);
    return response;
  },

  async deleteSubscription(id) {
    const path = `/subscriptions/${id}`;
    const params = {
      response: false,
    };

    const response = await API.del(appConfig.SECURE_API_NAME, path, params);
    return response;
  },

  // VOLUNTEERS FORMS
  async getVolunteersCount() {
    const path = "/volunteersforms";
    const params = {
      response: false,
      queryStringParameters: {
        count: true,
        new: true,
      },
    };

    const response = await API.get(appConfig.SECURE_API_NAME, path, params);
    if (response && response.length > 0) {
      return response;
    }
    return [];
  },

  async getVolunteersForms() {
    const path = "/volunteersforms";
    const params = {
      response: false,
    };

    const response = await API.get(appConfig.SECURE_API_NAME, path, params);
    if (response && response.length > 0) {
      return response;
    }
    return [];
  },

  async deleteVolunteersForm(id) {
    const path = `/volunteersforms/${id}`;
    const params = {
      response: false,
    };

    const response = await API.del(appConfig.SECURE_API_NAME, path, params);
    return response;
  },

  async updateVolunteersForm(id, fields) {
    const path = `/volunteersforms/${id}`;
    const params = {
      response: false,
      body: fields,
    };

    const response = await API.put(appConfig.SECURE_API_NAME, path, params);
    return response;
  },
};

export default NotificationService;
