/* eslint-disable no-return-await */
import { Auth, API } from "aws-amplify";
import { appConfig } from "config/appConfig";
import AWS from "aws-sdk";

import { isEmpty } from "helpers/strhelper";

const MediaService = {
  async uploadMediaToS3(file, filePath, fileName) {
    if (!file) {
      return;
    }

    const bucketName = appConfig.BUCKET_NAME;
    const bucketRegion = appConfig.Auth.region;
    const IdentityPoolId = appConfig.IDENTITY_POOL_ID;

    const currentaccount = await Auth.currentAuthenticatedUser();

    // TODO: validate authorization

    AWS.config.region = bucketRegion;
    if (currentaccount != null) {
      currentaccount.getSession((err, result) => {
        if (result) {
          // console.log('You are now logged in.', result);
          // console.log('getJwtToken.', result.getIdToken().getJwtToken());
          // Add the User's Id Token to the Cognito credentials login map.
          AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId,
            Logins: {
              [`cognito-idp.${appConfig.Auth.region}.amazonaws.com/${appConfig.Auth.userPoolId}`]:
                result.getIdToken().getJwtToken(),
            },
          });
        }
      });
    }

    const s3 = new AWS.S3({
      apiVersion: "2006-03-01",
      params: { Bucket: bucketName },
    });

    // eslint-disable-next-line consistent-return
    return s3
      .upload({
        Key: `${filePath}/${fileName || file.name}`,
        Body: file,
        ACL: "public-read",
      })
      .promise();
  },

  async addMedia(media) {
    const newRecor = {
      record_type: media.type,
      title: media.title,
      record_category: media.category,
      description: media.description,
      url: media.url,
      cover_image: media.cover_image,
    };

    const path = "/media";
    const params = {
      response: true,
      body: newRecor,
    };
    return await API.post(appConfig.SECURE_API_NAME, path, params);
  },

  async updateMedia(media) {
    const path = `/media/${media.id}`;
    const params = {
      response: true,
      body: media,
    };
    return API.put(appConfig.SECURE_API_NAME, path, params);
  },

  async listMedia(recordsType) {
    const path = "/media";
    const params = {
      response: true,
    };
    if (!isEmpty(recordsType)) {
      params.queryStringParameters = {
        type: recordsType,
      };
    }

    return await API.get(appConfig.SECURE_API_NAME, path, params);
  },

  async getMedia(recordId) {
    const path = `/media?id=${recordId}`;
    const params = {
      response: true,
    };
    return await API.get(appConfig.SECURE_API_NAME, path, params);
  },

  async deleteMedia(id) {
    const path = `/media/${id}`;
    const params = {
      response: false,
    };

    return await API.del(appConfig.SECURE_API_NAME, path, params);
  },
};

export default MediaService;
