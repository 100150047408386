const ElementsBaseValues = [
  // jumps 1
  { code: "1A", value: 1.1 },
  { code: "1T", value: 0.4 },
  { code: "1S", value: 0.4 },
  { code: "1Lo", value: 0.5 },
  { code: "1F", value: 0.5 },
  { code: "1Lz", value: 0.6 },
  { code: "1Eu", value: 0.5 },

  // jumps 2
  { code: "2A", value: 3.3 },
  { code: "2T", value: 1.3 },
  { code: "2S", value: 1.3 },
  { code: "2Lo", value: 1.7 },
  { code: "2F", value: 1.8 },
  { code: "2Lz", value: 2.1 },

  // jumps 3
  { code: "3A", value: 8.0 },
  { code: "3T", value: 4.2 },
  { code: "3S", value: 4.3 },
  { code: "3Lo", value: 4.9 },
  { code: "3F", value: 5.3 },
  { code: "3Lz", value: 5.9 },

  // jumps 3
  { code: "4A", value: 12.5 },
  { code: "4T", value: 9.5 },
  { code: "4S", value: 9.7 },
  { code: "4Lo", value: 10.5 },
  { code: "4F", value: 11.0 },
  { code: "4Lz", value: 11.5 },

  // Step Sequences
  { code: "StSqB", value: 1.5 },
  { code: "StSq1", value: 1.8 },
  { code: "StSq2", value: 2.6 },
  { code: "StSq3", value: 3.3 },
  { code: "StSq4", value: 3.9 },

  // Choreographic Sequences
  { code: "ChSqB", value: 3.0 },
  { code: "ChSq1", value: 3.0 },
  { code: "ChSq2", value: 3.0 },
  { code: "ChSq3", value: 3.0 },
  { code: "ChSq4", value: 3.0 },

  // Spins
  { code: "USpB", value: 1.0 },
  { code: "USp1", value: 1.2 },
  { code: "USp2", value: 1.5 },
  { code: "USp3", value: 1.9 },
  { code: "USp4", value: 2.4 },

  { code: "LSpB", value: 1.2 },
  { code: "LSp1", value: 1.5 },
  { code: "LSp2", value: 1.9 },
  { code: "LSp3", value: 2.4 },
  { code: "LSp4", value: 2.7 },

  { code: "CSpB", value: 1.1 },
  { code: "CSp1", value: 1.4 },
  { code: "CSp2", value: 1.8 },
  { code: "CSp3", value: 2.3 },
  { code: "CSp4", value: 2.6 },

  { code: "SSpB", value: 1.1 },
  { code: "SSp1", value: 1.3 },
  { code: "SSp2", value: 1.6 },
  { code: "SSp3", value: 2.1 },
  { code: "SSp4", value: 2.5 },

  { code: "FUSpB", value: 1.5 },
  { code: "FUSp1", value: 1.2 },
  { code: "FUSp2", value: 2.0 },
  { code: "FUSp3", value: 2.4 },
  { code: "FUSp4", value: 2.9 },

  { code: "FLSpB", value: 1.7 },
  { code: "FLSp1", value: 2.0 },
  { code: "FLSp2", value: 2.4 },
  { code: "FLSp3", value: 2.9 },
  { code: "FLSp4", value: 3.2 },

  { code: "FCSpB", value: 1.6 },
  { code: "FCSp1", value: 1.9 },
  { code: "FCSp2", value: 2.3 },
  { code: "FCSp3", value: 2.8 },
  { code: "FCSp4", value: 3.2 },

  { code: "FSSpB", value: 1.7 },
  { code: "FSSp1", value: 2.0 },
  { code: "FSSp2", value: 2.3 },
  { code: "FSSp3", value: 2.6 },
  { code: "FSSp4", value: 3.0 },

  { code: "FSSpB", value: 1.7 },
  { code: "FSSp1", value: 2.0 },
  { code: "FSSp2", value: 2.3 },
  { code: "FSSp3", value: 2.6 },
  { code: "FSSp4", value: 3.0 },

  { code: "CUSpB", value: 1.5 },
  { code: "CUSp1", value: 1.7 },
  { code: "CUSp2", value: 2.0 },
  { code: "CUSp3", value: 2.4 },
  { code: "CUSp4", value: 2.9 },

  { code: "CLSpB", value: 1.7 },
  { code: "CLSp1", value: 2.0 },
  { code: "CLSp2", value: 2.4 },
  { code: "CLSp3", value: 2.9 },
  { code: "CLSp4", value: 3.2 },

  { code: "CCSpB", value: 1.7 },
  { code: "CCSp1", value: 2.0 },
  { code: "CCSp2", value: 2.3 },
  { code: "CCSp3", value: 2.8 },
  { code: "CCSp4", value: 3.2 },

  { code: "CSSpB", value: 1.6 },
  { code: "CSSp1", value: 1.9 },
  { code: "CSSp2", value: 2.3 },
  { code: "CSSp3", value: 2.6 },
  { code: "CSSp4", value: 3.0 },

  { code: "CoSpB", value: 1.5 },
  { code: "CoSp1", value: 1.7 },
  { code: "CoSp2", value: 2.0 },
  { code: "CoSp3", value: 2.5 },
  { code: "CoSp4", value: 3.0 },

  { code: "CCoSpB", value: 1.7 },
  { code: "CCoSp1", value: 2.0 },
  { code: "CCoSp2", value: 2.5 },
  { code: "CCoSp3", value: 3.0 },
  { code: "CCoSp4", value: 3.5 },

  { code: "FCSSpB", value: 1.6 },
  { code: "FCSSp1", value: 1.9 },
  { code: "FCSSp2", value: 2.3 },
  { code: "FCSSp3", value: 2.6 },
  { code: "FCSSp4", value: 3.0 },

  { code: "FCCSpB", value: 1.7 },
  { code: "FCCSp1", value: 2.0 },
  { code: "FCCSp2", value: 2.3 },
  { code: "FCCSp3", value: 2.8 },
  { code: "FCCSp4", value: 3.2 },

  { code: "FCoSpB", value: 1.5 },
  { code: "FCoSp1", value: 1.7 },
  { code: "FCoSp2", value: 2.0 },
  { code: "FCoSp3", value: 2.5 },
  { code: "FCoSp4", value: 3.0 },

  { code: "FCCoSpB", value: 1.7 },
  { code: "FCCoSp1", value: 2.0 },
  { code: "FCCoSp2", value: 2.5 },
  { code: "FCCoSp3", value: 3.0 },
  { code: "FCCoSp4", value: 3.5 },
];

export default ElementsBaseValues;
