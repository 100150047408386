/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { BrowserRouter } from "react-router-dom";
import App from "App";
import { Amplify, Auth } from "aws-amplify";

// Soft UI Context Provider
import { MaterialUIControllerProvider } from "context";
import { appConfig } from "config/appConfig";
import { createRoot } from 'react-dom/client';

Amplify.configure({
  Auth: appConfig.Auth,
  API: {
    endpoints: [
      {
        name: appConfig.SECURE_API_NAME,
        endpoint: appConfig.API_GATEWAY,
        custom_header: async () => ({
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        }),
      },
      // {
      //   name: appConfig.LOCALHOST,
      //   endpoint: appConfig.LOCALHOST_API_GATEWAY,
      //   custom_header: async () => ({
      //     Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      //   }),
      // },
      // {
      //   name: appConfig.LOCALHOST2,
      //   endpoint: appConfig.LOCALHOST_API_GATEWAY2,
      //   custom_header: async () => ({
      //     Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      //   }),
      // },
    ],
  },
});

const container = document.getElementById('app');
const root = createRoot(container); 

root.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <App />
    </MaterialUIControllerProvider>
  </BrowserRouter>
);
