/* eslint-disable react/forbid-prop-types */
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Divider, Grid, Tooltip } from "@mui/material";
import { GetJumpDescription } from "isu/Elements/JumpElements";
import MDButton from "components/MDButton";
import { GetRotationDescription } from "isu/Elements/RotatingElements";
import { GetSequencesDescription } from "isu/Elements/SequenceElements";

import { ShowConfirmationAlert } from "components/Alerts";
import { DeleteForever, Edit } from "@mui/icons-material";
import format from "date-fns/format";
import ukLocale from "date-fns/locale/uk";

const renderItem = (el, displayCode, descr) => (
  <Grid item xs={12} key={displayCode}>
    <Grid container>
      <Grid item flex={0.6}>
        <Tooltip title={descr}>
          <MDTypography variant="body2">{displayCode}</MDTypography>
        </Tooltip>
      </Grid>
      <Grid item flex={0.2}>
        <MDTypography variant="body2">{el.mark}</MDTypography>
      </Grid>
      <Grid item flex={0.2}>
        <MDTypography variant="body2">{(el.bsv + el.goe).toFixed(2)}</MDTypography>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ mt: 0.1, mb: 0.1 }} />
      </Grid>
    </Grid>
  </Grid>
);

const getTotalPoints = (res) => {
  let pointsScore = 0.0;
  res.sequences.forEach((element) => {
    pointsScore += (element.bsv ?? 0) + (element.goe ?? 0);
  });
  res.jumps.forEach((element) => {
    pointsScore += (element.bsv ?? 0) + (element.goe ?? 0);
  });
  (res.baseSpins ?? []).forEach((element) => {
    pointsScore += (element.bsv ?? 0) + (element.goe ?? 0);
  });
  (res.complexSpins ?? []).forEach((element) => {
    pointsScore += (element.bsv ?? 0) + (element.goe ?? 0);
  });
  (res.programSpins ?? []).forEach((element) => {
    pointsScore += (element.bsv ?? 0) + (element.goe ?? 0);
  });
  return pointsScore.toFixed(2);
};
function ISUResultCardFlat({ results, onEdit, onDelete }) {
  return (
    <Card sx={{ p: 2 }}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={12}>
              <MDTypography variant="h6">
                Дата:{" "}
                {format(new Date(results.createDate), "dd MMMM yyyy, HH:mm", {
                  locale: ukLocale,
                })}
              </MDTypography>
            </Grid>
            {/* <Grid item xs={6}>
              <MDTypography variant="button">Категорія: ???</MDTypography>
            </Grid> */}
            <Grid item xs={6}>
              <MDTypography variant="button">{`Кількість Балів: ${getTotalPoints(
                results
              )}`}</MDTypography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: "right" }}>
          <MDButton
            circular
            iconOnly
            color="info"
            variant="gradient"
            style={{ margin: 5 }}
            onClick={() => {
              onEdit(results);
            }}
          >
            <Edit />
          </MDButton>
          <MDButton
            circular
            iconOnly
            color="dark"
            variant="gradient"
            onClick={() => {
              ShowConfirmationAlert("Видалити запис?", "Так, видалити!", onDelete, results.id);
            }}
          >
            <DeleteForever />
          </MDButton>
        </Grid>
        <Grid item xs={12} md={4}>
          <MDBox
            bgColor="light"
            variant="gradient"
            borderRadius="lg"
            shadow="lg"
            opacity={0.7}
            p={2}
          >
            <MDTypography variant="h6" sx={{ pb: 1 }}>
              Доріжки
            </MDTypography>
            <Grid container>
              <Grid item flex={0.6}>
                <MDTypography variant="h6">Код</MDTypography>
              </Grid>
              <Grid item flex={0.2}>
                <MDTypography variant="h6">Оцінка</MDTypography>
              </Grid>
              <Grid item flex={0.2}>
                <MDTypography variant="h6">Бали</MDTypography>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ mt: 0.1, mb: 0.1 }} />
              </Grid>
              {(results.sequences ?? []).map((j) =>
                renderItem(
                  j,
                  j.code + (j.level === "Base" ? "" : j.level),
                  GetSequencesDescription(j.code, j.level)
                )
              )}
            </Grid>
          </MDBox>
        </Grid>
        <Grid item xs={12} md={4}>
          <MDBox
            bgColor="light"
            variant="gradient"
            borderRadius="lg"
            shadow="lg"
            opacity={0.7}
            p={2}
          >
            <MDTypography variant="h6" sx={{ pb: 1 }}>
              Стрибки
            </MDTypography>
            <Grid container>
              <Grid item flex={0.6}>
                <MDTypography variant="h6">Код</MDTypography>
              </Grid>
              <Grid item flex={0.2}>
                <MDTypography variant="h6">Оцінка</MDTypography>
              </Grid>
              <Grid item flex={0.2}>
                <MDTypography variant="h6">Бали</MDTypography>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ mt: 0.1, mb: 0.1 }} />
              </Grid>
              {(results.jumps ?? []).map((j) =>
                renderItem(j, j.level + j.code, GetJumpDescription(j.code, j.level))
              )}
            </Grid>
          </MDBox>
        </Grid>
        <Grid item xs={12} md={4}>
          <MDBox
            bgColor="light"
            variant="gradient"
            borderRadius="lg"
            shadow="lg"
            opacity={0.7}
            p={2}
          >
            <MDTypography variant="h6" sx={{ pb: 1 }}>
              Обертання
            </MDTypography>
            <Grid container>
              <Grid item flex={0.6}>
                <MDTypography variant="h6">Код</MDTypography>
              </Grid>
              <Grid item flex={0.2}>
                <MDTypography variant="h6">Оцінка</MDTypography>
              </Grid>
              <Grid item flex={0.2}>
                <MDTypography variant="h6">Бали</MDTypography>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ mt: 0.1, mb: 0.1 }} />
              </Grid>
              {(results.baseSpins ?? []).map((j) =>
                renderItem(
                  j,
                  j.code + (j.level === "B" ? "" : j.level),
                  GetRotationDescription(j.code, j.level)
                )
              )}
              {(results.complexSpins ?? []).map((j) =>
                renderItem(
                  j,
                  j.code + (j.level === "B" ? "" : j.level),
                  GetRotationDescription(j.code, j.level)
                )
              )}
              {(results.programSpins ?? []).map((j) =>
                renderItem(
                  j,
                  j.code + (j.level === "B" ? "" : j.level),
                  GetRotationDescription(j.code, j.level)
                )
              )}
            </Grid>
          </MDBox>
        </Grid>
      </Grid>
    </Card>
  );
}

// Setting default values for the props of DefaultInfoCard
ISUResultCardFlat.defaultProps = {
  results: {},
  onEdit: () => {},
  onDelete: () => {},
};

// Typechecking props for the DefaultInfoCard
ISUResultCardFlat.propTypes = {
  results: PropTypes.object,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

export default ISUResultCardFlat;
