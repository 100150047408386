/* eslint-disable */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from "react";
import PropTypes from "prop-types";

import CustomCard from "components/CustomCard";
import { Autocomplete, Grid } from "@mui/material";

import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { DataGrid } from "@mui/x-data-grid";
import MDInput from "components/MDInput";
import ConfigService, { ConfigResources } from "api/ConfigService";

const uuid = require("uuid");

export default function AddResultsForm({ context, onCancel, onSubmit }) {
  const [comments, setComments] = useState(context.comments ?? "");
  const [selectionModel, setSelectionModel] = useState([]);

  const [testCategories, setTestCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const fetchData = async () => {
    try {
      const config = await ConfigService.getResources(ConfigResources.InternalEvaluation);
      setTestCategories(config ?? []);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  React.useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      field: "title",
      headerName: "Елемент",
      flex: 1,
    },
  ];

  return (
    <MDBox>
      <MDBox style={{ paddingBottom: 20 }}>
        <CustomCard
          title={`${context.firstname} ${context.familyname}: Нове Тестування`}
          iconName="person"
          content={
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  fullWidth
                  disablePortal
                  id="users-select"
                  options={testCategories}
                  renderInput={(params) => <MDInput {...params} label="Спортивна Категорія" />}
                  getOptionLabel={(option) => option.title}
                  value={selectedCategory}
                  onChange={(event, newValue) => {
                    setSelectionModel([]);
                    setSelectedCategory(newValue);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{
                    height: window.innerHeight < 850 ? 500 : window.innerHeight - 500,
                    width: "100%",
                  }}
                >
                  <DataGrid
                    checkboxSelection
                    onRowSelectionModelChange={(newSelectionModel) => {
                      setSelectionModel(newSelectionModel);
                    }}
                    rowSelectionModel={selectionModel}
                    rows={selectedCategory?.elements ?? []}
                    columns={columns}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <MDInput
                  fullWidth
                  multiline
                  rows={6}
                  label="Коментар"
                  value={comments}
                  onChange={(event) => {
                    setComments(event.target.value);
                  }}
                />
              </Grid>
            </Grid>
          }
        />
      </MDBox>
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <MDButton
            style={{ marginRight: 10, width: 125 }}
            variant="gradient"
            color="info"
            onClick={() => {
              const newResults = {
                id: uuid.v1(),
                accountid: context.accountid,
                profileid: context.id,
                categoryid: selectedCategory.id,
                categoryname: selectedCategory.title,
                comments,
                date: new Date(),
                elements: selectedCategory.elements.map((x) => ({
                  id: x.id,
                  title: x.title,
                  passed: selectionModel.includes(x.id),
                })),
              };
              onSubmit(newResults);
            }}
          >
            Зберегти
          </MDButton>
          <MDButton
            variant="gradient"
            color="dark"
            style={{ width: 125 }}
            onClick={() => {
              onCancel();
            }}
          >
            Відміна
          </MDButton>
        </Grid>
      </Grid>
    </MDBox>
  );
}

AddResultsForm.propTypes = {
  context: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
